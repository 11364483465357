import ReactTextareaAutocomplete from "@webscopeio/react-textarea-autocomplete";
import { emojiIndex } from "emoji-mart";
import { forwardRef } from "react";
import PropTypes from "prop-types";

const LL_VER = process.env.REACT_APP_LL_VERSION;
const inDevMode = () => LL_VER === "development";

const saveDelayTimeout = (id, func) => {
  clearTimeout(id);
  return setTimeout(() => func(), 1000);
};

const addSquare = color => {
  return <div className="square" style={{ background: color }} />;
};

const local_date_obj = date => {
  const ldate = new Date(date);
  return new Date(ldate.getTime() + ldate.getTimezoneOffset() * 60000);
};

/* Generalized Input component for text input must provide value and
and onChange for functionality
important props:
  name,
  type,
  label,
  value,
  onChange,
  description,
  extra_class,
*/
const Input = forwardRef(
  ({ SideButton, isTextarea = false, isClearable, extra_class = "", ...otherProps }, ref) => {
    return (
      <div className="form-group m-0">
        {otherProps.label && <label htmlFor={otherProps.name}>{otherProps.label}</label>}
        {isTextarea ? (
          <textarea
            {...otherProps}
            ref={ref}
            className={"form-control " + extra_class + " message-input"}
            id={otherProps.name}
            name={otherProps.name}
            aria-describedby="inputdesc"
            rows="1"
          >
            {otherProps.value || ""}
          </textarea>
        ) : (
          <input
            {...otherProps}
            ref={ref}
            className={"form-control " + extra_class + " message-input"}
            id={otherProps.name}
            name={otherProps.name}
            aria-describedby="inputdesc"
            type="text"
          />
        )}
        {SideButton && <div className="buttons">{SideButton}</div>}
        {otherProps.description && (
          <small id="inputdesc" className="add-form-input-label form-text text-muted">
            {otherProps.description}
          </small>
        )}
      </div>
    );
  },
);
Input.displayName = "CustomInput";
Input.propTypes = {
  SideButton: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.arrayOf(PropTypes.element),
  ]),
  isTextarea: PropTypes.bool,
  isClearable: PropTypes.bool,
  extra_class: PropTypes.string,
};

const InputEmoji = forwardRef(({ SideButton, ...props }, ref) => {
  return (
    <div className="form-group m-0">
      {props.label && <label htmlFor={props.name}>{props.label}</label>}
      <div className="rta-wrapper">
        <ReactTextareaAutocomplete
          {...props}
          ref={ref}
          className={`${props.extra_class || ""} message-input`}
          id={props.name}
          name={props.name}
          loadingComponent={() => <span>Loading</span>}
          aria-describedby="inputdesc"
          trigger={{
            ":": {
              dataProvider: token =>
                emojiIndex.search(token).map(o => ({
                  colons: o.colons,
                  native: o.native,
                })),
              component: ({ entity: { native, colons } }) => <div>{`${colons} ${native}`}</div>,
              output: item => `${item.native}`,
            },
          }}
        />
        {SideButton && <div className="buttons">{SideButton}</div>}
      </div>

      {props.description && (
        <small id="inputdesc" className="add-form-input-label form-text text-muted">
          {props.description}
        </small>
      )}
    </div>
  );
});
InputEmoji.displayName = "InputEmoji";
InputEmoji.propTypes = {
  SideButton: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.arrayOf(PropTypes.element),
  ]),
  label: PropTypes.string,
  name: PropTypes.string,
  extra_class: PropTypes.string,
  description: PropTypes.string,
};

const TextArea = ({
  name,
  type,
  label,
  value,
  rows = 3,
  onChange,
  description = "",
  extra_class = "",
}) => {
  return (
    <div className="form-group m-0  pb-1 pt-1">
      {label && <label htmlFor={name}>{label}</label>}
      <textarea
        value={value}
        onChange={onChange}
        name={name}
        type={type}
        rows={rows}
        className={"form-control " + extra_class}
        id={name}
        aria-describedby="inputdesc"
      />
      <small id="inputdesc" className="form-text text-muted">
        {description}
      </small>
    </div>
  );
};
TextArea.propTypes = {
  name: PropTypes.string,
  type: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.any,
  rows: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  description: PropTypes.string,
  extra_class: PropTypes.string,
};

const Select = ({ name, type, label, value, options = [], onChange, description, extra_class }) => {
  return (
    <div className="form-group m-0 pb-1 pt-1">
      {label && <label htmlFor={name}>{label}</label>}
      <select
        value={value}
        onChange={onChange}
        name={name}
        type={type}
        className={"form-control " + extra_class}
        id={name}
        aria-describedby="inputdesc"
      >
        {options.map(i => (
          <option key={i}>{i}</option>
        ))}
      </select>

      <small id="inputdesc" className="form-text text-muted">
        {description}
      </small>
    </div>
  );
};
Select.propTypes = {
  name: PropTypes.string,
  type: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.any,
  options: PropTypes.array,
  onChange: PropTypes.func.isRequired,
  description: PropTypes.string,
  extra_class: PropTypes.string,
};

const MultiSelect = ({ name, type, label, value, options, onChange, description, extra_class }) => {
  return (
    <div className="form-group m-0  pb-1 pt-1">
      {label && <label htmlFor={name}>{label}</label>}
      <select
        multiple
        value={value}
        onChange={onChange}
        name={name}
        type={type}
        className={"form-control " + extra_class}
        id={name}
        aria-describedby="inputdesc"
      >
        {options.map(i => (
          <option key={i[0]} label={i[1]}>
            {i[0]}
          </option>
        ))}
      </select>

      <small id="inputdesc" className="form-text text-muted">
        {description}
      </small>
    </div>
  );
};
MultiSelect.propTypes = {
  name: PropTypes.string,
  type: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.any,
  options: PropTypes.array,
  onChange: PropTypes.func.isRequired,
  description: PropTypes.string,
  extra_class: PropTypes.string,
};

const CheckBox = ({ name, label, value = false, onChange, extra_class }) => {
  return (
    <div className="form-check form-check-inline m-0  pb-1 pt-1 mr-2">
      <input
        checked={value}
        onChange={onChange}
        name={name}
        type="checkbox"
        className={"form-check-input " + extra_class}
        id={name}
        aria-describedby="inputdesc"
      />
      <label className="form-check-label" htmlFor={name}>
        {label}
      </label>
    </div>
  );
};
CheckBox.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  extra_class: PropTypes.string,
};

const ServerErrors = ({ errors }) => {
  let errorMsg = null;

  if (errors && errors.response) {
    if (errors.response.data && errors.response.data.non_field_errors) {
      if (
        errors.response.data.non_field_errors[0] ===
        "Unable to authenticate with provided credentials"
      ) {
        errorMsg = "Incorrect login / password entered. Click Forgot Password below to reset";
      } else {
        errorMsg = errors.response.data.non_field_errors;
      }
    } else if (
      errors.response.data &&
      (errors.response.data.email || errors.response.data?.error?.email)
    ) {
      const emailError =
        errors.response.data?.email?.length > 0 ? errors.response.data?.email[0] : "";
      if (
        emailError === "user with this email already exists." ||
        errors.response.data?.error?.email[0] === "user with this email already exists."
      ) {
        errorMsg = "User with this email already exists";
      } else if (
        emailError ===
        "There is no active user associated with this e-mail address or the password can not be changed"
      ) {
        errorMsg =
          "Email not found, please make sure this is your correct email or register for a new account.";
      } else {
        errorMsg = JSON.stringify(errors.response.data, null, 2);
      }
    } else if (errors.response.data && errors.response.data.status) {
      if (errors.response.data.status === "notfound") {
        errorMsg = "Invalid token, please check your email for the password reset token.";
      }
    } else {
      errorMsg = errors.response?.data
        ? JSON.stringify(errors.response.data, null, 2)
        : "Something went wrong with the server, please try again later.";
    }
  }
  return (
    errorMsg && (
      <div
        data-testid="server-error-text"
        className="alert alert-danger"
        style={{ maxWidth: "300px" }}
      >
        <small>{errorMsg}</small>
      </div>
    )
  );
};

ServerErrors.propTypes = {
  errors: PropTypes.object,
};

const DEVBTN = ({ func, name = "" }) => {
  return (
    <button type="button" className="btn btn-primary" onClick={func}>
      {name}
    </button>
  );
};
DEVBTN.propTypes = {
  func: PropTypes.func.isRequired,
  name: PropTypes.string,
};

const wtype_options = ["workette", "link", "note", "workset"];

const SORT_MENUS = [
  {
    text: "Custom",
    value: "custom",
  },
  {
    text: "Created date (Newest -> Oldest)",
    value: "created_on_newest_to_oldest",
  },
  {
    text: "Created date (Oldest -> Newest)",
    value: "created_on_oldest_to_newest",
  },
  // {
  //   text: "Sort by status (Open -> In progress -> Canceled -> Completed",
  //   value: "status_newest_to_oldest",
  // },
  // {
  //   text: "Sort by status (Completed -> Canceled -> In progress -> Open",
  //   value: "status_oldest_to_newest",
  // },
];

const sort_items = (items, list = [], sortBy = "custom") => {
  if (items && sortBy && SORT_MENUS.find(({ value }) => value === sortBy)) {
    let itemIds = [...list];

    // remove id if that is not in the items
    itemIds = itemIds.filter(id => items[id]);

    return itemIds.sort((prev, next) => {
      const prevItem = items[prev];
      const nextItem = items[next];

      const prevItemCreatedOn = prevItem.context.created_on || prevItem.j_timestamp;
      const nextItemCreatedOn = nextItem.context.created_on || nextItem.j_timestamp;

      switch (sortBy) {
        case "created_on_oldest_to_newest": {
          return new Date(nextItemCreatedOn) - new Date(prevItemCreatedOn);
        }
        case "created_on_newest_to_oldest": {
          return new Date(prevItemCreatedOn) - new Date(nextItemCreatedOn);
        }
        // case "status": {
        //   const statusPoints = {
        //     open: 1,
        //     running: 2,
        //     canceled: 3,
        //     done: 4,
        //   };

        //   const prevItemStatus = prevItem.context.status || "open";
        //   const nextItemStatus = nextItem.context.status || "open";

        //   if (sortBy.order === "asc") {
        //     return statusPoints[prevItemStatus] - statusPoints[nextItemStatus];
        //   } else {
        //     return statusPoints[nextItemStatus] - statusPoints[prevItemStatus];
        //   }
        // }
        default:
          return itemIds;
      }
    });
  }

  return list;
};

export {
  inDevMode,
  local_date_obj,
  saveDelayTimeout,
  Input,
  InputEmoji,
  TextArea,
  Select,
  MultiSelect,
  CheckBox,
  ServerErrors,
  DEVBTN,
  wtype_options,
  addSquare,
  LL_VER,
  sort_items,
  SORT_MENUS,
};
