import { memo, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import Tooltip from "react-simple-tooltip";
import PropTypes from "prop-types";

import { workette_actions as wact } from "@myca/shared-component";

import logo from "../../../aiviews/FocusLogo_Gold.svg";
import "./WktButtonFocusRecommendation.scss";

const WktButtonFocusRecommendation = ({ setIsOpenRecommendation }) => {
  const { days } = useSelector(state => state.workette);
  const cur_date = useSelector(state => state.session.cur_date);

  const dispatch = useDispatch();
  const get_suggested_focus = useCallback(
    w_id => {
      dispatch(wact.get_suggested_focus(w_id));
    },
    [dispatch],
  );

  const recommendationOnClick = useCallback(() => {
    if (days && cur_date) {
      get_suggested_focus(days[cur_date]);
      setIsOpenRecommendation && setIsOpenRecommendation(true);
    }
  }, [cur_date, days, get_suggested_focus, setIsOpenRecommendation]);

  return (
    <Tooltip
      padding={10}
      fontSize="11px"
      arrow={4}
      content={<span>Get focus recommendations</span>}
    >
      <span
        className="recommendation"
        onClick={recommendationOnClick}
        data-testid="focus-recommendation-button"
      >
        <img src={logo} alt="myca-ai" width={26} height={24} loading="lazy" />
      </span>
    </Tooltip>
  );
};

WktButtonFocusRecommendation.propTypes = {
  setIsOpenRecommendation: PropTypes.func,
};

export default memo(WktButtonFocusRecommendation);
