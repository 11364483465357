import PropTypes from "prop-types";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle } from "@fortawesome/pro-solid-svg-icons";
import "./HelpInfoTooltip.scss";
/**
 * Generates filtered list of workettes with customized paramters
 * @param {string} placement top/bottom/right/left
 * @param {string} message title of the list to display
 */
const HelpInfoTooltip = ({ className = "", placement = "top", message = "" }) => {
  const renderTooltip = props => (
    <Tooltip id="help-tooltip" {...props}>
      {message}
    </Tooltip>
  );

  return (
    <div className={`help-tooltip ${className || ""}`}>
      <OverlayTrigger
        placement={placement}
        delay={{ show: 250, hide: 400 }}
        overlay={renderTooltip}
      >
        <FontAwesomeIcon icon={faQuestionCircle} style={{ color: "grey" }} />
      </OverlayTrigger>
    </div>
  );
};

HelpInfoTooltip.propTypes = {
  className: PropTypes.string,
  placement: PropTypes.string,
  message: PropTypes.string,
};

export default HelpInfoTooltip;
