import { memo, useCallback } from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { faMinus } from "@fortawesome/pro-solid-svg-icons";

import { workette_actions as wact } from "@myca/shared-component";

import WktButton from "../WktButton";

const WktButtonUnAssociate = ({ goalId, jid }) => {
  const dispatch = useDispatch();
  const remove_associate_with_goal = useCallback(
    (goal_id, items) => dispatch(wact.remove_associate_with_goal(goal_id, items)),
    [dispatch],
  );
  return (
    <WktButton
      data-testid={"wkt-un-associate-btn " + jid}
      className="wkt-un-associate-btn"
      icon={faMinus}
      onClick={e => {
        e.stopPropagation();
        remove_associate_with_goal(goalId, [jid]);
      }}
      tooltip="Un-associate"
      isFAButton={false}
    />
  );
};

WktButtonUnAssociate.propTypes = {
  goalId: PropTypes.string.isRequired,
  jid: PropTypes.string.isRequired,
};

export default memo(WktButtonUnAssociate);
