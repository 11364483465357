import { useEffect, useState, useRef, useCallback, Suspense, lazy } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Switch, useLocation } from "react-router-dom";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import IdleTimer from "react-idle-timer";
import { Spinner } from "react-bootstrap";

import AppProvider from "./AppContext";
import routes, { Toasts } from "./routes";

import {
  workette_actions as wact,
  session_actions as sact,
  reflect_actions as ref_act,
  selectCurrentlySelectedLifeNode,
} from "@myca/shared-component";

import "./App.scss";

const DownloadPage = lazy(() => import("./components/DownloadPage"));
const PublicRoute = lazy(() => import("./route/PublicRoute"));
const AdminRoute = lazy(() => import("./route/AdminRoute"));
const InternalRoute = lazy(() => import("./route/InternalRoute"));
const Custom503 = lazy(() => import("./components/custom503"));

const IDLE_TIMEOUT = 1000 * 60 * 15; // 15mins

const App = () => {
  const location = useLocation();
  const [isMobile, setIsMobile] = useState(false);
  const idleRef = useRef(null);
  const [mycaBuddyInterval, setMycaBuddyInterval] = useState(null);

  const { logged_in, freeze_override, error: sessionError } = useSelector(state => state.session);
  const { error: worketteError } = useSelector(state => state.workette);
  const lifeNode = useSelector(selectCurrentlySelectedLifeNode);

  const dispatch = useDispatch();
  const session_logout = useCallback(alert => dispatch(sact.logout(alert)), [dispatch]);
  const wkt_logout = useCallback(() => dispatch(wact.logout()), [dispatch]);
  const clear_highlights = useCallback(() => dispatch(ref_act.clear_highlights()), [dispatch]);
  // const pull_myca_buddy_notifications = useCallback(
  //   () => dispatch(mbact.pull_myca_buddy_notifications()),
  //   [dispatch],
  // );
  const update_timezone = useCallback(() => dispatch(wact.update_timezone()), [dispatch]);

  const handleOnIdle = () => {
    window.location.reload();
  };

  // Detect if a user is on mobile
  useEffect(() => {
    const ua = window.navigator.userAgent;
    const screenWidth = window.screen.width;

    const isMobileDevice = /Android|webOS|iPhone|BlackBerry|Windows Phone/i.test(ua);
    const isTabletDevice =
      /iPad|Tablet/i.test(ua) || (/Android/i.test(ua) && screenWidth > 600 && screenWidth < 1024);

    setIsMobile(isMobileDevice && !isTabletDevice);
  }, []);

  // Detect if a user is logged in or not to display the chatbot
  useEffect(() => {
    if (document.querySelector("#zsb-widget")) {
      if (logged_in && !freeze_override) {
        document.querySelector("#zsb-widget").innerHTML =
          '<zeroshot-bot color="#3E67F6" height="410px" bot="U2FsdGVkX1/g4a40238lNtiGokulMqfp3bsFpSGdHhjYSEVRFjG6688lqHim20wN5LocIdCU/VV4f9wwlB1aNG+eEP6SUcgVnRqmmNfVEocDSrKUcOs4rA1PTEroWgcK0OtyRQCK6JJ5MnPE3dXrdgZV0MzKLreeI2aOjE9ufz+47oqAX9oeCgsHtVPUNXzQjKNM34OWBci0e1fdHGlj8JLWA4hcJtI12mZLFlULqujuHKKAL/5rlr0hSTmhVX8RHsi8BfvoxwIy66ZiLrJuBe6FfDgiTrJ+9ZKkY4m6nCQOEEKPJKbsVwYnQYD2dVIVFmbrcbhz39A06ksO5OVDe/FykZe5o1xmqgUg7QxmxSaZCibIUIepLF7NPqGOjOQFSOqfWsoVWB4xbIW9b2WRtpXBZLkuzlPkf8wDLow/O6A="></zeroshot-bot>';
      } else {
        document.querySelector("#zsb-widget").innerHTML = "";
      }
    }
  }, [freeze_override, logged_in]);

  // executes logout, clears redux and asyc
  const processLogout = useCallback(
    async alert => {
      localStorage.clear();
      session_logout(alert);
      wkt_logout();
      clear_highlights();
    },
    [clear_highlights, session_logout, wkt_logout],
  );

  // catch 401 and 403s
  useEffect(() => {
    if (
      sessionError?.response?.status === 401 ||
      sessionError?.response?.status === 403 ||
      worketteError?.response?.status === 401 ||
      worketteError?.response?.status === 403
    ) {
      processLogout({ name: "Session Expired", msg: "Please re-login to start a new session" });
    }
  }, [sessionError, worketteError, processLogout]);

  useEffect(() => {
    if (lifeNode && logged_in) {
      if (lifeNode?.context?.timezone_str !== Intl.DateTimeFormat().resolvedOptions().timeZone) {
        update_timezone();
      }
    }
  }, [lifeNode, logged_in]);

  // useEffect(() => {
  //   if (lifeNode && logged_in && !mycaBuddyInterval) {
  //     const ten_minutes = 10 * 60 * 1000;
  //     pull_myca_buddy_notifications();

  //     setMycaBuddyInterval(
  //       setInterval(() => {
  //         pull_myca_buddy_notifications();
  //       }, ten_minutes),
  //     );
  //   }
  // }, [lifeNode, logged_in, mycaBuddyInterval]);

  useEffect(() => {
    if (!logged_in && mycaBuddyInterval) {
      clearInterval(mycaBuddyInterval);
      setMycaBuddyInterval(null);
    }
  }, [logged_in, mycaBuddyInterval]);

  return (
    <>
      <IdleTimer ref={idleRef} element={document} onIdle={handleOnIdle} timeout={IDLE_TIMEOUT} />
      {sessionError?.response?.status === 503 || worketteError?.response?.status === 503 ? (
        <Suspense fallback="">
          <Custom503 />
        </Suspense>
      ) : (
        <AppProvider>
          {isMobile &&
          location.pathname !== "/eula" &&
          location.pathname !== "/privacy" &&
          location.pathname !== "/register" ? (
            <Suspense fallback={""}>
              <DownloadPage />
            </Suspense>
          ) : (
            <>
              <div className="app-wrapper">
                <TransitionGroup className="transition-group">
                  <CSSTransition timeout={{ enter: 700, exit: 700 }} classNames={"fade"}>
                    <Suspense
                      fallback={
                        <div className="page-loader">
                          <Spinner animation="grow" />
                        </div>
                      }
                    >
                      <Switch location={location}>
                        {routes.map(route => {
                          const { path, component, exact, secured, admin } = route;

                          let RouteComponent;
                          if (secured && !admin) {
                            RouteComponent = InternalRoute;
                          } else if (secured && admin) {
                            RouteComponent = AdminRoute;
                          } else {
                            RouteComponent = PublicRoute;
                          }

                          return (
                            <RouteComponent
                              key={path}
                              path={path}
                              exact={exact}
                              component={component}
                              processLogout={secured && !admin ? processLogout : undefined}
                            />
                          );
                        })}
                      </Switch>
                    </Suspense>
                  </CSSTransition>
                </TransitionGroup>
              </div>
              <Suspense fallback={""}>
                <Toasts />
              </Suspense>
            </>
          )}
        </AppProvider>
      )}
    </>
  );
};

export default App;
