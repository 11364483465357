import { lazy } from "react";

import Reflect from "./components/reflect";
import Envision from "./components/envision";
import SettingsMain from "./components/settings/SettingsMain";
import PerformMain from "./components/perform/PerformMain";
import LogOut from "./components/login/LogOut";
import Activation from "./components/activation";

export const LogIn = lazy(() => import("./components/login/LogIn"));
export const PrivacyPolicy = lazy(() => import("./components/splash/PrivacyPolicy"));
export const SplashPage = lazy(() => import("./components/splash/SplashPage"));
export const Register = lazy(() => import("./components/register/Register"));
export const PasswordReset = lazy(() => import("./components/passwordreset/PasswordReset"));
export const Toasts = lazy(() => import("./components/general/Toasts"));
export const Mailchimp = lazy(() => import("./components/mailchimp/MailChimp"));
export const UELA = lazy(() => import("./components/UELA"));
export const DeleteInstructions = lazy(() => import("./components/DeleteInstructions"));

export const Dashboard = lazy(() => import("./pages/Dashboard"));
export const MycaTour = lazy(() => import("./components/MycaTour"));

const public_pages = [
  {
    path: "/",
    component: LogIn,
    exact: true,
  },
  {
    path: "/login",
    component: LogIn,
  },
  {
    path: "/privacy",
    component: PrivacyPolicy,
    exact: true,
    bypass_auth: true,
  },
  {
    path: "/eula",
    component: UELA,
    exact: true,
    bypass_auth: true,
  },
  {
    path: "/delete",
    component: DeleteInstructions,
    exact: true,
    bypass_auth: true,
  },
  {
    path: "/home",
    component: SplashPage,
  },
  {
    path: "/logout",
    component: LogOut,
  },
  {
    path: "/register",
    component: Register,
  },
  {
    path: "/passwordreset/:token?",
    component: PasswordReset,
  },
  {
    path: "/activation/:code?",
    component: Activation,
    exact: true,
  },
];

const secured_pages = [
  {
    path: "/envision",
    component: Envision,
    secured: true,
  },
  {
    path: "/reflect",
    component: Reflect,
    secured: true,
  },
  {
    path: "/perform",
    component: PerformMain,
    secured: true,
  },
  {
    path: "/settings",
    component: SettingsMain,
    secured: true,
  },
  {
    path: "/myca-tour",
    component: MycaTour,
    secured: true,
  },
];

const admin_pages = [
  {
    path: "/dashboard",
    component: Dashboard,
    exact: true,
    secured: true,
    admin: true,
  },
];

const pages = [...public_pages, ...secured_pages, ...admin_pages];

export default pages;
