import { memo } from "react";
import PropTypes from "prop-types";
import WktButton from "../WktButton";
import { faCaretRight, faCaretDown } from "@fortawesome/pro-solid-svg-icons";

const WrksetButtonExpandToggle = ({ expanded, setExpand, jid }) => {
  return (
    <>
      {expanded ? (
        <WktButton
          data-testid={"wkt-expand-btn " + jid}
          isFAButton={false}
          className="mx-1 workset-icon expanded"
          icon={faCaretDown}
          tooltip="Collapse"
          onClick={e => {
            e.stopPropagation();
            setExpand(false);
          }}
          role="workset-icon"
        />
      ) : (
        <WktButton
          data-testid={"wkt-expand-btn " + jid}
          isFAButton={false}
          className="mx-1 workset-icon collapsed"
          icon={faCaretRight}
          tooltip="Expand"
          onClick={e => {
            e.stopPropagation();
            setExpand(true);
          }}
          role="workset-icon"
        />
      )}
    </>
  );
};

WrksetButtonExpandToggle.propTypes = {
  expanded: PropTypes.bool,
  setExpand: PropTypes.func.isRequired,
  jid: PropTypes.string.isRequired,
};

export default memo(WrksetButtonExpandToggle);
