import {
  useState,
  useEffect,
  useRef,
  useCallback,
  useLayoutEffect,
  useMemo,
  memo,
  Suspense,
} from "react";
import { Col, Collapse } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { AnimatePresence, motion, useAnimation } from "framer-motion";
import PropTypes from "prop-types";
import "x-frame-bypass";
import { usePopperTooltip } from "react-popper-tooltip";
import { DateTime } from "luxon";
import classNames from "classnames";

import {
  workette_filters as w_filter,
  session_actions as sact,
  workette_actions as wact,
  selectCurrentlySelectedLifeNode,
  getURL,
  validURL,
  inLinkWhiteList,
  getDeepChildrenId,
  nearestColor,
  print_wkt_name,
  get_meta,
  check_frozen,
} from "@myca/shared-component";

import { addSquare } from "../../../utils/utils";

import WorketteTree from "../WorketteTree";
import WktiFrameModal from "../wktforms/WktiFrameModal";
import WktAddForm from "../wktforms/WktAddForm";
import SuggestedWorkset from "../../aiviews/SuggestedWorkset";
import LinkPreview from "src/components/link-preview";
import WktNote from "src/components/wkt-note";
import {
  LeftButtons,
  WorketteTitle,
  Parent,
  HoverButtons,
  GroupItemCount,
  RightButtons,
  DateBadge,
} from "./cols";

import "./WktItemSingle.scss";

const WktItemSingle = ({
  expandState = false,
  showParent,
  toggleShow,
  rightWktActions,
  leftWktActions,
  recursive,
  purgeTree,
  compact_override,
  item,
  toggleExpand,
  showOn,
  tabKey,
  showHoverActions = true,
  withoutText,
  parentSnapshot,
  extraClassName = "",
  firstWkt = "",
  firstWktset = "",
  firstInProgress = "",
  hide_type,
  hide_more_options,
  context_override,
  hide_buttons,
  hideHidden,
  isTreeExpanded,
  showLeftMargin = true,
  sideLineColor = "transparent",
  hasExtraGap = false,
  override_filters,
  isDraggedOver = false,
}) => {
  const controls = useAnimation();

  const workette = useSelector(state => state.workette);
  const session = useSelector(state => state.session);
  const lifeNode = useSelector(selectCurrentlySelectedLifeNode);
  const { cur_date, toggle_trophy, drill_stack, sidebar_collapsed, token } = useSelector(
    state => state.session,
  );
  const parent = useSelector(state => state.workette.items[state.workette.items[item.jid].parent]);
  const drillOnboarding = useSelector(state => state.onboarding?.drill);
  const [expanded, setExpanded] = useState(false);
  const [showiFrame, setShowiFrame] = useState(false);
  const [hover, setHover] = useState(false);
  const [showAddForm, setShowAddForm] = useState(false);
  const [showNoteForm, setShowNoteForm] = useState(false);
  const [recommendedParent, setRecommendedParent] = useState(null);
  const [wRowClass, setWRowClass] = useState("lg");
  const [linkMetadata, setLinkMetadata] = useState(null);
  const wItemClass = useSelector(({ workette }) =>
    workette.items[workette.life["life"]]?.context?.settings?.mode === "compact" || compact_override
      ? "wkt-item-single-compact"
      : "wkt-item-single-comfortable",
  );

  const dispatch = useDispatch();
  const set_selected_wkt = useCallback(w_id => dispatch(sact.set_selected_wkt(w_id)), [dispatch]);
  const set_workette = useCallback(
    (w_id, ctx) => dispatch(wact.set_workette(w_id, ctx)),
    [dispatch],
  );
  const set_link_metadata = useCallback(
    (link_metadata, w_id) => dispatch(wact.set_link_metadata(link_metadata, w_id)),
    [dispatch],
  );
  const update_drill_stack = useCallback(
    newStacks => dispatch(sact.update_drill_stack(newStacks)),
    [dispatch],
  );

  const { getArrowProps, getTooltipProps, setTooltipRef, setTriggerRef, visible } =
    usePopperTooltip({
      visible: hover && item?.context?.wtype === "link",
      placement: "top",
    });

  const borderLeft = useMemo(
    () =>
      item.context.wtype === "workset"
        ? `15px solid ${nearestColor({
            w_id: item.jid,
            defaultColor: "#333333",
            skipSelf: false,
            items: workette.items,
          })}`
        : "",
    [item.context.wtype, item.jid, workette.items],
  );

  const marginLeft = useMemo(
    () => (workette.items[item.parent]?.name === "workette" && showLeftMargin ? "4px" : 0),
    [item.parent, showLeftMargin, workette.items],
  );

  const worketteRef = useRef(null);

  // const controls = {
  //   start: type => {
  //     setFadeAnimation(type);
  //   },
  // };

  const firstClass = useMemo(() => {
    if (item.context.wtype === "workette") {
      if (firstWkt === item.jid) {
        return "first-workette";
      }
    } else if (item.context.wtype === "workset") {
      if (firstWktset === item.jid) {
        return "first-workset";
      }
    }

    return "";
  }, [firstWkt, firstWktset, item.context.wtype, item.jid]);

  const firstInprogressClass = useMemo(
    () =>
      item.context.status === "running" && firstInProgress === item.jid ? "first-in-progress" : "",
    [firstInProgress, item.context.status, item.jid],
  );

  const isFirstItem = useMemo(() => {
    if (item.context.wtype === "workette") {
      return firstWkt === item.jid;
    } else if (item.context.wtype === "workset") {
      return firstWktset === item.jid;
    } else {
      return false;
    }
  }, [firstWkt, firstWktset, item.context.wtype, item.jid]);

  const alreadyHasTheRecommendParent = useMemo(
    () => getDeepChildrenId(item.jid, workette.items).includes(recommendedParent),
    [item.jid, recommendedParent, workette.items],
  );

  const generateRowClass = useCallback(() => {
    if (worketteRef.current) {
      const width = worketteRef?.current?.getBoundingClientRect?.().width || 0;
      if (Math.floor(width) < 600) {
        setWRowClass("sm");
      } else {
        setWRowClass("lg");
      }
    }
  }, [worketteRef]);

  const getUrlMetadata = useCallback(
    async (w_id, url) => {
      try {
        if (linkMetadata) {
          setLinkMetadata(linkMetadata);
          return;
        }

        const { og } = await get_meta(url, token);

        if (!og) {
          return;
        }

        set_link_metadata(og, w_id);
        setLinkMetadata(og);
      } catch (error) {}
    },
    [linkMetadata, set_workette, token],
  );

  const handleOnMouseOver = () => {
    setHover(true);

    if (item?.context?.wtype === "link") {
      if (item?.context?.link_metadata) {
        setLinkMetadata(item?.context?.link_metadata);
      } else {
        getUrlMetadata(item.jid, item.context.note?.replace(/<\/?[^>]+(>|$)/g, ""));
      }
    }
  };

  const toggleNoteForm = useCallback(
    state => {
      if (state) {
        update_drill_stack([...drill_stack, { jid: item.jid, type: "note" }]);
      }
    },
    [drill_stack, item.jid, update_drill_stack],
  );

  // Defines onclick behavior for the larger component
  const onClickDefaults = e => {
    e.stopPropagation();

    // parentSnapshot is from the filteredDayView, its the snapshot of drag and drop library
    // this is to fix the drag and drop issue
    if (parentSnapshot) {
      return true;
    }

    //If there are children or its a workset, default to toggling expand
    if (item.children.length > 0 || item.context.wtype === "workset") {
      if (expanded === true) {
        setShowAddForm(false);
      }

      toggleExpand ? toggleExpand(item.jid) : setExpanded(!expanded);
    }
    // If it is a note, show the note modal
    else if (item.context.wtype === "note") {
      setShowNoteForm(showNoteForm => !showNoteForm);
    }
    // if its a link and there's a valid URL listed, call open link function
    else if (item.context.wtype === "link") {
      if (validURL(getURL(item))) {
        openLink({ name: item.context.name, url: getURL(item) });
      } else {
        set_selected_wkt(item.jid);
      }
    }
    // Otherwise, set selected wkt to open edit item modal
    else {
      set_selected_wkt(item.jid);
    }
  };

  const openLink = link => {
    if (inLinkWhiteList(link.url)) {
      setShowiFrame(true);
    } else {
      if (window.__TAURI__) {
        window.__TAURI__.shell.open(link.url);
      } else {
        window.open(link.url, "_blank");
      }
    }
  };

  const handleOnMouseLeave = () => {
    setHover(false);
  };

  useLayoutEffect(() => {
    generateRowClass();

    if (worketteRef.current) {
      window.addEventListener("resize", generateRowClass, true);
    }
    return () => {
      window.removeEventListener("resize", generateRowClass, true);
    };
  }, [generateRowClass]);

  useEffect(() => {
    setExpanded(expandState);
  }, [expandState]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      generateRowClass();
    }, 300);

    return () => clearTimeout(timeout);
  }, [generateRowClass, toggle_trophy, sidebar_collapsed]);

  useEffect(() => {
    if (lifeNode) {
      if (!!lifeNode.context?.settings.aiFlag) {
        setRecommendedParent(item.context.parentR);
      } else {
        setRecommendedParent(null);
      }
    }
  }, [item.context.parentR, lifeNode]);

  useEffect(() => {
    if (!isTreeExpanded) {
      setShowAddForm(false);
    }
  }, [isTreeExpanded]);

  const backgroundColor = useMemo(() => {
    if (item.context.status === "done") {
      return "#dbf1e3";
    } else if (item.context.status === "canceled") {
      return "#edeef2";
    } else if (item.context.status === "running") {
      return "#e7e7ff";
    }

    if (!w_filter.scheduled_now(item, cur_date)) {
      return "#FFFFED";
    }

    switch (item.context.wtype) {
      case "link":
      case "note":
        return "#deefff";
      default:
        return "#FFFFFF";
    }
  }, [cur_date, item]);

  const animateVariants = {
    default: {
      backgroundColor,
    },
    open: {
      backgroundColor: "#ffff",
    },
    snoozed: {
      backgroundColor: "#ffffed",
    },
    done: {
      backgroundColor: "#dbf1e3",
    },
    canceled: {
      backgroundColor: "#edeef2",
    },
    running: {
      backgroundColor: "#e7e7ff",
    },
  };

  return (
    <>
      <motion.div
        className="d-flex flex-column w-100 workette"
        style={{ "--sideLineColor": sideLineColor }}
        data-extra-gap={hasExtraGap}
        data-mode={lifeNode?.context?.settings?.mode}
        ref={setTriggerRef}
        key={"wkt-item-single " + item.jid}
        exit={{ opacity: 0 }}
      >
        <motion.div
          ref={worketteRef}
          data-testid={"wkt-item-single " + item.jid}
          onMouseEnter={handleOnMouseOver}
          onMouseLeave={handleOnMouseLeave}
          className={classNames(
            "single-workette-item",
            wRowClass,
            wItemClass,
            extraClassName,
            firstClass,
            firstInprogressClass,
            {
              snoozed: !w_filter.scheduled_now(item, cur_date),
            },
          )}
          style={{ borderLeft, marginLeft }}
          data-dragover={isDraggedOver}
          variants={animateVariants}
          initial={"default"}
          animate={controls}
        >
          <div className="left" data-testid={"workette-item-" + item.jid} onClick={onClickDefaults}>
            <Col className="left-icons">
              {!hide_buttons && (
                <LeftButtons
                  item={item}
                  expanded={expanded}
                  setShowAddForm={setShowAddForm}
                  setExpanded={setExpanded}
                  toggleExpand={toggleExpand}
                  controls={controls}
                  leftWktActions={leftWktActions}
                />
              )}
            </Col>
            <div className="workette-title">
              <WorketteTitle name={print_wkt_name(item)} item={item} hover={hover} />
              {workette.items[recommendedParent] && !alreadyHasTheRecommendParent ? (
                <div
                  onClick={e => {
                    e.stopPropagation();
                  }}
                >
                  <SuggestedWorkset
                    w_id={item.jid}
                    recommendedParentID={recommendedParent}
                    setRecommended={setRecommendedParent}
                    wRowClass={wRowClass}
                  />
                </div>
              ) : (
                showParent &&
                parent?.context?.wtype === "workset" && (
                  <Parent
                    parentJid={parent.jid}
                    square={addSquare(
                      nearestColor({
                        w_id: item.jid,
                        defaultColor: "#333333",
                        skipSelf: true,
                        items: workette.items,
                      }),
                    )}
                    parentName={print_wkt_name(parent)}
                  />
                )
              )}
              {(drillOnboarding?.running &&
                (extraClassName?.split(" ").includes("onboarding-drill-item") ||
                  extraClassName?.split(" ").includes("myca-tour-drill"))) ||
              (hover && showHoverActions && !hide_buttons) ||
              ((item?.context?.wtype === "workset" || item?.children?.length > 0) && expanded) ? (
                <div className="hover-buttons" data-testid="hover-buttons">
                  <HoverButtons
                    item={item}
                    showAddForm={showAddForm}
                    setShowAddForm={setShowAddForm}
                    toggleExpand={toggleExpand}
                    setExpanded={setExpanded}
                    setShowNoteFrame={toggleNoteForm}
                    setShowNoteForm={setShowNoteForm}
                  />
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
          <div className="right">
            <Col className="due-date">
              {item.context.due_date && (
                <DateBadge
                  date={DateTime.fromISO(item.context.due_date, { zone: "utc" }).toFormat("LLL dd")}
                  tooltip="Due Date"
                  badgeClass="due-date"
                  testId="due-date"
                />
              )}
            </Col>
            <Col className="children-count">
              {w_filter.countDeepChildren(item, workette, false, cur_date) > 0 && (
                <GroupItemCount
                  count={w_filter.countDeepChildrenClosed(item, workette, false, cur_date)}
                  over={w_filter.countDeepChildren(item, workette, false, cur_date)}
                />
              )}
            </Col>
            {!hide_buttons && (
              <Col className="right-icons" data-testid="right-icons">
                <RightButtons
                  item={item}
                  toggleShow={toggleShow}
                  toggleExpand={toggleExpand}
                  showOn={showOn}
                  rightWktActions={rightWktActions}
                  controls={controls}
                  purgeTree={purgeTree}
                  wRowClass={wRowClass}
                  tabKey={tabKey}
                  withoutText={withoutText}
                  isFirstItem={isFirstItem}
                  setShowNoteFrame={toggleNoteForm}
                  setShowNoteForm={setShowNoteForm}
                  showAddForm={showAddForm}
                  setShowAddForm={setShowAddForm}
                  setExpanded={setExpanded}
                  firstWkt={firstWkt}
                />
              </Col>
            )}
          </div>
        </motion.div>
        {!check_frozen(session) && item?.context?.wtype === "workset" && expanded ? (
          <div
            className={`workset-add-form${showAddForm ? " visible" : ""} ${
              firstClass ? `${firstClass}-form` : ""
            }`}
            data-testid={`workset-add-form ${item.jid}`}
          >
            <Suspense fallback="">
              <span data-testid={`add-form ${item.jid}`}>
                <WktAddForm
                  w_id={item.jid}
                  isFirstItem={isFirstItem}
                  hide_type={hide_type}
                  hide_more_options={hide_more_options}
                  context_override={context_override}
                  extra_class="workset-add-form-accordion"
                  isOpen={showAddForm}
                />
              </span>
            </Suspense>
          </div>
        ) : (
          <Suspense fallback="">
            <div
              className={classNames(
                "workette-add-form",
                workette.items[workette.life["life"]]?.context?.settings?.mode,
              )}
            >
              <WktAddForm
                w_id={item.jid}
                isFirstItem={isFirstItem}
                hide_type={hide_type}
                hide_more_options={hide_more_options}
                context_override={context_override}
                isOpen={showAddForm}
              />
            </div>
          </Suspense>
        )}
        <Collapse in={showNoteForm} unmountOnExit mountOnEnter>
          <div className="workette-note-form p-2">
            <WktNote w_id={item.jid} hasFullScreen />
          </div>
        </Collapse>
      </motion.div>
      <AnimatePresence>
        {item.children.length > 0 && recursive && expanded && (
          <WorketteTree
            root={item.jid}
            leftWktActions={leftWktActions}
            rightWktActions={rightWktActions}
            parentSnapshot={parentSnapshot}
            hideHidden={hideHidden}
            override_filters={override_filters}
          />
        )}
      </AnimatePresence>
      {item.context.wtype === "link" && (
        <WktiFrameModal
          item={item}
          link={{ name: item.context.name, url: getURL(item) }}
          show={showiFrame}
          onHide={() => setShowiFrame(false)}
        />
      )}
      {visible && linkMetadata && (
        <div ref={setTooltipRef} {...getTooltipProps({ className: "tooltip-container" })}>
          <div {...getArrowProps({ className: "tooltip-arrow" })} />
          <LinkPreview title={item.context.name} linkMetadata={linkMetadata} />
        </div>
      )}
    </>
  );
};

WktItemSingle.propTypes = {
  expandState: PropTypes.bool,
  showParent: PropTypes.bool,
  toggleShow: PropTypes.func,
  rightWktActions: PropTypes.array,
  leftWktActions: PropTypes.array,
  recursive: PropTypes.bool,
  purgeTree: PropTypes.func,
  compact_override: PropTypes.bool,
  item: PropTypes.object.isRequired,
  toggleExpand: PropTypes.func,
  showOn: PropTypes.bool,
  tabKey: PropTypes.string,
  showHoverActions: PropTypes.bool,
  withoutText: PropTypes.bool,
  parentSnapshot: PropTypes.bool,
  extraClassName: PropTypes.string,
  firstWkt: PropTypes.string,
  firstWktset: PropTypes.string,
  firstInProgress: PropTypes.string,
  hide_type: PropTypes.bool,
  hide_more_options: PropTypes.bool,
  context_override: PropTypes.object,
  hide_buttons: PropTypes.bool,
  hideHidden: PropTypes.bool,
  isTreeExpanded: PropTypes.bool,
  showLeftMargin: PropTypes.bool,
  sideLineColor: PropTypes.string,
  hasExtraGap: PropTypes.bool,
  override_filters: PropTypes.object,
  isDraggedOver: PropTypes.bool,
};

export default memo(WktItemSingle);
