import { memo } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { Tab, Tabs } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";

import FullDayView from "../../dayviews/FullDayView";
import DailyJournal from "../../dayviews/DailyJournal";
import PerformTabView from "../PerformTabView/PerformTabView";
import PerformDateSelector from "../PerformDateSelector";
import FrozenBar from "../FrozenBar";
import { is_today } from "@myca/shared-component";

import "react-calendar/dist/Calendar.css";
import "./PerformMainView.scss";

/**
 * Center component of Perform page, generates Focus, Rituals, and Day workette list components
 * @param {*} props
 */

const PerformMainView = ({ tabKey, setTabKey, rowRef, rowClass }) => {
  const { days } = useSelector(state => state.workette);
  const { freeze_override, cur_date } = useSelector(state => state.session);

  return (
    <div data-testid="perform-main-view" className="perform-main-view myca-tab">
      <div className="tab-header">
        {!cur_date ? (
          <div className="datepicker-placeholder">
            <Skeleton height={24} />
            <Skeleton height={24} />
          </div>
        ) : (
          <PerformDateSelector />
        )}
        {cur_date && !is_today(cur_date) && !freeze_override && <FrozenBar />}
      </div>
      <Tab.Container fluid>
        <Tabs
          id="perform-main-view"
          unmountOnExit={true}
          mountOnEnter={true}
          activeKey={tabKey}
          onSelect={setTabKey}
        >
          <Tab
            tabClassName="perform-tabs plan-tab"
            eventKey="plan"
            title="Plan"
            disabled={!cur_date}
          >
            <FullDayView root={days[cur_date]} label="Plan" />
          </Tab>
          <Tab
            data-testid="perform-tab"
            tabClassName="perform-tabs perform-tab"
            eventKey="focus"
            title="Focus"
            disabled={!cur_date}
          >
            <PerformTabView
              setTabKey={setTabKey}
              tabKey={tabKey}
              rowRef={rowRef}
              rowClass={rowClass}
            />
          </Tab>
          <Tab tabClassName="perform-tabs" eventKey="journal" title="Journal" disabled={!cur_date}>
            {days[cur_date] && <DailyJournal w_id={days[cur_date]} />}
          </Tab>
        </Tabs>
      </Tab.Container>
    </div>
  );
};

PerformMainView.propTypes = {
  tabKey: PropTypes.string,
  setTabKey: PropTypes.func,
  rowRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.any })]),
  rowClass: PropTypes.string,
};

export default memo(PerformMainView);
