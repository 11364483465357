import { useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";

import {
  workette_actions as wact,
  onboarding_actions as onb_act,
  session_actions as sact,
  selectCurrentlySelectedLifeNode,
  selectCurrentlySelectedDayNode,
  logTour,
} from "@myca/shared-component";

const useOnboardingFunctions = (name, action) => {
  const lifeID = useSelector(state => state.workette.life);
  const lifeNode = useSelector(selectCurrentlySelectedLifeNode);
  const dayNode = useSelector(selectCurrentlySelectedDayNode);

  const dispatch = useDispatch();
  const set_workette = useCallback(
    (w_id, ctx) => dispatch(wact.set_workette(w_id, ctx, false, true)),
    [dispatch],
  );
  const stopOnboarding = useCallback(
    () => action && dispatch(onb_act[action].stop()),
    [action, dispatch],
  );
  const updateStepOnboarding = useCallback(
    step => action && dispatch(onb_act[action].next_or_prev(step)),
    [action, dispatch],
  );
  const restartOnboarding = useCallback(
    () => action && dispatch(onb_act[action].restart()),
    [action, dispatch],
  );
  const setVideoPlaying = useCallback(url => dispatch(sact.set_video_playing(url)), [dispatch]);

  const finishOnboarding = useCallback(() => {
    if (name) {
      logTour(lifeID, lifeNode, set_workette, name);
      restartOnboarding();
    }
  }, [lifeID, lifeNode, name, restartOnboarding, set_workette]);

  const setReminderDate = useCallback(
    (type, date, isDone = false) => {
      if (!lifeNode || !type || !date) {
        return;
      }

      const lifeContext = { ...lifeNode.context };
      lifeContext.settings[`${type}_reminded_at`] = date;
      lifeContext.settings[`${type}_reminded_at`] = date;

      if (type === "past") {
        lifeContext.settings[`${type}_prompt_done`] = isDone;
      }

      set_workette(lifeID["life"], { settings: lifeContext.settings });
    },
    [lifeID, lifeNode, set_workette],
  );

  useEffect(() => {}, []);

  return {
    lifeID,
    lifeNode,
    dayNode,
    finishOnboarding,
    stopOnboarding,
    restartOnboarding,
    updateStepOnboarding,
    set_workette,
    setReminderDate,
    setVideoPlaying,
  };
};

export default useOnboardingFunctions;
