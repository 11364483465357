import { forwardRef, useCallback } from "react";
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/pro-solid-svg-icons";
import PropTypes from "prop-types";

import { ResultItem } from "../ResultItem/ResultItem";

const ParentSelectionModalTreeItem = forwardRef(
  ({ item, provided, selectedParentId, onClick, onExpand, onCollapse }, ref) => {
    const handleExpandToggleClick = useCallback(
      e => {
        e.stopPropagation();

        if (item?.isExpanded) {
          onCollapse && onCollapse(item);
        } else {
          onExpand && onExpand(item);
        }
      },
      [item, onCollapse, onExpand],
    );

    const renderExpandToggle = () => {
      if (item.hasChildren || item.data?.context.wtype === "workset") {
        return (
          <Button
            ref={ref}
            as="div"
            variant="secondary"
            size="sm"
            onClick={handleExpandToggleClick}
            className={item?.isExpanded ? "expanded" : "collapsed"}
            data-testid={`expand-collapse-btn ${item.id}`}
          >
            <FontAwesomeIcon icon={item?.isExpanded ? faChevronUp : faChevronDown} />
          </Button>
        );
      }

      return null;
    };

    return (
      item.data?.name === "workette" && (
        <div
          ref={provided.innerRef}
          className="d-flex align-items-center"
          style={provided.draggableProps.style}
        >
          <ResultItem
            item={item.data}
            selectedParentId={selectedParentId}
            onClick={onClick}
            renderRightAccessory={renderExpandToggle}
          />
        </div>
      )
    );
  },
);

ParentSelectionModalTreeItem.defaultProps = {
  onExpand: () => null,
  onCollapse: () => null,
  onClick: () => null,
};

ParentSelectionModalTreeItem.propTypes = {
  /**
   * The tree item to render.
   */
  item: PropTypes.object.isRequired,
  /**
   * The `provided` object that `@atlaskit/tree` provides.
   */
  provided: PropTypes.object.isRequired,
  /**
   * The ID of the parent node that should initially be shown as "selected."
   */
  selectedParentId: PropTypes.string,
  /**
   * Called when the user clicks on the item.
   */
  onClick: PropTypes.func,
  /**
   * The function to call when an item expand request is made.
   */
  onExpand: PropTypes.func,
  /**
   * The function to call when an item collapse request is made.
   */
  onCollapse: PropTypes.func,
};

ParentSelectionModalTreeItem.displayName = "ParentSelectionModalTreeItem";

export { ParentSelectionModalTreeItem };
