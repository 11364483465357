import { useCallback, useState } from "react";
import { useSelector } from "react-redux";

import PropTypes from "prop-types";
import { ListGroupItem } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEyeSlash, faLink, faStickyNote } from "@fortawesome/pro-solid-svg-icons";
import {
  remove_date_offset,
  workette_filters,
  selectNodeParents,
  print_wkt_name,
} from "@myca/shared-component";
import "./ResultItem.scss";
import { getNodeName } from "../../../utils/getNodeName";

/**
 * Renders a workette as a list item to be used in result lists, e.g. search results.
 */
const ResultItem = ({
  renderRightAccessory,
  item,
  selectedParentId,
  showBreadcrumbs,
  onClick,
  children,
  hasCheckBox,
  selectedAssociates = [],
}) => {
  const [onHover, setOnHover] = useState(false);

  const parents = useSelector(state => selectNodeParents(state, item.jid));

  const isItemHidden = item => {
    return !workette_filters.scheduled_now(item);
  };

  const handleItemClick = useCallback(
    e => {
      e.stopPropagation();

      onClick && onClick(item.jid);
    },
    [item.jid, onClick, selectedParentId],
  );

  const getBackgroundColorStyle = () => {
    if (isItemHidden(item)) {
      return {
        backgroundColor: "#ffffed",
      };
    }

    return null;
  };

  const renderSnoozedUntilMessage = () => {
    const snoozedUntil = item.context.snooze_till;

    if (!isItemHidden(item) || snoozedUntil == null || snoozedUntil === "") {
      return null;
    }

    return (
      <div className="text-muted small font-italic">
        Snoozed until {remove_date_offset(snoozedUntil).toDateString()}
      </div>
    );
  };

  const renderNotRecurringTodayMessage = () => {
    if (!isItemHidden(item)) {
      return null;
    }

    return <div className="text-muted small font-italic">Not scheduled for today</div>;
  };

  const renderIcon = () => {
    if (isItemHidden(item)) {
      return <FontAwesomeIcon icon={faEyeSlash} className="mr-2" />;
    }

    switch (item.context.wtype) {
      case "note":
        return <FontAwesomeIcon icon={faStickyNote} className="mr-2" />;
      case "link":
        return <FontAwesomeIcon icon={faLink} className="mr-2" />;
      case "workset":
        const color =
          item.context.color != null && item.context.color !== "" ? item.context.color : "#000";
        const colorStyle = { backgroundColor: color };

        return (
          <div className="result-item__workset-icon mr-2 align-self-stretch" style={colorStyle} />
        );
      default:
        return null;
    }
  };

  const renderBreadcrumbs = () => {
    const parentNames = parents.map(parent => getNodeName(parent)).reverse();

    return <div className="small text-muted mt-2">{parentNames.join(" > ")}</div>;
  };

  return (
    <ListGroupItem
      action
      className="border-0 d-flex flex-column w-100"
      style={getBackgroundColorStyle()}
      onMouseEnter={() => setOnHover(true)}
      onMouseLeave={() => setOnHover(false)}
      onClick={handleItemClick}
      data-testid={`suggested-wkt-${item.jid}`}
    >
      <div className="d-flex align-items-center gap-2 w-100">
        {hasCheckBox && (onHover || selectedAssociates.includes(item.jid)) ? (
          <input
            type="checkbox"
            className="cb-selected-goal"
            aria-label="selected goal associate"
            checked={selectedAssociates.includes(item.jid)}
            readOnly
          />
        ) : (
          <div className="cb-placeholder"></div>
        )}
        {renderRightAccessory && (
          <div className="result-item__expand">{renderRightAccessory()}</div>
        )}
        <div className="d-flex overflow-hidden align-items-center flex-1">
          {renderIcon()}
          <span className="result-item__title flex-1">{print_wkt_name(item)}</span>
        </div>
      </div>

      {children}
      {renderSnoozedUntilMessage()}
      {renderNotRecurringTodayMessage()}
      {showBreadcrumbs && (
        <div className="d-flex gap-2">
          <div className="cb-placeholder"></div>
          {renderBreadcrumbs()}
        </div>
      )}
    </ListGroupItem>
  );
};

ResultItem.defaultProps = {
  onClick: () => null,
};

ResultItem.propTypes = {
  /**
   * The item to render.
   */
  item: PropTypes.object.isRequired,
  /**
   * The ID of the parent node that should initially be shown as "selected."
   */
  selectedParentId: PropTypes.string,
  /**
   * A function that renders a right accessory, e.g. an expand toggle.
   */
  renderRightAccessory: PropTypes.func,
  /**
   * When set to `true`, the hierarchy that the item is shown as breadcrumbs.
   */
  showBreadcrumbs: PropTypes.bool,
  /**
   * Called when the user clicks on the item.
   */
  onClick: PropTypes.func,

  children: PropTypes.node,
  hasCheckBox: PropTypes.bool,
  selectedAssociates: PropTypes.array,
};

export { ResultItem };
