import { useEffect, useState, useCallback, useMemo } from "react";
import { Col, Nav, Tab } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import { workette_actions as wact, selectCurrentlySelectedLifeNode } from "@myca/shared-component";
import AppSettings from "./AppSettings";
import ProfileSettings from "./ProfileSettings";
import Subscription from "./subscription";
import Referral from "./Referral";

import "./SettingsMain.scss";

const SettingsMain = () => {
  const history = useHistory();
  const { life } = useSelector(state => state.workette);
  const { logged_in } = useSelector(state => state.session);

  const lifeID = useSelector(state => state.workette.life["life"]);
  const lifeNode = useSelector(selectCurrentlySelectedLifeNode);

  const eventKey = useMemo(() => {
    const route = history.location.pathname;
    if (route === "/settings") {
      return "config";
    } else if (route === "/settings/profile") {
      return "profile";
    } else if (route === "/settings/subscription") {
      return "subscription";
    } else if (route === "/settings/referral") {
      return "referral";
    }
  }, [history.location.pathname]);

  const [settings, setSettings] = useState({
    mode: "comfortable",
    aiFlag: true,
    use_personalized_parent_recommendation: false,
  });

  const dispatch = useDispatch();
  const set_workette = useCallback(
    (w_id, ctx) => dispatch(wact.set_workette(w_id, ctx)),
    [dispatch],
  );

  const handleSave = useCallback(
    e => {
      e.preventDefault();
      set_workette(lifeID, { settings });
    },
    [lifeID, set_workette, settings],
  );

  useEffect(() => {
    if (lifeNode) {
      setSettings(settings => ({ ...settings, ...lifeNode.context.settings }));
    }
  }, [lifeNode]);

  const onClickTab = key => {
    if (key === "config") {
      history.push("/settings");
    } else if (key === "profile") {
      history.push("/settings/profile");
    } else if (key === "subscription") {
      history.push("/settings/subscription");
    } else if (key === "referral") {
      history.push("/settings/referral");
    }
  };

  return (
    logged_in &&
    life && (
      <div className="settings-wrapper">
        <Tab.Container defaultActiveKey={eventKey} onSelect={onClickTab}>
          <div className="d-flex flex-wrap">
            <Col md={3} className="settings-nav">
              <Nav className="flex-column">
                <h5 className="p-3 mb-0">Settings:</h5>
                <Nav.Item>
                  <Nav.Link eventKey="config">General</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="profile">Profile</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="subscription">Subscription</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="referral">Referral</Nav.Link>
                </Nav.Item>
              </Nav>
            </Col>
            <Col className="p-3">
              <Tab.Content>
                <Tab.Pane eventKey="config">
                  <AppSettings
                    setSettings={setSettings}
                    settings={settings}
                    handleSave={handleSave}
                  />
                </Tab.Pane>
                <Tab.Pane eventKey="profile">
                  <ProfileSettings settings={settings} />
                </Tab.Pane>
                <Tab.Pane eventKey="subscription">
                  {eventKey === "subscription" && <Subscription />}
                </Tab.Pane>
                <Tab.Pane eventKey="referral">{eventKey === "referral" && <Referral />}</Tab.Pane>
              </Tab.Content>
            </Col>
          </div>
        </Tab.Container>
      </div>
    )
  );
};

export default SettingsMain;
