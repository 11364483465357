import { memo } from "react";
import PropTypes from "prop-types";
import Tooltip from "react-simple-tooltip";

const DateBadge = ({ date, tooltip = "", badgeClass = "", testId = "" }) => {
  return tooltip ? (
    <Tooltip
      padding={10}
      fontSize="11px"
      arrow={4}
      content={<span style={{ whiteSpace: "nowrap" }}>{tooltip}</span>}
      background="#000000C0"
      radius={7}
    >
      <span data-testid={testId} className={`date-badge ${badgeClass}`}>
        {date}
      </span>
    </Tooltip>
  ) : (
    <span data-testid={testId} className={`date-badge ${badgeClass}`}>
      {date}
    </span>
  );
};

DateBadge.propTypes = {
  date: PropTypes.string.isRequired,
  tooltip: PropTypes.string,
  badgeClass: PropTypes.string,
  testId: PropTypes.string,
};

export default memo(DateBadge);
