import { memo } from "react";
import PropTypes from "prop-types";

import {
  WktButtonComplete,
  WktButtonFocus,
  WktButtonRunning,
  WktButtonAbandon,
  WrksetButtonExpandToggle,
  WktButtonSnooze,
  WktButtonRitual,
  WktButtonEdit,
  WktButtonAdd,
  WktButtonDrill,
  WktButtonNote,
  WktButtonEye,
  WktButtonFocusRecommendation,
  WktButtonDelete,
  WktButtonFilter,
  WktButtonAssociateRecommendation,
  WktButtonAddToGoogleCalendar,
} from "./buttons";

/**
 * Centralized workette button
 * @param {object} item workette item
 * @param {array} buttons list of all buttons that should render
 * @param {object} controls reference for the animation of the workette item single
 * @param {func} purgeTree function to remove the item in the tree
 * @param {bool} withoutText use to decide whether the focus button should have the "Focus" text
 * @param {bool} expanded state whether the workset is expanded or not
 * @param {func} setShowAddForm function to display the add form
 * @param {func} toggleExpand function to toggle the workset to expand/collapse
 * @param {func} setExpanded function to toggle the expanded state
 * @param {bool} showAddForm state whether the add form is shown or not
 * @param {bool} showOn state whether the toggle eye button is on or off state
 * @param {func} toggleShow controls logic for show/hiding items, including saving state
 * @param {string} wRowClass returns "sm" or "lg", "sm" if the width of workette single is less than 500px
 * @param {bool} isDropDown state whether the right buttons is in dropdown view
 * @param {func} setIsOpenRecommendation controls logic to toggle FocusRecommendation component visibility
 */

const WktButtons = ({
  item,
  buttons = [],
  controls,
  purgeTree,
  withoutText,
  expanded,
  setShowAddForm,
  toggleExpand,
  setExpanded,
  showAddForm,
  setShowNoteFrame,
  setShowNoteForm,
  showOn,
  toggleShow,
  wRowClass,
  isDropDown,
  setIsOpenRecommendation,
  isFirstItem,
  firstWkt,
  handleOnClickRecommendation,
}) => {
  const createButton = (button, index) => {
    switch (button) {
      case "edit":
        return <WktButtonEdit key={index} jid={item.jid} />;
      case "add":
        return (
          <WktButtonAdd
            key={index}
            jid={item.jid}
            showAddForm={showAddForm}
            setShowAddForm={setShowAddForm}
            toggleExpand={toggleExpand}
            setExpanded={setExpanded}
          />
        );
      case "drill":
        return <WktButtonDrill key={index} jid={item.jid} />;
      case "note":
        return (
          <WktButtonNote
            key={index}
            jid={item.jid}
            setShowNoteFrame={setShowNoteFrame}
            setShowNoteForm={setShowNoteForm}
          />
        );
      case "eye":
        return (
          <WktButtonEye
            key={index}
            jid={item.jid}
            showOn={showOn}
            toggleExpand={toggleExpand}
            toggleShow={toggleShow}
            isFirstItem={isFirstItem}
          />
        );
      case "complete":
        return (
          <WktButtonComplete key={index} purgeTree={purgeTree} item={item} controls={controls} />
        );
      case "focus":
        return (
          <WktButtonFocus
            key={index}
            item={item}
            controls={controls}
            withoutText={withoutText || wRowClass === "sm"}
            isFirstItem={isFirstItem}
          />
        );
      case "running":
        return (
          <WktButtonRunning
            key={index}
            item={item}
            controls={controls}
            isFirstItem={Boolean(
              item.context.wtype === "workette" && firstWkt && firstWkt === item.jid,
            )}
          />
        );
      case "abandon":
        return (
          <WktButtonAbandon key={index} purgeTree={purgeTree} item={item} controls={controls} />
        );
      case "ritual":
        return <WktButtonRitual key={index} item={item} isFirstItem={isFirstItem} />;
      case "snooze":
        return (
          <WktButtonSnooze
            key={index}
            item={item}
            controls={controls}
            isFirstItem={Boolean(
              item.context.wtype === "workette" && firstWkt && firstWkt === item.jid,
            )}
          />
        );
      case "filter":
        return <WktButtonFilter key={index} item={item} />;
      case "focus-recommendation":
        return (
          <WktButtonFocusRecommendation
            key={index}
            setIsOpenRecommendation={setIsOpenRecommendation}
          />
        );
      case "goal-recommendation":
        return (
          <WktButtonAssociateRecommendation
            key={index}
            jid={item.jid}
            onClick={handleOnClickRecommendation}
          />
        );
      case "expandToggle":
        return (
          <WrksetButtonExpandToggle
            key={index}
            expanded={expanded}
            setExpand={() => {
              if (expanded) {
                setShowAddForm(false);
              }
              toggleExpand ? toggleExpand(item.jid) : setExpanded(expanded => !expanded);
            }}
            jid={item.jid}
          />
        );
      case "delete":
        return <WktButtonDelete key={index} jid={item.jid} />;
      case "calendar":
        return <WktButtonAddToGoogleCalendar key={index} jid={item.jid} />;
      default:
        return <></>;
    }
  };

  if (isDropDown) {
    return (
      <>
        {buttons.length > 0 &&
          buttons.map((b, i) => (
            <div key={i} className="dd-item">
              {createButton(b, i)}
            </div>
          ))}
      </>
    );
  }

  return <>{buttons.length > 0 && buttons.map(createButton)}</>;
};

WktButtons.propTypes = {
  item: PropTypes.object,
  buttons: PropTypes.array,
  controls: PropTypes.object,
  purgeTree: PropTypes.func,
  withoutText: PropTypes.bool,
  expanded: PropTypes.bool,
  setShowAddForm: PropTypes.func,
  toggleExpand: PropTypes.func,
  setExpanded: PropTypes.func,
  showAddForm: PropTypes.bool,
  setShowNoteFrame: PropTypes.func,
  setShowNoteForm: PropTypes.func,
  showOn: PropTypes.bool,
  toggleShow: PropTypes.func,
  wRowClass: PropTypes.string,
  isDropDown: PropTypes.bool,
  setIsOpenRecommendation: PropTypes.func,
  isFirstItem: PropTypes.bool,
  firstWkt: PropTypes.string,
  handleOnClickRecommendation: PropTypes.func,
};

export default memo(WktButtons);
