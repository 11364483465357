import { memo } from "react";
import PropTypes from "prop-types";

function GroupItemCount({ count, over }) {
  return (
    <div
      className="badge badge-info inline-block"
      style={{
        color: "black",
        backgroundColor: "#00000015",
        opacity: 1,
      }}
    >
      <div>
        {count}/{over}
      </div>
    </div>
  );
}

GroupItemCount.propTypes = {
  count: PropTypes.number.isRequired,
  over: PropTypes.number.isRequired,
};

export default memo(GroupItemCount);
