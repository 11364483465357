import { memo } from "react";
import PropTypes from "prop-types";

const WorketteTitle = ({ name, item }) => {
  return (
    <span className="title" title={item?.context?.wtype !== "link" ? name : ""}>
      {name}
    </span>
  );
};

WorketteTitle.propTypes = {
  name: PropTypes.string.isRequired,
  item: PropTypes.object,
};

export default memo(WorketteTitle);
