import { useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  workette_actions as wact,
  session_actions as sact,
  subscription_actions as subs_act,
  reflect_actions as ref_act,
  onboarding_actions as onb_act,
  myca_buddy_actions as mbact,
  calendar_actions as cact,
} from "@myca/shared-component";
import { Redirect } from "react-router-dom";

const LogOut = () => {
  const { logged_in } = useSelector(state => state.session);
  const onboarding = useSelector(state => state.onboarding);

  const dispatch = useDispatch();
  const logout = useCallback(() => dispatch(sact.logout()), [dispatch]);
  const wkt_logout = useCallback(() => dispatch(wact.logout()), [dispatch]);
  const subscription_logout = useCallback(() => dispatch(subs_act.logout()), [dispatch]);
  const clear_highlights = useCallback(() => dispatch(ref_act.clear_highlights()), [dispatch]);
  const restartOnboarding = useCallback(
    onboarding => onboarding && dispatch(onb_act[onboarding].restart()),
    [dispatch],
  );
  const mycaBuddyLogout = useCallback(() => dispatch(mbact.logout()), [dispatch]);
  const calendarLogout = useCallback(() => dispatch(cact.calendar_logout()), [dispatch]);

  useEffect(() => {
    localStorage.clear();
    logout();
    wkt_logout();
    clear_highlights();
    Object.keys(onboarding).forEach(onboarding => {
      restartOnboarding(onboarding);
    });
    subscription_logout();
    mycaBuddyLogout();
    calendarLogout();
  }, []);

  return !logged_in && <Redirect to="/login" />;
};

export default LogOut;
