import { useState, useEffect, useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  REACT_APP_STRIPE_MONTHLY_PRICE_ID,
  REACT_APP_STRIPE_YEARLY_PRICE_ID,
  subscription_actions as subsact,
  getCurrencySymbol,
} from "@myca/shared-component";

import BillingIssue from "./BillingIssue";
import PlanSelection from "./PlanSelection";
import MobileSubscriptionDisplay from "./MobileSubscriptionDisplay";
import SubscriptionFooter from "./SubscriptionFooter";
import Features from "./Features";

import "./Subscription.scss";
import StripeLoader from "./StripeLoader";
import SubscriptionModal from "./SubscriptionModal";

const PLANS = [
  {
    price_id: REACT_APP_STRIPE_YEARLY_PRICE_ID,
    plan_type: "yearly_subscription",
    interval: "year",
    currency: "usd",
    amount: parseFloat(11999 / 100).toFixed(2),
    amount_description: "$119.99/year, that's $9.99/month",
    name: "Yearly",
    description: "Annual",
    extra: "save 33%",
  },
  {
    price_id: REACT_APP_STRIPE_MONTHLY_PRICE_ID,
    plan_type: "monthly_subscription",
    interval: "month",
    currency: "usd",
    amount: parseFloat(1499 / 100).toFixed(2),
    amount_description: "$14.99/month",
    name: "Monthly",
    description: "Monthly",
  },
];

const Subscription = props => {
  const [showDialog, setShowDialog] = useState(false);
  const [showCancelDialog, setShowCancelDialog] = useState(false);
  const [planTobeSwitch, setPlanTobeSwitch] = useState(PLANS[0].price_id);
  const [isSwitchingPlan, setIsSwitchingPlan] = useState(false);
  const [cancelingSubscription, setCancelingSubscription] = useState(false);
  const [switchingSubscription, setSwitchingSubscription] = useState(false);

  const { revenue_cat, stripe_subscription_item, stripe_subscription, checkout_session } =
    useSelector(state => state.subscription);
  const { is_loading } = useSelector(state => state.api);

  const SHOW_LOADER =
    is_loading.includes("Switching subscription plan") ||
    is_loading.includes("Creating checkout session") ||
    is_loading.includes("Creating billing checkout session");

  const TRIAL_ELIGIBLE =
    revenue_cat && Object.keys(revenue_cat.subscriber?.subscriptions || {})?.length === 0;
  const CURRENT_SUBSCRIPTION =
    revenue_cat?.subscriber?.subscriptions[
      revenue_cat.subscriber?.entitlements?.standard?.product_identifier
    ];
  const SUBSCRIPTION_TYPE = CURRENT_SUBSCRIPTION?.store || "";
  const ON_TRIAL = Boolean(CURRENT_SUBSCRIPTION?.period_type === "trial");
  const SUBSCRIPTION_CANCELED = Boolean(CURRENT_SUBSCRIPTION?.unsubscribe_detected_at);
  const BILLING_ISSUE = Boolean(
    CURRENT_SUBSCRIPTION?.billing_issues_detected_at &&
      !CURRENT_SUBSCRIPTION?.unsubscribe_detected_at,
  );

  const MOBILE_SUBSCRIPTION = ["app_store", "play_store"].includes(SUBSCRIPTION_TYPE);
  const PROMOTIONAL_SUBSCRIPTION = CURRENT_SUBSCRIPTION && SUBSCRIPTION_TYPE === "promotional";

  const stripeSubscriptionPriceId = stripe_subscription_item?.price?.id;
  const currentSelectedPlan = PLANS.find(plan => plan.price_id === planTobeSwitch);

  const SUBSCRIPTION_EXPIRED = useMemo(
    () =>
      (new Date(CURRENT_SUBSCRIPTION?.expires_date).getTime() - new Date().getTime()) /
        (1000 * 3600 * 24) <
        0 ||
      (stripe_subscription?.status === "canceled" && !stripe_subscription?.cancel_at_period_end),
    [
      CURRENT_SUBSCRIPTION?.expires_date,
      stripe_subscription?.cancel_at_period_end,
      stripe_subscription?.status,
    ],
  );

  const buttonText = useMemo(() => {
    if (
      CURRENT_SUBSCRIPTION &&
      !SUBSCRIPTION_CANCELED &&
      !SUBSCRIPTION_EXPIRED &&
      stripeSubscriptionPriceId === planTobeSwitch
    ) {
      return "current plan";
    } else if (
      !SUBSCRIPTION_CANCELED &&
      !SUBSCRIPTION_EXPIRED &&
      stripeSubscriptionPriceId &&
      stripeSubscriptionPriceId !== planTobeSwitch
    ) {
      return "switch";
    } else if (SUBSCRIPTION_CANCELED && !SUBSCRIPTION_EXPIRED) {
      return "renew plan";
    } else if (TRIAL_ELIGIBLE) {
      return "try for free";
    } else {
      return "subscribe";
    }
  }, [
    TRIAL_ELIGIBLE,
    SUBSCRIPTION_CANCELED,
    SUBSCRIPTION_EXPIRED,
    planTobeSwitch,
    CURRENT_SUBSCRIPTION,
    stripeSubscriptionPriceId,
  ]);

  const headerMessage = useMemo(() => {
    const subscriptionDaysRemaining = Math.ceil(
      (new Date(CURRENT_SUBSCRIPTION?.expires_date).getTime() - new Date().getTime()) /
        (1000 * 3600 * 24),
    );

    const ACTIVE_SUBSCRIPTION =
      !CURRENT_SUBSCRIPTION?.billing_issues_detected_at &&
      !CURRENT_SUBSCRIPTION?.unsubscribe_detected_at &&
      !SUBSCRIPTION_EXPIRED;

    if (TRIAL_ELIGIBLE) {
      return "14 days free";
    }

    if (ACTIVE_SUBSCRIPTION && !PROMOTIONAL_SUBSCRIPTION) {
      return `Your ${
        ON_TRIAL && !SUBSCRIPTION_CANCELED ? "free trial" : "subscription"
      } will expire in ${subscriptionDaysRemaining} days`;
    }

    if (BILLING_ISSUE) {
      return "Billing Notice";
    }

    return "";
  }, [
    CURRENT_SUBSCRIPTION?.expires_date,
    CURRENT_SUBSCRIPTION?.billing_issues_detected_at,
    CURRENT_SUBSCRIPTION?.unsubscribe_detected_at,
    SUBSCRIPTION_EXPIRED,
    TRIAL_ELIGIBLE,
    PROMOTIONAL_SUBSCRIPTION,
    BILLING_ISSUE,
    ON_TRIAL,
    SUBSCRIPTION_CANCELED,
  ]);

  const dispatch = useDispatch();
  const get_stripe_subscription_item = useCallback(
    subscription_item_id => dispatch(subsact.get_stripe_subscription_item(subscription_item_id)),
    [dispatch],
  );
  const get_stripe_subscription = useCallback(
    subscription_id => dispatch(subsact.get_stripe_subscription(subscription_id)),
    [dispatch],
  );
  const cancel_subscription = useCallback(
    (subscription_id, customer_id) =>
      dispatch(subsact.cancel_subscription(subscription_id, customer_id)),
    [dispatch],
  );
  const switch_plan = useCallback(
    ({ plan_type, stripe_subscription_id, stripe_subscription_item_id }) =>
      dispatch(
        subsact.switch_plan({
          plan_type,
          stripe_subscription_id,
          stripe_subscription_item_id,
        }),
      ),
    [dispatch],
  );
  const create_checkout_session = useCallback(
    ({ trial_period_days, plan_type, success_url, cancel_url, customer_id }) =>
      dispatch(
        subsact.create_checkout_session({
          trial_period_days,
          plan_type,
          success_url,
          cancel_url,
          customer_id,
        }),
      ),
    [dispatch],
  );
  const create_billing_checkout_session = useCallback(
    ({ success_url, cancel_url, customer_id }) =>
      dispatch(subsact.create_billing_checkout_session({ success_url, cancel_url, customer_id })),
    [dispatch],
  );
  const switchPlan = async () => {
    if (!currentSelectedPlan || !stripe_subscription?.id || !stripe_subscription_item?.id) {
      return;
    }

    setIsSwitchingPlan(true);

    switch_plan({
      plan_type: currentSelectedPlan.plan_type,
      stripe_subscription_id: stripe_subscription?.id,
      stripe_subscription_item_id: stripe_subscription_item?.id,
    });
  };
  const createCheckoutSession = useCallback(async () => {
    if (!currentSelectedPlan) {
      return;
    }

    create_checkout_session({
      trial_period_days: TRIAL_ELIGIBLE ? 14 : 0,
      plan_type: currentSelectedPlan.plan_type,
      success_url: `${window.location.origin}/perform`,
      cancel_url: `${window.location.origin}/settings/subscription`,
      customer_id: stripe_subscription?.customer,
    });
  }, [create_checkout_session, currentSelectedPlan, stripe_subscription?.customer, TRIAL_ELIGIBLE]);

  const createBillingCheckoutSession = useCallback(async () => {
    if (!currentSelectedPlan || !stripe_subscription?.customer) {
      return;
    }

    create_billing_checkout_session({
      success_url: `${window.location.origin}/perform`,
      cancel_url: `${window.location.origin}/settings/subscription`,
      customer_id: stripe_subscription?.customer,
    });
  }, [create_billing_checkout_session, currentSelectedPlan, stripe_subscription?.customer]);

  const onClickSwitch = useCallback(
    async price_id => {
      if (!price_id) {
        return;
      }

      if (
        !CURRENT_SUBSCRIPTION ||
        (CURRENT_SUBSCRIPTION && SUBSCRIPTION_CANCELED && SUBSCRIPTION_EXPIRED)
      ) {
        createCheckoutSession();
        return;
      }

      if (CURRENT_SUBSCRIPTION && !SUBSCRIPTION_EXPIRED) {
        setPlanTobeSwitch(price_id);
        setShowDialog(true);
      }
    },
    [createCheckoutSession, SUBSCRIPTION_CANCELED, SUBSCRIPTION_EXPIRED, CURRENT_SUBSCRIPTION],
  );

  const cancelSubscription = async () => {
    if (!stripe_subscription?.id) {
      return;
    }

    setShowCancelDialog(false);
    cancel_subscription(stripe_subscription?.id, stripe_subscription?.customer);
  };

  useEffect(() => {
    // LOAD SUBSCRIPTION ITEM IN REDUX IF NOT YET LOADED
    !stripe_subscription_item &&
      CURRENT_SUBSCRIPTION?.store_transaction_id &&
      get_stripe_subscription_item(CURRENT_SUBSCRIPTION?.store_transaction_id);
  }, [stripe_subscription_item]);

  useEffect(() => {
    // LOAD SUBSCRIPTION IN REDUX IF NOT YET LOADED
    stripe_subscription_item &&
      !stripe_subscription &&
      get_stripe_subscription(stripe_subscription_item?.subscription);
  }, [stripe_subscription, stripe_subscription_item]);

  useEffect(() => {
    setPlanTobeSwitch(stripe_subscription_item?.price?.id || PLANS[0].price_id);
  }, [stripe_subscription_item?.price?.id]);

  useEffect(() => {
    // when checkout session is created, redirect to its url
    if (checkout_session) {
      window.location.href = checkout_session;
    }
  }, [checkout_session]);

  useEffect(() => {
    // check if subscription is being cancel
    !cancelingSubscription &&
      setCancelingSubscription(is_loading?.includes("Cancelling subscription"));

    // if cancel subscription is successful, reload the page
    // returning the revenuecat api response right after the walker is called is not accurate, because RC will be updated just when the webhook in stripe is triggered
    if (cancelingSubscription && !is_loading?.includes("Cancelling subscription")) {
      window.location.reload();
    }
  }, [is_loading]);

  useEffect(() => {
    !switchingSubscription &&
      setSwitchingSubscription(is_loading?.includes("Switching subscription plan"));

    if (switchingSubscription && !is_loading?.includes("Switching subscription plan")) {
      window.location.reload();
    }
  }, [is_loading]);

  const renderContent = () => {
    if (BILLING_ISSUE) {
      return <BillingIssue plan={currentSelectedPlan} MOBILE_SUBSCRIPTION={MOBILE_SUBSCRIPTION} />;
    }

    if (PROMOTIONAL_SUBSCRIPTION) {
      return (
        <div className="mobile-subscription" data-testid="mobile-subscription">
          <strong>You're currently on a promotional subscription. </strong>
        </div>
      );
    }

    if (MOBILE_SUBSCRIPTION) {
      return <MobileSubscriptionDisplay />;
    }

    if (!CURRENT_SUBSCRIPTION || SUBSCRIPTION_TYPE === "stripe") {
      // if there is no current subscription or the active subscription is in web, display plan selection
      return (
        <PlanSelection
          plans={PLANS}
          planTobeSwitch={planTobeSwitch}
          setPlanTobeSwitch={setPlanTobeSwitch}
          isSwitchingPlan={isSwitchingPlan}
          onClickSwitch={onClickSwitch}
          currentSelectedPlan={currentSelectedPlan}
          buttonText={buttonText}
          stripeSubscriptionPriceId={stripeSubscriptionPriceId}
          createBillingCheckoutSession={createBillingCheckoutSession}
          showPremiumPrice={TRIAL_ELIGIBLE}
          showFreeTrialText={TRIAL_ELIGIBLE}
          showUpdateBillingButton={CURRENT_SUBSCRIPTION && !SUBSCRIPTION_EXPIRED}
        />
      );
    }
  };

  return (
    <>
      {SHOW_LOADER && <StripeLoader />}
      <div className="d-flex flex-column gap-2 subscription-container justify-content-center">
        <section className="d-flex justify-content-center w-100 gap-4 pricing-wrapper align-items-center">
          <Features
            headerText="Myca is forever free."
            subHeaderText="Try myca premium to access more advanced features."
          />
          <div className="plans-wrapper">
            <>
              <header data-testid="subscription-header">{headerMessage}</header>
              <main>{renderContent()}</main>
              {!CURRENT_SUBSCRIPTION || SUBSCRIPTION_TYPE === "stripe" ? (
                <SubscriptionFooter
                  currencySymbol={getCurrencySymbol(currentSelectedPlan?.currency || "usd")}
                  currentSelectedPlan={currentSelectedPlan}
                  setShowCancelDialog={setShowCancelDialog}
                  showFreeTrialText={TRIAL_ELIGIBLE || ON_TRIAL}
                  showCancelButton={
                    CURRENT_SUBSCRIPTION && !SUBSCRIPTION_CANCELED && !SUBSCRIPTION_EXPIRED
                  }
                />
              ) : (
                <></>
              )}
            </>
          </div>
        </section>
      </div>
      <SubscriptionModal
        show={showDialog}
        body={
          <p>
            {SUBSCRIPTION_CANCELED && !SUBSCRIPTION_EXPIRED
              ? "Are you sure you want to renew your plan?"
              : "Are you sure you want to switch plan?"}
          </p>
        }
        cancelFunc={() => setShowDialog(false)}
        confirmFunc={() => {
          setShowDialog(false);
          if (planTobeSwitch) {
            setIsSwitchingPlan(true);
            switchPlan(planTobeSwitch);
          }
        }}
      />
      <SubscriptionModal
        show={showCancelDialog}
        body={<p>Are you sure you want to cancel your existing plan?</p>}
        cancelFunc={() => setShowCancelDialog(false)}
        confirmFunc={cancelSubscription}
      />
    </>
  );
};

Subscription.propTypes = {};

export default Subscription;
