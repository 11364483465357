import { useCallback, memo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { faArrowRight } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";

import { session_actions as sact, future_actions as fact } from "@myca/shared-component";

import "./FrozenBar.scss";

const FrozenBar = ({
  enableLatestDayBtn = true,
  enableUnfreezeBtn = false,
  text = "The currently selected day is frozen",
}) => {
  const { days } = useSelector(state => state.workette);
  const { freeze_override } = useSelector(state => state.future);

  const dispatch = useDispatch();
  const change_date = useCallback(date => dispatch(sact.change_date(date)), [dispatch]);
  const set_future_freeze_override = useCallback(
    val => dispatch(fact.set_future_freeze_override(val)),
    [dispatch],
  );

  const handleClickLatestDay = useCallback(() => {
    let latestDay = Object.keys(days)[0];

    Object.keys(days).forEach(day => {
      if (new Date(day) > new Date(latestDay)) {
        latestDay = day;
      }
    });

    change_date(latestDay);
  }, [change_date, days]);

  const handleGoalFreezeOverride = useCallback(() => {
    set_future_freeze_override(!freeze_override);
  }, [freeze_override, set_future_freeze_override]);

  return (
    <div
      className={`frozen-bar ${!freeze_override ? "active" : "inactive"} ${
        enableLatestDayBtn ? "right-button-visible" : ""
      }`}
      data-testid="frozen-bar"
    >
      {enableUnfreezeBtn ? (
        <span
          className="unfreeze-button"
          data-testid="unfreeze-button"
          onClick={handleGoalFreezeOverride}
        >
          {freeze_override ? "Freeze" : "Unfreeze"}
        </span>
      ) : (
        <div></div>
      )}

      <span className="title">{text}</span>
      {enableLatestDayBtn && (
        <span className="latest-day-button" onClick={handleClickLatestDay}>
          Back to latest day
          <FontAwesomeIcon icon={faArrowRight} />
        </span>
      )}
    </div>
  );
};

FrozenBar.propTypes = {
  enableLatestDayBtn: PropTypes.bool,
  enableUnfreezeBtn: PropTypes.bool,
  text: PropTypes.string,
};

export default memo(FrozenBar);
