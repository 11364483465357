import { useEffect, useState, useCallback, memo, useRef, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  workette_actions as wact,
  generate_new_ritual_history,
  selectCurrentlySelectedLifeNode,
  logTour,
} from "@myca/shared-component";
import { Button, Container, Col } from "react-bootstrap";
import PropTypes from "prop-types";
import { DateTime } from "luxon";

import RitualDays from "../RitualDays";

import "./WktRitualForm.scss";

/**
 * Contains ritual body/footer for ritual configuration on provided w_id
 * @param {string} w_id workette for this ritual
 * @param {boolean} new_wkt if true, pretends there is no existing wkt and returns ritual obj
 */

const weekDays = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];

const WktRitualForm = ({
  w_id,
  new_wkt,
  new_settings,
  setShowCustomForm,
  isRitualChecked,
  setIsRitualChecked,
  setShowOverlay,
  hide,
}) => {
  const workette = useSelector(state => state.workette);
  const session = useSelector(state => state.session);
  const lifeNode = useSelector(selectCurrentlySelectedLifeNode);

  const dispatch = useDispatch();
  const set_workette = useCallback(
    (w_id, ctx) => dispatch(wact.set_workette(w_id, ctx)),
    [dispatch],
  );

  const ritualRef = useRef(null);

  // Date.getDay() returns 0 as sunday, but we print weeks as monday is first, so sunday should be 7
  const getDay = () => (new Date().getDay() === 0 ? 7 : new Date().getDay() - 1);

  const defaultRitual = {
    increment: "1",
    frequency: "WEEKS",
    selectedDays: Array(7)
      .fill(false)
      .map((v, i) => i === getDay()),
    startDate: DateTime.now().toFormat("yyyy-LL-dd"),
    end: null,
    occurrences: null,
  };

  const item = useMemo(
    () => !new_wkt && w_id && workette.items[w_id],
    [new_wkt, w_id, workette.items],
  );

  const [timeIncrement, setTimeIncrement] = useState(
    new_wkt
      ? defaultRitual.frequency
      : item?.context?.is_ritual?.frequency || defaultRitual.frequency,
  );
  const [frequency, setFrequency] = useState(
    new_wkt
      ? defaultRitual.increment
      : item?.context?.is_ritual?.interval || defaultRitual.increment,
  );
  const [selectedDays, setDays] = useState(
    new_wkt
      ? defaultRitual.selectedDays
      : item?.context?.is_ritual?.byDayOfMonth ||
          item?.context?.is_ritual?.byDayOfWeek ||
          defaultRitual.selectedDays,
  );
  const [startDate, setStartDate] = useState(
    new_wkt
      ? defaultRitual.startDate
      : item?.context?.is_ritual?.start || DateTime.now().toFormat("yyyy-LL-dd"),
  );
  const [ritualEndsType, setRitualEndsType] = useState("never");

  const [ritualEnds, setRitualEnds] = useState(
    new_wkt || !item?.context?.is_ritual?.end ? defaultRitual.end : item?.context?.is_ritual?.end,
  );
  const [ritualEndsOccurrences, setRitualEndsOccurrences] = useState(
    new_wkt || !item?.context?.is_ritual?.occurrences
      ? defaultRitual.occurrences
      : item?.context?.is_ritual?.occurrences,
  );

  const [isSaveDisabled, setIsSaveDisabled] = useState(false);

  const monthDays = [
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26,
    27, 28, 29, 30, 31,
  ];

  useEffect(() => {
    if (!item?.context?.is_ritual?.occurrences && item?.context?.is_ritual?.end) {
      setRitualEndsType("on");
    } else if (item?.context?.is_ritual?.occurrences > 0 && !item?.context?.is_ritual?.end) {
      setRitualEndsType("after");
    } else {
      setRitualEndsType("never");
    }
  }, [item?.context?.is_ritual?.end, item?.context?.is_ritual?.occurrences]);

  useEffect(() => {
    //if old format, convert
    if (new_wkt || (item?.context?.is_ritual && item?.context?.is_ritual.constructor === Array)) {
      setFrequency(new_settings?.interval || "1");
      setDays(
        new_wkt
          ? new_settings?.byDayOfWeek || defaultRitual.selectedDays
          : [...item?.context?.is_ritual],
      );
      setTimeIncrement(new_settings?.frequency || "WEEKS");
    } else {
      if (!timeIncrement) {
        setTimeIncrement("WEEKS");
      }
      if (!selectedDays) {
        setDays([1, 1, 1, 1, 1, 1, 1]);
      }
    }
  }, []);

  useEffect(() => {
    if (timeIncrement === "WEEKS") {
      setIsSaveDisabled(!selectedDays.includes(true));
    } else {
      setIsSaveDisabled(false);
    }
  }, [selectedDays, timeIncrement]);

  const convertRSchedule = ritual_flag => {
    const endDate = new Date();
    endDate.setDate(endDate.getDate() + 1000);
    let rule;

    if (timeIncrement === "DAYS") {
      rule = {
        frequency: timeIncrement.toUpperCase(),
        start: startDate,
        interval: parseInt(frequency),
      };
    } else if (timeIncrement === "WEEKS") {
      rule = {
        frequency: timeIncrement.toUpperCase(),
        start: startDate,
        byDayOfWeek: selectedDays,
        interval: parseInt(frequency),
      };
    } else if (timeIncrement === "MONTHS") {
      rule = {
        frequency: timeIncrement.toUpperCase(),
        start: startDate,
        byDayOfMonth: parseInt(selectedDays),
        interval: parseInt(frequency),
      };
    }

    if (ritualEndsType === "on") {
      rule = { ...rule, end: ritualEnds, occurrences: null };
    } else if (ritualEndsType === "after") {
      rule = { ...rule, end: null, occurrences: ritualEndsOccurrences };
    } else {
      rule = { ...rule, end: null, occurrences: null };
    }

    const schedule = { ...rule, ritual_flag: ritual_flag || isRitualChecked };
    return schedule;
  };

  const handleSave = () => {
    if (!isSaveDisabled) {
      let timeoutId = null;
      let wktRitual;
      if (timeIncrement === "none") {
        wktRitual = false;
      } else {
        wktRitual = convertRSchedule(new_settings?.ritual_flag || isRitualChecked);
      }

      if (new_wkt) {
        new_wkt(wktRitual);
        hide();
      } else if (wktRitual !== item?.context?.is_ritual) {
        const ritualHistory = generate_new_ritual_history({
          item,
          new_history: {
            day: session.cur_date,
            snoozed_till: item?.context?.snoozed_till,
            is_ritual: wktRitual,
          },
        });

        timeoutId = setTimeout(() => {
          // if the user set item as ritual for the first time, save it to tours
          !lifeNode?.context?.settings?.tours.includes("CreatedRitual") &&
            logTour(workette.life, lifeNode, set_workette, "CreatedRitual");

          set_workette(w_id, { is_ritual: wktRitual, ritual_history: ritualHistory });
        }, 1000);
      }

      document.body.click();

      return () => clearTimeout(timeoutId);
    }
  };

  const handleClear = useCallback(() => {
    setTimeIncrement("none");

    if (item) {
      const ritualHistory = generate_new_ritual_history({
        item,
        new_history: {
          day: session.cur_date,
          snoozed_till: item?.context?.snoozed_till,
          is_ritual: false,
        },
      });

      set_workette(w_id, { is_ritual: false, ritual_history: ritualHistory });
    }
  }, [item, session.cur_date, set_workette, w_id]);

  const handleStartDate = e => {
    setStartDate(e.currentTarget.value);
  };

  const handleDaysofMonthChange = e => {
    setDays(e.currentTarget.value);
  };

  const handleDaysofWeekChange = e => {
    let daysOfWeek = false;
    // If daysOfWeek isn't an array, generate an array
    if (selectedDays.constructor === Array) {
      daysOfWeek = [...selectedDays];
    } else {
      daysOfWeek = defaultRitual.selectedDays;
    }

    switch (e.currentTarget.name) {
      case "Mon":
        daysOfWeek[0] = !daysOfWeek[0];
        break;
      case "Tue":
        daysOfWeek[1] = !daysOfWeek[1];
        break;
      case "Wed":
        daysOfWeek[2] = !daysOfWeek[2];
        break;
      case "Thu":
        daysOfWeek[3] = !daysOfWeek[3];
        break;
      case "Fri":
        daysOfWeek[4] = !daysOfWeek[4];
        break;
      case "Sat":
        daysOfWeek[5] = !daysOfWeek[5];
        break;
      case "Sun":
        daysOfWeek[6] = !daysOfWeek[6];
        break;
      default:
        break;
    }

    setDays([...daysOfWeek]);
  };

  const handleIncrementChange = e => {
    setTimeIncrement(e.currentTarget.value);
    if (e.currentTarget.value === "WEEKS") {
      setDays(defaultRitual.selectedDays);
    } else if (e.currentTarget.value === "MONTHS") {
      setDays(
        !new_wkt && item?.context?.is_ritua?.byDayOfMonth
          ? item?.context?.is_ritual?.byDayOfMonth || 1
          : 1,
      );
    }
  };

  const handleFrequencyChange = e => {
    setFrequency(e.currentTarget.value);
  };

  const handleCancel = useCallback(() => {
    if (setShowCustomForm) {
      setShowCustomForm(false);
    } else {
      hide && hide();
    }
  }, [hide, setShowCustomForm]);

  const handleRitualFlagOnChange = useCallback(
    isChecked => {
      if (new_wkt) {
        new_wkt(convertRSchedule(isChecked));
      } else {
        setIsRitualChecked(isChecked);
      }
    },
    [convertRSchedule, new_wkt, setIsRitualChecked],
  );

  useEffect(() => {
    return () => {
      setShowCustomForm && setShowCustomForm(false);
    };
  }, [setShowCustomForm]);

  useEffect(() => {
    const handleClickOutside = e => {
      const ritualMenu = document.querySelector(".ritual-form-container");

      if (
        (ritualRef.current && !ritualRef.current.contains(e.target)) ||
        (Boolean(ritualMenu) && !Boolean(ritualMenu) && !Boolean(ritualMenu.contains(e.target)))
      ) {
        setShowOverlay && setShowOverlay(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ritualRef, setShowOverlay]);

  const handleOnChangeEndsType = type => {
    switch (type) {
      case "never":
        setRitualEnds(null);
        setRitualEndsOccurrences(null);
        break;
      case "on":
        setRitualEnds(
          ritualEnds?.on_date || DateTime.now().plus({ days: 1 }).toFormat("yyyy-LL-dd"),
        );
        setRitualEndsOccurrences(null);
        break;
      case "after":
        setRitualEnds(null);
        setRitualEndsOccurrences(Number(ritualEnds?.occurrences) || 1);
        break;
      default:
        break;
    }
    setRitualEndsType(type);
  };

  const handleOnChangeOnDate = date => {
    setRitualEndsType("on");
    setRitualEnds(date);
  };

  const handleOnChangeEndsOccurrences = occurrences => {
    setRitualEndsType("after");
    setRitualEndsOccurrences(Number(occurrences));
  };

  return (
    <span className="ritual-form-container" data-testid="ritual-container" ref={ritualRef}>
      <Container className="ritual-container">
        <div className="ritual-row">
          <div className="row-label">Repeat every</div>
          <Col className="flex">
            {timeIncrement !== "none" && (
              <input
                className="small-input"
                type="number"
                min="1"
                max="99"
                name="frequency"
                value={frequency}
                onChange={handleFrequencyChange}
                style={{ marginRight: "5px" }}
              />
            )}
            <select
              value={timeIncrement}
              onChange={handleIncrementChange}
              data-testid="repeat-every"
            >
              <option value="none">None</option>
              <option value="DAYS">Days</option>
              <option value="WEEKS">Weeks</option>
              <option value="MONTHS">Months</option>
            </select>
          </Col>
        </div>
        {timeIncrement === "WEEKS" && (
          <div className="ritual-row align-items-center mb-2">
            <Col className="min-width-auto">on</Col>
            {weekDays.map((v, i) => (
              <Col key={i}>
                <div className="ritual-weekdays">
                  <RitualDays
                    value={selectedDays[i]}
                    onChange={handleDaysofWeekChange}
                    name={v}
                    label={v}
                  />
                </div>
              </Col>
            ))}
          </div>
        )}
        {timeIncrement === "MONTHS" && (
          <div className="ritual-row">
            <div className="row-label">on the</div>
            <Col>
              <select
                value={parseInt(selectedDays)}
                onChange={handleDaysofMonthChange}
                data-testid="repeat-every"
                className="mr-2"
              >
                {monthDays.map(v => (
                  <option key={v} value={v}>
                    {v}
                  </option>
                ))}
              </select>
              of the month
            </Col>
          </div>
        )}
        <div className="ritual-row">
          <div className="row-label">Starting on:</div>
          <Col>
            <input
              value={startDate}
              onChange={handleStartDate}
              name="date"
              type="date"
              aria-label="starting-on"
            />
          </Col>
        </div>
        <div className="ritual-row ritual-end-row">
          <div className="row-label">Ends:</div>
          <div className="ritual-end-container">
            <div>
              <label htmlFor="ritual-option-never">
                <input
                  type="radio"
                  name="ritual-end-option"
                  id="ritual-option-never"
                  checked={ritualEndsType === "never"}
                  onChange={() => handleOnChangeEndsType("never")}
                />
                Never
              </label>
            </div>
            <div>
              <label htmlFor="ritual-option-on">
                <input
                  type="radio"
                  name="ritual-end-option"
                  id="ritual-option-on"
                  checked={ritualEndsType === "on"}
                  onChange={() => handleOnChangeEndsType("on")}
                />
                On
              </label>
              <input
                value={ritualEnds || DateTime.now().plus({ days: 1 }).toFormat("yyyy-LL-dd")}
                onChange={e => handleOnChangeOnDate(e.target.value)}
                min={DateTime.now().plus({ days: 1 }).toFormat("yyyy-LL-dd")}
                name="date"
                type="date"
                aria-label="ends-on datepicker"
              />
            </div>
            <div>
              <label htmlFor="ritual-option-after">
                <input
                  type="radio"
                  name="ritual-end-option"
                  id="ritual-option-after"
                  checked={ritualEndsType === "after"}
                  onChange={() => handleOnChangeEndsType("after")}
                />
                After
              </label>
              <label htmlFor="ritual-occurrences" className="ritual-occurrences-label">
                <input
                  type="number"
                  name="ritual-occurrences"
                  min={1}
                  max={999}
                  value={ritualEndsOccurrences || 1}
                  id="ritual-occurrences"
                  onChange={e => handleOnChangeEndsOccurrences(e.target.value)}
                />
                occurrences
              </label>
            </div>
          </div>
        </div>
        <div className="ritual-row">
          <div className="row-label">Ritual:</div>
          <Col className="d-flex">
            <input
              name="Recurring"
              type="checkbox"
              checked={new_settings?.ritual_flag || isRitualChecked}
              onChange={e => {
                handleRitualFlagOnChange(e.target.checked);
              }}
              data-testid="ritual-flag"
            />
          </Col>
        </div>
      </Container>
      <div className="ritual-footer">
        <div className="footer-button">
          <Button variant="light" onClick={handleClear}>
            Clear
          </Button>
        </div>
        <div className="footer-button">
          <Button variant="secondary" onClick={handleCancel}>
            Cancel
          </Button>
        </div>
        <div className="footer-button">
          <Button data-testid="save-btn" onClick={handleSave} disabled={isSaveDisabled}>
            Save
          </Button>
        </div>
      </div>
    </span>
  );
};

WktRitualForm.propTypes = {
  w_id: PropTypes.string,
  new_wkt: PropTypes.func,
  new_settings: PropTypes.object,
  setShowCustomForm: PropTypes.func,
  isRitualChecked: PropTypes.bool,
  setIsRitualChecked: PropTypes.func,
  setShowOverlay: PropTypes.func,
  hide: PropTypes.func,
};

export default memo(WktRitualForm);
