import { useCallback, memo, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import WktButton from "../WktButton";

import {
  workette_actions as wact,
  session_actions as sact,
  onboarding_actions as onb_act,
  check_frozen,
} from "@myca/shared-component";

const WktButtonRunning = ({ item, isFirstItem = false, controls }) => {
  const [extraClass, setExtraClass] = useState("");

  const workette = useSelector(state => state.workette);
  const session = useSelector(state => state.session);
  const life = useSelector(state => state.workette.items[state.workette.life["life"]]);

  const dispatch = useDispatch();
  const startInProgressOnboarding = useCallback(
    () => dispatch(onb_act.in_progress.start()),
    [dispatch],
  );
  const set_workette = useCallback(
    (w_id, ctx) => dispatch(wact.set_workette(w_id, ctx)),
    [dispatch],
  );
  const set_push_alert = useCallback(alert => dispatch(sact.set_push_alert(alert)), [dispatch]);

  const toggle_running = useCallback(async () => {
    if (check_frozen(session)) {
      set_push_alert({ name: "Running failed", msg: "You can't edit a frozen day" });
      return;
    }
    let val = workette.items[item.jid].context.status;

    if (val === "running") {
      val = "open";
      controls.start("open");
    } else {
      val = "running";
      controls.start("running");
    }

    setTimeout(() => {
      set_workette(item.jid, { status: val });
    }, 300);
    setExtraClass("");
    if (
      val === "running" &&
      life &&
      !life.context?.settings?.tours?.includes("InProgressOnboarding")
    ) {
      setExtraClass("inprogress-onboarding-item");
      startInProgressOnboarding();
    }
  }, [
    controls,
    item.jid,
    life,
    session,
    set_push_alert,
    set_workette,
    startInProgressOnboarding,
    workette.items,
  ]);

  return (
    <WktButton
      iconName="running"
      status={workette.items[item.jid].context.status === "running"}
      tooltip="Move to in progress"
      onClick={e => {
        toggle_running(item);
        e.stopPropagation();
      }}
      className={`check-back-btn ${extraClass}`}
    />
  );
};

WktButtonRunning.propTypes = {
  item: PropTypes.object.isRequired,
  controls: PropTypes.object.isRequired,
  isFirstItem: PropTypes.bool,
};

export default memo(WktButtonRunning);
