import { useEffect, useState, useCallback, useContext } from "react";
import { Button, Modal } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";

import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/pro-duotone-svg-icons";
import {
  workette_actions as wact,
  session_actions as sact,
  selectCurrentlySelectedLifeNode,
  onboarding_actions as onb_act,
} from "@myca/shared-component";

import { AppContext } from "src/AppContext";

import "./ProfileSettings.scss";

const ProfileSettings = ({ settings }) => {
  const { isNonSubscriber } = useContext(AppContext);

  const [pw1Visibility, setPw1Visibility] = useState(false);
  const [pw2Visibility, setPw2Visibility] = useState(false);

  const [name, setName] = useState();
  const [pw1, setPw1] = useState("");
  const [pw2, setPw2] = useState("");
  const [pw1Error, setPw1Error] = useState(null);
  const [pw2Error, setPw2Error] = useState(null);
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
  const [deleteInputValue, setDeleteInputValue] = useState("");

  const lifeNode = useSelector(selectCurrentlySelectedLifeNode);
  const { user } = useSelector(state => state.session);
  const onboarding = useSelector(state => state.onboarding);

  const dispatch = useDispatch();
  const set_workette = useCallback(
    (w_id, ctx) => dispatch(wact.set_workette(w_id, ctx)),
    [dispatch],
  );
  const update_user_info = useCallback(
    ({ name, password }) => dispatch(sact.update_user_info({ name, password })),
    [dispatch],
  );
  const delete_user = useCallback(date => dispatch(sact.delete_user()), [dispatch]);

  const restartOnboarding = useCallback(
    onboarding => onboarding && dispatch(onb_act[onboarding].restart()),
    [dispatch],
  );
  const set_push_alert = useCallback(alert => dispatch(sact.set_push_alert(alert)), [dispatch]);

  useEffect(() => {
    if (user) {
      setName(user.name);
      // setEmail(user.email);
    }
  }, [user]);

  const resetTutorials = useCallback(() => {
    if (lifeNode) {
      set_workette(lifeNode.jid, {
        settings: {
          ...settings,
          tours: lifeNode?.context?.settings?.tours?.filter(
            tour => tour === "FounderIntroOnboarding",
          ),
        },
      });

      Object.keys(onboarding).forEach(onboarding => {
        restartOnboarding(onboarding);
      });
    }
  }, [lifeNode, settings]);

  const handleChange = e => {
    switch (e.currentTarget.id) {
      case "name":
        return setName(e.currentTarget.value);
      case "pw1":
        return setPw1(e.currentTarget.value);
      case "pw2":
        return setPw2(e.currentTarget.value);
      default:
        return;
    }
  };

  const saveProfile = useCallback(
    e => {
      e.preventDefault();
      if (name.trim() === "") {
        return;
      }

      update_user_info({ name });
    },
    [name, update_user_info],
  );

  const savePassword = useCallback(
    e => {
      e.preventDefault();
      if (pw1.length < 8) {
        setPw1Error("Passwords must be more than 8 characters");
        return;
      } else {
        setPw1Error(null);
      }

      if (pw1.trim() !== pw2.trim()) {
        setPw2Error("Passwords must match");
        return;
      } else {
        setPw2Error(null);
      }

      update_user_info({ password: pw1 });
    },
    [pw1, pw2],
  );

  const handleDeleteAccount = async () => {
    if (!isNonSubscriber) {
      set_push_alert({
        name: "Delete account failed",
        msg: "Please unsubscribe first, before deleting your account",
      });
      return;
    }

    if (deleteInputValue === "DELETE") {
      delete_user();
      setIsOpenDeleteModal(false);
    }
  };

  return (
    <>
      <div className="d-flex flex-column gap-6">
        <form onSubmit={saveProfile}>
          <div className="d-flex flex-column gap-3">
            <h3>User Profile</h3>

            <div className="form-group">
              <label htmlFor="name">Full Name</label>
              <input
                className="form-input"
                id="name"
                value={name || ""}
                onChange={handleChange}
                autoComplete="off"
                required
                type="text"
              />
            </div>
            {/* <div className="profile-setting">
            <label htmlFor="email" className="label">
              Email
            </label>
            <input
              className="text-input"
              id="email"
              type="email"
              value={email || ""}
              onChange={handleChange}
              autoComplete="off"
              required
            />
          </div> */}
            <div>
              <Button type="submit" className="primary-button">
                Update Profile
              </Button>
            </div>
          </div>
        </form>
        <form onSubmit={savePassword}>
          <div className="d-flex flex-column gap-3">
            <h3>Change Password</h3>
            <div className="form-group">
              <label htmlFor="pw1">Enter New Password</label>
              <div className="form-input-group">
                <input
                  className="form-input"
                  id="pw1"
                  onChange={handleChange}
                  value={pw1 || ""}
                  type={pw1Visibility ? "text" : "password"}
                />{" "}
                <FontAwesomeIcon
                  icon={!pw1Visibility ? faEye : faEyeSlash}
                  onClick={() => setPw1Visibility(!pw1Visibility)}
                />
              </div>
              {pw1Error && <div className="pw-validation-error">{pw1Error}</div>}
            </div>
            <div className="form-group">
              <label htmlFor="pw2">Re-enter New Password</label>
              <div className="form-input-group">
                <input
                  className="form-input"
                  id="pw2"
                  onChange={handleChange}
                  value={pw2 || ""}
                  type={pw2Visibility ? "text" : "password"}
                />
                <FontAwesomeIcon
                  icon={!pw2Visibility ? faEye : faEyeSlash}
                  onClick={() => setPw2Visibility(!pw2Visibility)}
                />
              </div>
              {pw2Error && <div className="pw-validation-error">{pw2Error}</div>}
            </div>
            <div>
              <Button type="submit" className="primary-button">
                Change Password
              </Button>
            </div>
          </div>
        </form>
        <div>
          <Button type="button" onClick={resetTutorials} className="primary-button">
            Reset All Tutorials
          </Button>
        </div>
        <div>
          <Button
            type="button"
            onClick={() => setIsOpenDeleteModal(true)}
            className="primary-button delete-account-button"
            disabled={!isNonSubscriber}
            data-testid="delete-account-button"
          >
            Delete Account
          </Button>
        </div>
      </div>
      <Modal
        show={isOpenDeleteModal}
        onHide={() => setIsOpenDeleteModal(false)}
        className="delete-account-modal"
        data-testid="delete-account-modal"
      >
        <Modal.Header closeButton>Account Deletion</Modal.Header>
        <Modal.Body>
          <p>
            Please type in "<b>DELETE</b>" (case sensitive) to confirm account deletion.
          </p>
          <input
            type="text"
            value={deleteInputValue}
            className="form-input"
            aria-label="input for delete confirmation"
            data-testid="delete-account-input"
            onChange={e => setDeleteInputValue(e.target.value)}
          />
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="primary-button"
            disabled={!isNonSubscriber}
            data-testid="delete-account-submit-button"
            onClick={handleDeleteAccount}
          >
            Submit
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

ProfileSettings.propTypes = {
  settings: PropTypes.shape({
    name: PropTypes.string,
    email: PropTypes.string,
  }),
};

ProfileSettings.defaultProps = {
  settings: {
    name: "",
    email: "",
  },
};

export default ProfileSettings;
