/**
 * Returns the name of the given node.
 *
 * For day nodes, it returns `"Root (Day)"`.
 *
 * @param node
 */
export function getNodeName(node) {
  if (node.name === "day") {
    return "Plan (Day)";
  }

  const { name } = node.context;
  if (name == null || name.trim() === "") {
    return "Untitled";
  }

  return name;
}
