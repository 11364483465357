import { memo, useState, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { OverlayTrigger, Popover } from "react-bootstrap";

import WktButton from "../WktButton";
import { workette_actions as wact, selectCurrentlySelectedDayNode } from "@myca/shared-component";

import "./WktButtonFilter.scss";

const FILTERS = ["inactive_ritual", "snoozed", "completed", "canceled", "in_progress"];

const WktButtonFilter = ({ item, className = "" }) => {
  const [filters, setFilters] = useState(null);
  const [showOverlay, setShowOverlay] = useState(false);

  const dayNode = useSelector(selectCurrentlySelectedDayNode);

  const dispatch = useDispatch();
  const set_workette = useCallback(
    (w_id, ctx) => dispatch(wact.set_workette(w_id, ctx)),
    [dispatch],
  );

  const isButtonActive = filters && Object.values(filters).includes(true);

  const handleCheckAll = () => {
    setFilters({
      inactive_ritual: true,
      snoozed: true,
      completed: true,
      canceled: true,
      in_progress: true,
    });
  };

  const handleClearAll = () => {
    setFilters({
      inactive_ritual: false,
      snoozed: false,
      completed: false,
      canceled: false,
      in_progress: false,
    });
  };

  const handleSave = () => {
    if (dayNode) {
      set_workette(dayNode.jid, {
        item_filters: filters,
      });

      setShowOverlay(false);
    }
  };

  const handleToggle = show => setShowOverlay(show);

  const handleOnchange = (filter, state) => {
    setFilters(filters => {
      const newFilters = { ...filters, [filter]: state };
      return newFilters;
    });
  };

  useEffect(() => {
    const dayFilters = dayNode?.context?.item_filters;

    const itemFilter = Array.isArray(dayFilters)
      ? dayFilters.find(filter => filter.jid === dayNode.jid)?.filters
      : dayFilters;

    setFilters(itemFilter);
  }, []);

  return (
    <OverlayTrigger
      show={showOverlay}
      rootClose={true}
      trigger="click"
      placement="bottom-end"
      onToggle={handleToggle}
      overlay={
        <Popover id="filter-popover" bsPrefix="filter-popover popover">
          <div className="filter-container">
            <ul>
              {FILTERS.map(filter => {
                let text = "";

                switch (filter) {
                  case "inactive_ritual":
                    text = "Inactive rituals";
                    break;
                  case "in_progress":
                    text = "In Progress";
                    break;
                  default:
                    text = filter;
                    break;
                }

                return (
                  <li key={filter}>
                    <label>
                      <input
                        type="checkbox"
                        checked={Boolean(filters && filters[filter])}
                        onChange={e => handleOnchange(filter, e.target.checked)}
                      />
                      {text}
                    </label>
                  </li>
                );
              })}
            </ul>
            <div className="filter-footer">
              <label onClick={handleCheckAll}>All</label>
              <label onClick={handleClearAll}>None</label>
              <button type="button" className="btn primary-button" onClick={handleSave}>
                Save
              </button>
            </div>
          </div>
        </Popover>
      }
    >
      <WktButton
        iconName="filter"
        status={isButtonActive}
        tooltip="Filter"
        onClick={() => false}
        data-testid={`wkt-filter-btn ${item.jid}`}
        className={`wkt-filter-btn ${className} ${isButtonActive ? "active" : "inactive"}`}
        tooltipPlacement="bottom"
      />
    </OverlayTrigger>
  );
};

WktButtonFilter.propTypes = {
  item: PropTypes.object.isRequired,
  className: PropTypes.string,
};

export default memo(WktButtonFilter);
