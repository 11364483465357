import PropTypes from "prop-types";

const FeatureItem = ({ title = "", features = [] }) => {
  return (
    <div className="feature">
      <h2>{title}</h2>
      <ul>
        {features?.map(feature => {
          return (
            <li key={feature}>
              <input type="checkbox" aria-label="feature check" checked readOnly />
              {feature}
            </li>
          );
        })}
      </ul>
    </div>
  );
};

FeatureItem.propTypes = {
  title: PropTypes.string,
  features: PropTypes.array.isRequired,
};

export default FeatureItem;
