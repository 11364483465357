import { memo, useCallback } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { addSquare } from "../../utils/utils";
import {
  workette_actions as wact,
  session_actions as sact,
  print_wkt_name,
  nearestColor,
} from "@myca/shared-component";

import "./WktRecommendation.scss";

const WktRecommendation = ({ w_id, remove }) => {
  const workette = useSelector(state => state.workette);

  const dispatch = useDispatch();
  const set_workette = useCallback(
    (w_id, ctx) => dispatch(wact.set_workette(w_id, ctx)),
    [dispatch],
  );
  const set_selected_wkt = useCallback(w_id => dispatch(sact.set_selected_wkt(w_id)), [dispatch]);

  const acceptFocus = useCallback(() => {
    set_workette(w_id, { is_MIT: true });
    remove(w_id);
  }, [remove, set_workette, w_id]);

  if (!workette.items[w_id]) {
    return <></>;
  }

  return (
    <div
      className="recommendation-wkt"
      onClick={e => {
        e.stopPropagation();
        set_selected_wkt(w_id);
      }}
    >
      <div
        className="button accept"
        onClick={e => {
          e.stopPropagation();
          acceptFocus(e);
        }}
      >
        Accept
      </div>
      <div
        className="button reject"
        onClick={e => {
          e.stopPropagation();
          remove(w_id);
        }}
      >
        Reject
      </div>
      <div className="name">{print_wkt_name(workette.items[w_id])}</div>
      <div className="parent-info">
        {workette.items[workette.items[w_id].parent].context.wtype === "workset" && (
          <>
            {addSquare(
              nearestColor({
                w_id: w_id,
                items: workette.items,
                defaultColor: "#333333",
                skipSelf: true,
              }),
            )}
            {print_wkt_name(workette.items[workette.items[w_id].parent])}
          </>
        )}
      </div>
    </div>
  );
};

WktRecommendation.propTypes = {
  w_id: PropTypes.string.isRequired,
  remove: PropTypes.func.isRequired,
};

export default memo(WktRecommendation);
