import { lazy, Suspense, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { motion } from "framer-motion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowFromRight,
  faArrowToRight,
  faCalendar,
  faTrophy,
} from "@fortawesome/pro-solid-svg-icons";

import { session_actions as sact } from "@myca/shared-component";

import "./PerformRightContainer.scss";

const VARIANTS = {
  out: {
    x: "100%",
    width: "0px",
  },
  in: {
    x: "0%",
    width: "350px",
  },
};

const TABS = [
  {
    key: "trophy",
    icon: faTrophy,
  },
  {
    key: "calendar",
    icon: faCalendar,
  },
];

const TrophyView = lazy(() => import("../TrophyView"));
const MycaCalendar = lazy(() => import("src/components/MycaCalendar"));

const PerformRightContainer = ({ onAnimationComplete }) => {
  const { toggle_trophy, trophy_wall_tabkey = "trophy" } = useSelector(state => state.session);

  const dispatch = useDispatch();
  const toggle_trophy_sidebar = useCallback(
    e => dispatch(sact.toggle_trophy_sidebar(e)),
    [dispatch],
  );
  const set_trophy_wall_tabkey = useCallback(
    key => dispatch(sact.set_trophy_wall_tabkey(key)),
    [dispatch],
  );

  const renderComponent = () => {
    switch (trophy_wall_tabkey) {
      case "trophy":
        return <TrophyView />;
      case "calendar":
        return <MycaCalendar />;

      default:
        return <></>;
    }
  };

  return (
    <motion.div
      key="perform-right-column-container"
      className="p-0 perform-right-column-container animate-div"
      animate={toggle_trophy ? "in" : "out"}
      transition={{ type: "Tween" }}
      variants={VARIANTS}
      onAnimationComplete={onAnimationComplete}
    >
      <aside className="tabs-container">
        <div className="toggle-button">
          <div className="tab" onClick={() => toggle_trophy_sidebar(!toggle_trophy)}>
            <FontAwesomeIcon
              style={{ cursor: "pointer" }}
              icon={toggle_trophy ? faArrowToRight : faArrowFromRight}
            />
          </div>
        </div>
        <div className="tabs">
          {TABS?.map(tab => (
            <div
              key={tab.key}
              className={`tab${tab.key === trophy_wall_tabkey ? " active" : ""}`}
              onClick={() => set_trophy_wall_tabkey(tab.key)}
            >
              <FontAwesomeIcon icon={tab.icon} />
            </div>
          ))}
        </div>
      </aside>
      <div className="right-side-bar" data-hidden={!toggle_trophy}>
        <Suspense fallback="">{renderComponent()}</Suspense>
      </div>
    </motion.div>
  );
};

PerformRightContainer.propTypes = {
  onAnimationComplete: PropTypes.func.isRequired,
};

export default PerformRightContainer;
