import { memo, useCallback } from "react";
import PropTypes from "prop-types";
import { faCalendarPlus } from "@fortawesome/pro-light-svg-icons";

import { useDispatch } from "react-redux";

import { session_actions as sact } from "@myca/shared-component";

import WktButton from "../WktButton";
import useFetchCalendar from "src/components/MycaCalendar/hooks/useFetchCalendar";

const WktButtonAddToGoogleCalendar = ({ jid }) => {
  const { calendar_accounts } = useFetchCalendar();

  const dispatch = useDispatch();
  const set_selected_wkt_calendar = useCallback(
    w_id => dispatch(sact.set_selected_wkt_calendar(w_id)),
    [dispatch],
  );

  if (calendar_accounts?.length === 0) {
    return <></>;
  }

  return (
    <WktButton
      data-testid={"wkt-add-to-calendar " + jid}
      className="wkt-add-to-calendar"
      icon={faCalendarPlus}
      onClick={() => set_selected_wkt_calendar(jid)}
      tooltip="Add to google calendar"
    />
  );
};

WktButtonAddToGoogleCalendar.propTypes = {
  jid: PropTypes.string.isRequired,
};

export default memo(WktButtonAddToGoogleCalendar);
