import { Row } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";

import "./WktItemSingle/WktItemSingle.scss";

const WktItemSingleSkeleton = () => {
  return (
    <Row className="single-workette-item">
      <Skeleton circle={true} height={24} width={24} />
      <Skeleton height={24} />
      <div>
        <Skeleton circle={true} height={24} width={24} />
        <span className="pr-1"></span>
        <Skeleton circle={true} height={24} width={24} />
      </div>
    </Row>
  );
};

export default WktItemSingleSkeleton;
