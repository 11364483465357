import { memo, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import Tooltip from "react-simple-tooltip";

import { session_actions as sact } from "@myca/shared-component";

const Parent = ({ parentJid, square, parentName }) => {
  const { drill_stack } = useSelector(state => state.session);

  const dispatch = useDispatch();
  const update_drill_stack = useCallback(
    newStacks => dispatch(sact.update_drill_stack(newStacks)),
    [dispatch],
  );

  const handleOnClick = useCallback(() => {
    update_drill_stack([...drill_stack, { jid: parentJid, type: "drill" }]);
  }, [drill_stack, parentJid, update_drill_stack]);

  return (
    <Tooltip
      padding={10}
      fontSize="11px"
      arrow={4}
      content={<span style={{ whiteSpace: "nowrap" }}>Drill Into Parent</span>}
      background="#000000C0"
      radius={7}
      className="parent-info-wrapper"
    >
      <span
        className="parent-info"
        onClick={e => {
          e.stopPropagation();
          handleOnClick();
        }}
      >
        {square}
        <span className="title">{parentName}</span>
      </span>
    </Tooltip>
  );
};

Parent.propTypes = {
  parentJid: PropTypes.string.isRequired,
  square: PropTypes.element.isRequired,
  parentName: PropTypes.string.isRequired,
};

export default memo(Parent);
