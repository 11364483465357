import { useMemo, useState, useCallback } from "react";
import PropTypes from "prop-types";
import { shallowEqual, useSelector } from "react-redux";
import {
  selectCurrentlySelectedDayNode,
  selectDeepChildItemsOfNodeAsTree,
  selectNodeParents,
} from "@myca/shared-component";
import { Modal } from "react-bootstrap";
import "./ParentSelectionModal.scss";
import Tree, { mutateTree } from "@atlaskit/tree";
import { ParentSelectionModalTreeItem } from "./ParentSelectionModalTreeItem";
import { SearchInput } from "../SearchInput/SearchInput";
import { ResultItem } from "../ResultItem/ResultItem";
import { isItem } from "../../../utils/isItem";

/**
 * Shows a modal that allows the user to select a parent node.
 */
const ParentSelectionModal = ({
  selectedParentId,
  onNodeSelect,
  nodeIdToSkip,
  show,
  onHide,
  override_root_node = "",
}) => {
  const dayNode = useSelector(selectCurrentlySelectedDayNode);
  const initiallyExpandedNodeIds = useSelector(state => {
    if (selectedParentId == null) {
      return [dayNode.jid];
    }

    return selectNodeParents(state, selectedParentId).map(item => item.jid);
  }, shallowEqual);

  const dayTree = useSelector(state => {
    const root = override_root_node || dayNode?.jid;

    const rootNode = state.workette.items[root];

    if (!rootNode) {
      return {};
    }

    const tree = selectDeepChildItemsOfNodeAsTree(state, root, false, {
      notCompleted: state.workette.items[selectedParentId]?.context?.wtype !== "goal", // include completed if parent is wtype === goal
      notCanceled: true,
    });

    if (nodeIdToSkip !== null && nodeIdToSkip in tree.items) {
      const parentId = tree.items[nodeIdToSkip]?.data?.parent;
      if (parentId) {
        delete tree.items[nodeIdToSkip];
        tree.items[parentId].children = tree.items[parentId].children.filter(
          id => id !== nodeIdToSkip,
        );
        tree.items[parentId].hasChildren = tree.items[parentId].children.length > 0;
      }
    }

    initiallyExpandedNodeIds.forEach(id => {
      if (tree.items[id]) {
        tree.items[id].isExpanded = true;
      }
    });
    if (tree.items[root]) {
      tree.items[root].isExpanded = true;
    }

    return tree;
  }, shallowEqual);

  const [tree, setTree] = useState(dayTree);
  const [searchResults, setSearchResults] = useState();

  const searchItems = useMemo(
    () =>
      dayTree.items
        ? Object.values(dayTree.items)
            .map(treeItem => treeItem.data)
            .filter(item => isItem(item))
        : [],
    [dayTree],
  );

  const handleExpand = useCallback(
    item => {
      const updatedTree = mutateTree(tree, item.id, { isExpanded: true });
      setTree(updatedTree);
    },
    [tree],
  );

  const handleCollapse = useCallback(
    item => {
      const updatedTree = mutateTree(tree, item.id, { isExpanded: false });
      setTree(updatedTree);
    },
    [tree],
  );

  function handleSearchQueryClear() {
    setSearchResults(undefined);
  }

  const handleSearchResultChange = results => {
    setSearchResults(results);
  };

  const handleModalHide = () => {
    setSearchResults(undefined);
    onHide();
  };

  const renderTreeItem = props => {
    return (
      <ParentSelectionModalTreeItem
        {...props}
        onClick={onNodeSelect}
        selectedParentId={selectedParentId}
      />
    );
  };

  const renderSearchResults = () => {
    if (searchResults == null) {
      return null;
    }

    if (searchResults.length === 0) {
      return <div className="h5 text-muted text-center mt-5">There are no results.</div>;
    }

    return searchResults.map(result => (
      <ResultItem
        item={result.item}
        onClick={onNodeSelect}
        key={result.item.jid}
        selectedParentId={selectedParentId}
        showBreadcrumbs
      />
    ));
  };

  return (
    <Modal
      show={show}
      onHide={handleModalHide}
      onExit={handleModalHide}
      size="lg"
      centered
      scrollable
      data-testid="parent-selection-modal"
    >
      <Modal.Header closeButton>
        <div className="d-flex align-items-center justify-content-between flex-fill">
          Select new group
          <SearchInput
            items={searchItems}
            onQueryClear={handleSearchQueryClear}
            onResultsChange={handleSearchResultChange}
          />
        </div>
      </Modal.Header>
      <Modal.Body className="parent-selection-modal__body p-0">
        {!searchResults ? (
          <Tree
            tree={tree}
            renderItem={renderTreeItem}
            onExpand={handleExpand}
            onCollapse={handleCollapse}
          />
        ) : (
          renderSearchResults()
        )}
      </Modal.Body>
    </Modal>
  );
};

ParentSelectionModal.defaultProps = {
  onNodeSelect: () => null,
  onHide: () => null,
};

ParentSelectionModal.propTypes = {
  /**
   * When set to `true`, the modal is shown.
   */
  show: PropTypes.bool,
  /**
   * The ID of the parent node that should initially be shown as "selected."
   */
  selectedParentId: PropTypes.string,
  /**
   * The ID of a node not to render. This is useful when this modal is shown as a "move item" dialog
   * and we don't want to render the node itself as it can't be its own parent.
   */
  nodeIdToSkip: PropTypes.string,
  /**
   * Called when the user selects a node as the new parent node.
   */
  onNodeSelect: PropTypes.func,
  /**
   * Called when the user requests to hide the modal.
   */
  onHide: PropTypes.func,

  override_root_node: PropTypes.string,
};

export { ParentSelectionModal };
