import { memo, useCallback } from "react";
import PropTypes from "prop-types";

import { useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLink, faStickyNote, faEyeSlash } from "@fortawesome/pro-solid-svg-icons";
import { workette_filters as w_filter } from "@myca/shared-component";

import WktButtons from "../../../wktbuttons";

import "./LeftButtons.scss";

function LeftButtons({
  item,
  expanded,
  setShowAddForm,
  setExpanded,
  toggleExpand,
  controls,
  leftWktActions = [],
}) {
  const cur_date = useSelector(state => state.session.cur_date);

  const generateLeftButtons = useCallback(() => {
    let buttons = [...leftWktActions];

    // Removes complete if item is a workset, link, or note
    if (["workset", "link", "note"].includes(item.context.wtype)) {
      buttons = buttons.filter(b => b !== "complete");
    }

    // Adds expand toggle if item has children or is workset
    if (
      item.children?.length > 0 ||
      item.context.wtype === "workset" ||
      item.context.wtype === "goal"
    ) {
      buttons = buttons.concat("expandToggle");
    }

    return buttons;
  }, [item, leftWktActions]);

  const printIcon = useCallback(() => {
    switch (item.context.wtype) {
      case "link":
        return <FontAwesomeIcon className="mx-1 text-black" icon={faLink} />;
      case "note":
        return <FontAwesomeIcon className="mx-1 text-black" icon={faStickyNote} />;
      default:
        if (!w_filter.scheduled_now(item, cur_date)) {
          return <FontAwesomeIcon className="mx-1 text-black" icon={faEyeSlash} />;
        } else {
          return (
            <WktButtons
              item={item}
              buttons={generateLeftButtons()}
              controls={controls}
              setExpanded={setExpanded}
              expanded={expanded}
              setShowAddForm={setShowAddForm}
              toggleExpand={toggleExpand}
            />
          );
        }
    }
  }, [
    controls,
    cur_date,
    expanded,
    generateLeftButtons,
    item,
    setExpanded,
    setShowAddForm,
    toggleExpand,
  ]);

  return <>{printIcon()}</>;
}

LeftButtons.propTypes = {
  item: PropTypes.object.isRequired,
  expanded: PropTypes.bool,
  setShowAddForm: PropTypes.func,
  setExpanded: PropTypes.func,
  toggleExpand: PropTypes.func,
  controls: PropTypes.object,
  leftWktActions: PropTypes.array,
};

export default memo(LeftButtons);
