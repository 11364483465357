import { memo, lazy, Suspense, useEffect, useCallback, useContext, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Col, Container, Dropdown, Row, ButtonGroup, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlusSquare,
  faExpandAlt,
  faCompressAlt,
  faMinusSquare,
} from "@fortawesome/pro-solid-svg-icons";
import PropTypes from "prop-types";
import Tree from "@atlaskit/tree";
import { AnimatePresence } from "framer-motion";

import WktButton from "../../workette/wktbuttons/WktButton";
import WktButtonFilter from "../../workette/wktbuttons/WktButtonFilter";
import WktAddForm from "../../workette/wktforms/WktAddForm";
import WktItemSingleSkeleton from "../../workette/WktItemSingleSkeleton";
import { SearchModal } from "../../perform/SearchModal/SearchModal";
import { useFullDayViewHook } from "../hooks";

import {
  selectCurrentlySelectedLifeNode,
  onboarding_actions as onb_act,
  check_frozen,
  date_now,
} from "@myca/shared-component";

import { AppContext } from "src/AppContext";

import DrilledItems from "./DrilledItems";
import WktButtonDrill from "src/components/workette/wktbuttons/WktButtonDrill";

import plan_empty from "../assets/plan_empty.png";
import "./FullDayView.scss";

const AddItemOnboarding = lazy(() => import("src/components/Onboarding/AddItemOnboarding"));

const FullDayView = ({ root, label }) => {
  const [worketteType, setWorketteType] = useState("workette");
  const {
    treeData,
    showAddForm,
    showSearchModal,
    isTreeExpanded,
    session,
    workette,
    toggleExpand,
    handleDrag,
    handleSearchClick,
    handleSearchModalHide,
    renderTreeItem,
    toggleTree,
    setShowAddForm,
    itemForOnboarding,
  } = useFullDayViewHook(root, false);

  const { showOnboardingVideos } = useContext(AppContext);
  const { days } = useSelector(state => state.workette);
  const { drill_stack, cur_date, video_playing } = useSelector(state => state.session);
  const { is_loading } = useSelector(state => state.api);
  const currentStack = session.drill_stack?.length > 0 && [...session.drill_stack].reverse()[0];

  const drillPastItems = useSelector(({ workette }) => {
    const days_drilled = workette.days_drilled || [];

    const currentDrillItem = [...days_drilled].find(
      drill => drill.drilled_item === currentStack.jid,
    );

    return currentDrillItem;
  });

  const lifeNode = useSelector(selectCurrentlySelectedLifeNode);
  const { drill, add_item } = useSelector(state => state.onboarding);

  const dispatch = useDispatch();
  const drillOnboardingStart = useCallback(() => dispatch(onb_act.drill.start()), [dispatch]);
  const updateStepAddItemOnboarding = useCallback(
    step => dispatch(onb_act.add_item.next_or_prev(step)),
    [dispatch],
  );

  useEffect(() => {
    const itemForOnboardingParent = treeData.items[itemForOnboarding]?.data?.parent;

    if (
      itemForOnboarding &&
      !drill?.running &&
      lifeNode &&
      lifeNode.context?.settings?.tours.includes("PerformTabOnboarding") &&
      !lifeNode.context?.settings?.tours.includes("DrillOnboarding") &&
      (treeData.items[itemForOnboardingParent]?.isExpanded ||
        itemForOnboardingParent === treeData.rootId)
    ) {
      drillOnboardingStart();
    }
  }, [drill?.running, drillOnboardingStart, itemForOnboarding, lifeNode, treeData]);

  return (
    <>
      <Col data-testid="full-day-container">
        <Container className="full-day-container">
          <div className="full-day-row">
            <Col sm="auto">
              <span>{label}</span>
              {root === days[cur_date] && currentStack.jid !== days[cur_date] && (
                <WktButtonDrill jid={root} tooltipPlacement="right" />
              )}
            </Col>

            <Col sm={8} md className="d-flex justify-content-end align-items-center">
              {/* {drill_stack?.length > 0 && (
              <div className="mr-2">
                <SortDropdown
                  isSortDesc={isSortDesc}
                  setIsSortDesc={setIsSortDesc}
                  sortBy={sortBy}
                  setSortBy={setSortBy}
                />
              </div>
            )} */}
              <div className="mr-2">
                <WktButton
                  data-testid="global-search-btn"
                  iconName="search"
                  iconType="fas"
                  tooltip="Search"
                  onClick={handleSearchClick}
                  tooltipPlacement="bottom"
                />
              </div>
              <div className="mr-2">
                <WktButton
                  data-testid="toggle-btn"
                  className={isTreeExpanded ? "expanded" : "collapsed"}
                  icon={isTreeExpanded ? faCompressAlt : faExpandAlt}
                  tooltip={isTreeExpanded ? "Collapse All" : "Expand All"}
                  onClick={toggleTree}
                  tooltipPlacement="bottom"
                />
              </div>
              <div className="mr-2">
                {workette.items[days[cur_date]] && (
                  <WktButtonFilter
                    item={workette.items[days[cur_date]]}
                    className={"root-filter-btn"}
                  />
                )}
              </div>
              {root && !check_frozen(session) && (
                <Dropdown
                  as={ButtonGroup}
                  className="plan-add-button"
                  onSelect={type => {
                    setWorketteType(type);
                    setShowAddForm(true);
                  }}
                >
                  <Button
                    className="add-button"
                    data-testid="plan-add-button"
                    onClick={() => {
                      setWorketteType("workette");
                      setShowAddForm(showAddForm => !showAddForm);
                      if (add_item?.running && add_item?.current_step === 0 && !showAddForm) {
                        setTimeout(() => {
                          updateStepAddItemOnboarding(1);
                        }, 300);
                      }
                    }}
                  >
                    <FontAwesomeIcon icon={showAddForm ? faMinusSquare : faPlusSquare} /> Add
                  </Button>
                  <Dropdown.Toggle split />
                  <Dropdown.Menu>
                    <Dropdown.Item eventKey="workette">Add Task</Dropdown.Item>
                    <Dropdown.Item eventKey="workset">Add Group</Dropdown.Item>
                    <Dropdown.Item eventKey="link">Add Link</Dropdown.Item>
                    <Dropdown.Item eventKey="note">Add Note</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              )}
            </Col>
          </div>
          {root && !check_frozen(session) && (
            <WktAddForm
              w_id={root}
              parent_override={!!root}
              setShowAddForm={setShowAddForm}
              extra_class="root-add-form"
              isOpen={showAddForm}
              context_override={{
                wtype: worketteType,
              }}
            />
          )}

          {is_loading?.includes(`Loading Recent Day ${date_now()}`) ||
          is_loading?.includes("Getting Life") ||
          is_loading?.includes("Loading Jac") ? (
            <div data-testid="full-day-skeleton" className="full-day-skeleton">
              {Array(8)
                .fill(0)
                .map((n, i) => (
                  <WktItemSingleSkeleton key={i.toString()} item={{ context: {} }} />
                ))}
            </div>
          ) : workette.items[workette.days[session.cur_date]]?.children?.length < 1 ? (
            <div className="no-item-container">
              <img src={plan_empty} alt="Empty plan" data-testid="no-data" loading="lazy" />
              <div
                className="plan-add-button"
                onClick={() => setShowAddForm(showAddForm => !showAddForm)}
              >
                <FontAwesomeIcon icon={faPlusSquare} /> Add an Item
              </div>
            </div>
          ) : (
            workette.days[session.cur_date] && (
              <>
                <Row className="row-column">
                  <Col data-testid="tree-data">
                    <AnimatePresence>
                      <Tree
                        tree={treeData}
                        renderItem={renderTreeItem}
                        onExpand={toggleExpand}
                        onCollapse={toggleExpand}
                        onDragEnd={handleDrag}
                        offsetPerLevel={15}
                        isDragEnabled={true}
                        isNestingEnabled
                      />
                    </AnimatePresence>
                  </Col>
                  {drill_stack?.length > 0 &&
                    drillPastItems?.days?.length > 0 &&
                    drillPastItems.days.map(day => (
                      <DrilledItems key={day.past_id} day={day} root={day.past_id} />
                    ))}
                </Row>
                <SearchModal show={showSearchModal} onHide={handleSearchModalHide} />
              </>
            )
          )}
        </Container>
      </Col>
      {lifeNode && add_item?.running && video_playing === "" && !showOnboardingVideos && (
        <Suspense fallback="">
          <AddItemOnboarding setShowAddForm={setShowAddForm} />
        </Suspense>
      )}
    </>
  );
};

FullDayView.propTypes = {
  root: PropTypes.string,
  label: PropTypes.string.isRequired,
};

export default memo(FullDayView);
