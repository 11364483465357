import { useCallback, memo, useContext } from "react";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";

import { workette_actions as wact } from "@myca/shared-component";
import { AppContext } from "src/AppContext";

import aiLogo from "src/assets/goal_ai_recommendation.svg";
import "./WktButtonAssociateRecommendation.scss";

const WktButtonAssociateRecommendation = ({ jid, onClick, text = "" }) => {
  const { isNonSubscriber } = useContext(AppContext);
  const { currentWeekSelected } = useSelector(state => state.future?.current_selected_date);
  const item = useSelector(state => jid && state.workette.items[jid]);

  const dispatch = useDispatch();
  const get_goal_association_recommendations = useCallback(
    goal_nd => {
      dispatch(wact.get_goal_association_recommendations(goal_nd));
    },
    [dispatch],
  );

  const get_goal_association_recommendations_by_week = useCallback(
    week_nd => {
      dispatch(wact.get_goal_association_recommendations_by_week(week_nd));
    },
    [dispatch],
  );

  const recommendationOnClick = () => {
    if (isNonSubscriber) {
      return;
    }

    if (currentWeekSelected) {
      if (item?.name === "week") {
        get_goal_association_recommendations_by_week(jid);
      } else if (item?.context?.wtype === "goal") {
        get_goal_association_recommendations(jid);
      }
      onClick && onClick(jid);
    }
  };

  return (
    <button
      type="button"
      className="associate-recommendation-button"
      onClick={recommendationOnClick}
      data-testid={`associate-recommendation-button ${jid}`}
    >
      <img src={aiLogo} alt="" />
      {text && <span>{text}</span>}
    </button>
  );
};

WktButtonAssociateRecommendation.propTypes = {
  jid: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  text: PropTypes.string,
};

export default memo(WktButtonAssociateRecommendation);
