import React from "react";
import PropTypes from "prop-types";

/**
 * Given note match objects, renders the first three note matches.
 */
const SearchModalNoteMatch = ({ noteMatches, itemId }) => {
  noteMatches.sort((match1, match2) => match2.match.length - match1.match.length);

  const renderMatch = (noteMatch, index) => {
    const { matchLeading, match, matchTrailing } = noteMatch;

    return (
      <p className="small my-1" key={`note-match-${index}-${itemId}`}>
        <span>{matchLeading}</span>
        <mark className="p-0 align-self-baseline">{match}</mark>
        <span>{matchTrailing}</span>
      </p>
    );
  };

  return noteMatches.slice(0, 3).map(renderMatch);
};

SearchModalNoteMatch.propTypes = {
  /**
   * A note matches array from the search results of {@link SearchInput}.
   */
  noteMatches: PropTypes.arrayOf(PropTypes.object),
};

export { SearchModalNoteMatch };
