import { useEffect, useState, useCallback, memo, useMemo } from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Col, Row } from "react-bootstrap";
import {
  faAnalytics,
  faChevronDown,
  faChevronUp,
  faMinusSquare,
  faPlusSquare,
} from "@fortawesome/pro-solid-svg-icons";
import { useHistory } from "react-router-dom";

import WktItemSingle from "../../workette/WktItemSingle";
import WktItemSingleSkeleton from "../../workette/WktItemSingleSkeleton";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import WktListProgressBar from "../WktListProgressBar";
import WktAddForm from "../../workette/wktforms/WktAddForm";
import WktButton from "../../workette/wktbuttons/WktButton";
import WktButtons from "../../workette/wktbuttons";

import {
  workette_actions as wact,
  workette_filters as w_filter,
  binItems,
  selectCurrentlySelectedLifeNode,
  move_arr_item,
  reflect_actions as refl,
  onboarding_actions as onb_act,
  check_frozen,
} from "@myca/shared-component";

import "./FilteredDayView.scss";
/**
 * Generates filtered list of workettes with customized paramters
 * @param {string} label title of the list to display
 * @param {FontAwesomeIcon} labelIconProps FontAwesome Object to display
 * @param {string} caption extra string to show below name
 * @param {bool} showSummaryBarTotal toggle for % completion bars
 * @param {list} leftWktActions buttons that show up on the left
 * @param {list} rightWktActions buttons that show up on the right
 * @param {list} items list of workettes to display
 * @param {string} emptyMsg displays this if items is empty
 * @param {list} addOption If exists, Add Items with passed in context
 * @param {int} softCap Max number of workettes to display before expanding
 * @param {bool} withoutText state whether the focus button should have "Focus" label
 * @param {func} setIsOpenRecommendation toggle recommendation view visibility
 */
// leftWktActions, rightWktActions, items

const FilteredDayView = ({
  label,
  labelIconProps,
  caption,
  skeletons,
  showSummaryBarTotal = false,
  styleOverride,
  showParentTag,
  setShowDrill,
  leftWktActions,
  rightWktActions,
  items = [],
  doneImg,
  emptyImg,
  addOption = [],
  softCap,
  hideHidden = true,
  testid,
  tabKey,
  dropdownButtons = true,
  showHoverActions,
  withoutText,
  setIsOpenRecommendation,
  source = "",
  extraClassName = "",
  root = "",
  override_filters,
  sectionTestId = "",
  completedCount = 0,
}) => {
  const history = useHistory();

  const [ordered, setOrdered] = useState(null);
  const [expanded, setExpanded] = useState(false);
  const [showAddForm, setShowAddForm] = useState(false);
  const [focusOrderTimeout, setFocusOrderTimeout] = useState(null);
  const [ritualOrderTimeout, setRitualOrderTimeout] = useState(null);
  const [recurringOrderTimeout, setRecurringOrderTimeout] = useState(null);

  const workette = useSelector(state => state.workette);
  const session = useSelector(state => state.session);
  const { is_loading } = useSelector(state => state.api);
  const stateItems = useSelector(state => state.workette.items);
  const days = useSelector(state => state.workette.days);
  const cur_date = useSelector(state => state.session.cur_date);
  const lifeNode = useSelector(selectCurrentlySelectedLifeNode);
  const { add_focus } = useSelector(state => state.onboarding);

  const rootNode = useMemo(
    () => stateItems[root || days[cur_date]],
    [cur_date, days, root, stateItems],
  );

  const rootNodeId = useMemo(() => rootNode?.jid, [rootNode?.jid]);

  const dispatch = useDispatch();
  const set_workette = useCallback(
    (w_id, ctx) => {
      dispatch(wact.set_workette(w_id, ctx));
    },
    [dispatch],
  );
  const set_reflect_tab = useCallback(key => dispatch(refl.set_reflect_tab(key)), [dispatch]);
  const updateStepAddFocusOnboarding = useCallback(
    step => dispatch(onb_act.add_focus.next_or_prev(step)),
    [dispatch],
  );
  const purgeTree = useCallback(
    idx => {
      const purged = [...ordered];
      purged.splice(idx, 1);
      setOrdered(purged);
    },
    [ordered],
  );

  const oldOrder = useMemo(() => {
    let order = null;
    if (rootNode && ["focus", "rituals", "recurrings"].includes(source)) {
      switch (source) {
        case "focus":
          order = rootNode.context.focus_order;
          break;
        case "rituals":
          order = rootNode.context.ritual_order;
          break;
        case "recurrings":
          order = rootNode.context.recurring_order;
          break;
        default:
          break;
      }
    }

    return order;
  }, [
    source,
    rootNode?.context?.focus_order,
    rootNode?.context?.ritual_order,
    rootNode?.context?.recurring_order,
  ]);

  const handle_drag = useCallback(
    result => {
      const { source: treeSource, destination, draggableId } = result;
      if (
        !destination ||
        treeSource.droppableId !== destination.droppableId ||
        treeSource.index === destination.index ||
        !rootNodeId
      ) {
        return;
      }

      const src_idx = ordered.indexOf(draggableId);
      const dest_idx = ordered.indexOf(ordered[destination.index]);
      const new_order = move_arr_item(ordered, src_idx, dest_idx);
      setOrdered(new_order);
    },
    [ordered, rootNodeId],
  );

  const isOrderTheSame = (order1, order2) => {
    if (order1?.length !== order2?.length) {
      return false;
    }

    // Check if every element in both arrays is identical and the same order
    return order1.every((value, index) => value === order2[index]);
  };

  // Save the order for focus or ritual
  useEffect(() => {
    if (rootNodeId) {
      if (["focus", "rituals", "recurrings"].includes(source)) {
        const bypassBins = true;
        const newOrder = binItems(stateItems, items, oldOrder, cur_date, bypassBins);
        setOrdered(newOrder);
      } else {
        const newOrder = binItems(stateItems, items, [], cur_date);
        setOrdered(newOrder);
      }
    }
  }, [items, oldOrder]);

  useEffect(() => {
    if (ordered && ["focus", "rituals", "recurrings"].includes(source)) {
      if (!isOrderTheSame(ordered, oldOrder)) {
        if (source === "focus") {
          focusOrderTimeout && clearTimeout(focusOrderTimeout);

          setFocusOrderTimeout(
            setTimeout(() => {
              set_workette(rootNodeId, { focus_order: ordered });
            }, 1000),
          );
        } else if (source === "rituals") {
          ritualOrderTimeout && clearTimeout(ritualOrderTimeout);

          setRitualOrderTimeout(
            setTimeout(() => {
              set_workette(rootNodeId, { ritual_order: ordered });
            }, 1000),
          );
        } else if (source === "recurrings") {
          recurringOrderTimeout && clearTimeout(recurringOrderTimeout);

          setRecurringOrderTimeout(
            setTimeout(() => {
              set_workette(rootNodeId, { recurring_order: ordered });
            }, 1000),
          );
        }
      }
    }
  }, [ordered]);

  let firstInProgress = "";

  const renderItems = (itemId, i) => {
    if (ordered?.length > 0) {
      let lastWtype = "workette";
      let typeCount = 0;

      return ordered?.map((i, idx) => {
        const witem = stateItems[i];

        if (!witem) {
          return <div key={i}></div>;
        }

        //show a div between types of workettes
        const showDiv =
          lastWtype !==
          (witem?.context?.wtype === "" ? "workette" : witem?.context?.wtype || "workette");

        typeCount = typeCount + 1;

        const hasExtraGap = showDiv;

        if (showDiv) {
          lastWtype =
            witem?.context?.wtype === "" ? "workette" : witem?.context?.wtype || "workette";
          typeCount = 0;
        }

        if (
          firstInProgress === "" &&
          witem?.context?.status === "running" &&
          (!w_filter.checkHidden(witem, workette, session.cur_date, override_filters) ||
            !hideHidden)
        ) {
          firstInProgress = witem?.jid || "";
        }

        return (
          <Draggable key={i} draggableId={i} index={idx}>
            {(provided, snapshot) => {
              const draggingState = snapshot.draggingOver && snapshot.dropAnimation;

              return (
                <div
                  ref={provided.innerRef}
                  {...provided.draggableProps}
                  {...provided.dragHandleProps}
                >
                  {witem &&
                  (!w_filter.checkHidden(witem, workette, session.cur_date, override_filters) ||
                    !hideHidden) ? (
                    <WktItemSingle
                      item={witem}
                      expandState={false}
                      index={idx}
                      leftWktActions={leftWktActions}
                      rightWktActions={rightWktActions}
                      showParent={showParentTag}
                      recursive={true}
                      setShowDrill={setShowDrill}
                      showHoverActions={showHoverActions}
                      purgeTree={() => purgeTree(idx)}
                      tabKey={tabKey}
                      dropdownButtons={dropdownButtons}
                      withoutText={withoutText}
                      parentSnapshot={Boolean(draggingState)}
                      count={typeCount}
                      firstInProgress={firstInProgress}
                      hideHidden={hideHidden}
                      showLeftMargin={false}
                      hasExtraGap={hasExtraGap}
                      override_filters={override_filters}
                    />
                  ) : (
                    <div></div>
                  )}
                </div>
              );
            }}
          </Draggable>
        );
      });
    } else {
      // if all done
      // showSummaryBarTotal[0].sectionLength is the count of the completed items
      if (doneImg && showSummaryBarTotal && completedCount > 0) {
        return <div className="all-done image-container">{doneImg}</div>;
      } else if (emptyImg && items.length === 0) {
        return <div className="all-done image-container">{emptyImg}</div>;
      } else {
        return <></>;
      }
    }
  };

  const toggleExpanded = () => {
    setExpanded(!expanded);
  };

  const handleClickRitualHistory = useCallback(() => {
    set_reflect_tab("ritual-tracking");
    history.push("/reflect");
  }, [history, set_reflect_tab]);

  const percentage = useMemo(
    () =>
      Math.round(
        (completedCount /
          (items.filter(
            item =>
              !w_filter.checkHidden(stateItems[item], workette, session.cur_date, override_filters),
          ).length +
            completedCount)) *
          100,
      ) || 0,
    [completedCount, items, override_filters, session.cur_date, stateItems, workette],
  );

  const newCaption = useMemo(() => {
    if (source === "rituals" || source === "recurrings") {
      if (percentage === 100) {
        return `You have finished all the ${source.slice(0, -1)} tasks today!`;
      }
    }

    return caption;
  }, [caption, percentage, source]);

  return (
    <div
      className={`filtered-day-view ${extraClassName}`}
      style={styleOverride}
      data-testid={sectionTestId}
    >
      <Row className="top-row">
        <Col>
          {labelIconProps && <FontAwesomeIcon {...labelIconProps} />}
          <span className="main-label">{label}</span>
          {!check_frozen(session) && addOption?.includes("add-focus-button") && days[cur_date] && (
            <span
              className="add-button plan-add-button pl-2"
              data-testid={`add-focus-button ${days[cur_date]}`}
            >
              <WktButton
                icon={showAddForm ? faMinusSquare : faPlusSquare}
                status={true}
                onClick={() => {
                  setShowAddForm(showAddForm => !showAddForm);
                  if (add_focus?.running && add_focus?.current_step === 0 && !showAddForm) {
                    setTimeout(() => {
                      updateStepAddFocusOnboarding(1);
                    }, 300);
                  }
                }}
                tooltip="Add a new item"
                tooltipPlacement="right"
              />
            </span>
          )}
        </Col>
        <Col style={{ display: "flex", justifyContent: "flex-end" }}>
          {days[cur_date] &&
            lifeNode &&
            (!lifeNode?.context?.settings ||
              lifeNode?.context?.settings?.aiFlag ||
              !("aiFlag" in lifeNode.context.settings)) &&
            addOption?.includes("focus-recommendation") && (
              <WktButtons
                buttons={["focus-recommendation"]}
                setIsOpenRecommendation={setIsOpenRecommendation}
              />
            )}
          {days[cur_date] && addOption?.includes("ritual-history-button") && (
            <div className="ritual-history-button">
              <WktButton
                icon={faAnalytics}
                status={true}
                onClick={handleClickRitualHistory}
                tooltip="Go to Ritual Tracking"
                data-testid="ritual-history-button"
                tooltipPlacement="left"
              />
            </div>
          )}
        </Col>
      </Row>
      <Row className="caption-row" data-testid={`${source}-section-caption`}>
        {newCaption && <Col>{newCaption}</Col>}
      </Row>
      <Row className="progress-bar-row">
        <Col>
          {showSummaryBarTotal && (
            <WktListProgressBar
              items={items.filter(
                item =>
                  !w_filter.checkHidden(
                    stateItems[item],
                    workette,
                    session.cur_date,
                    override_filters,
                  ),
              )}
              completedCount={completedCount}
              source={source}
              testid={testid}
              isDay={rootNodeId === days[cur_date]}
            />
          )}
        </Col>
      </Row>
      {!check_frozen(session) && days[cur_date] && (
        <WktAddForm
          w_id={
            session.drill_stack?.length > 0
              ? session.drill_stack[session.drill_stack?.length - 1]?.jid
              : days[cur_date]
          }
          context_override={{ is_MIT: true }}
          isOpen={showAddForm}
        />
      )}

      <div className="workette-container">
        {is_loading?.includes(`Loading Recent Day ${cur_date}`) ||
        is_loading?.includes(`Loading/Generating Day ${cur_date}`) ||
        is_loading?.includes("Getting Life") ||
        is_loading?.includes("Loading Jac") ||
        !cur_date ? (
          <div className="workette-skeletons" data-testid="workette-skeletons">
            {skeletons.map((_, index) => (
              <WktItemSingleSkeleton key={index} item={{ context: {} }} />
            ))}
          </div>
        ) : (
          <DragDropContext onDragEnd={handle_drag}>
            <Droppable droppableId={label ? label : "no label"}>
              {provided => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  {renderItems()}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
            {softCap < items.length && (
              <div
                className="text-center border pt-2 pb-2"
                style={{ marginLeft: "-15px", cursor: "pointer" }}
                onClick={toggleExpanded}
              >
                {expanded ? (
                  <>
                    <FontAwesomeIcon icon={faChevronUp} style={{ color: "grey" }} /> Collapse{" "}
                    <FontAwesomeIcon icon={faChevronUp} style={{ color: "grey" }} />
                  </>
                ) : (
                  <>
                    <FontAwesomeIcon icon={faChevronDown} style={{ color: "grey" }} /> Expand{" "}
                    <FontAwesomeIcon icon={faChevronDown} style={{ color: "grey" }} />
                  </>
                )}
              </div>
            )}
          </DragDropContext>
        )}
      </div>
    </div>
  );
};

FilteredDayView.displayName = "FilteredDayView";
FilteredDayView.propTypes = {
  label: PropTypes.string,
  labelIconProps: PropTypes.object,
  caption: PropTypes.string,
  skeletons: PropTypes.array,
  showSummaryBarTotal: PropTypes.bool,
  styleOverride: PropTypes.object,
  showParentTag: PropTypes.bool,
  setShowDrill: PropTypes.func,
  leftWktActions: PropTypes.array,
  rightWktActions: PropTypes.array,
  items: PropTypes.array,
  doneImg: PropTypes.string,
  emptyImg: PropTypes.node,
  addOption: PropTypes.array,
  softCap: PropTypes.number,
  hiddenElements: PropTypes.object,
  hideHidden: PropTypes.bool,
  testid: PropTypes.string,
  tabKey: PropTypes.string,
  dropdownButtons: PropTypes.bool,
  showHoverActions: PropTypes.bool,
  withoutText: PropTypes.bool,
  setIsOpenRecommendation: PropTypes.func,
  source: PropTypes.string,
  extraClassName: PropTypes.string,
  root: PropTypes.string,
  override_filters: PropTypes.object,
  sectionTestId: PropTypes.string,
  completedCount: PropTypes.number,
};

export default memo(FilteredDayView);
