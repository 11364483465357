import { memo } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import WktButtons from "../../../wktbuttons";
import { check_frozen } from "@myca/shared-component";

const HoverButtons = ({
  item,
  showAddForm,
  setShowAddForm,
  toggleExpand,
  setExpanded,
  setShowNoteFrame,
  setShowNoteForm,
}) => {
  const session = useSelector(state => state.session);

  const generateButtons = () => {
    let buttons = [!check_frozen(session) && "add", "drill", "note"].filter(Boolean);

    // If children > 0, workset, link, or note, add edit button
    if (item.children.length > 0 || ["workset", "link", "note"].includes(item.context.wtype)) {
      buttons = ["edit", ...buttons];
    }

    return buttons;
  };

  return (
    <WktButtons
      item={item}
      buttons={generateButtons()}
      showAddForm={showAddForm}
      setShowAddForm={setShowAddForm}
      toggleExpand={toggleExpand}
      setExpanded={setExpanded}
      setShowNoteFrame={setShowNoteFrame}
      setShowNoteForm={setShowNoteForm}
    />
  );
};

HoverButtons.propTypes = {
  item: PropTypes.object.isRequired,
  showAddForm: PropTypes.bool.isRequired,
  setShowAddForm: PropTypes.func.isRequired,
  toggleExpand: PropTypes.func,
  setExpanded: PropTypes.func.isRequired,
  setShowNoteFrame: PropTypes.func,
  setShowNoteForm: PropTypes.func,
};

export default memo(HoverButtons);
