import React from "react";
import PropTypes from "prop-types";

const PlanSelection = ({
  plans,
  planTobeSwitch,
  setPlanTobeSwitch,
  isSwitchingPlan,
  onClickSwitch,
  currentSelectedPlan,
  buttonText,
  stripeSubscriptionPriceId,
  createBillingCheckoutSession,
  showPremiumPrice = false,
  showFreeTrialText = false,
  showUpdateBillingButton = false,
}) => {
  return (
    <>
      {showPremiumPrice ? (
        <div className="price" data-testid="premium-price">
          <h1>Premium</h1>
          <strong>$0</strong>
          <span>First 14 days</span>
        </div>
      ) : (
        <></>
      )}
      <div className="plans" data-testid="plans">
        {plans?.map(plan => {
          return (
            <div
              key={plan.name}
              className={`plan${planTobeSwitch === plan.price_id ? " active" : ""}`}
              onClick={() => setPlanTobeSwitch(plan.price_id)}
              data-testid={`plan-${plan.plan_type}`}
            >
              <div className="d-flex gap-4">
                <div className="description" data-testid={`plan-description-${plan.plan_type}`}>
                  <strong>
                    {plan.description}
                    {showFreeTrialText ? " - 14 days free" : ""}
                  </strong>
                  <span>{plan.amount_description}</span>
                </div>
                <aside>{plan.extra && <div className="extra">{plan.extra}</div>}</aside>
              </div>
            </div>
          );
        })}
      </div>
      <div className="d-flex flex-column gap-4">
        <button
          type="button"
          disabled={isSwitchingPlan || stripeSubscriptionPriceId === currentSelectedPlan?.price_id}
          onClick={() => {
            onClickSwitch(currentSelectedPlan?.price_id);
          }}
          data-testid="subscription-button"
        >
          {buttonText}
        </button>
        {showUpdateBillingButton && (
          <button
            type="button"
            onClick={createBillingCheckoutSession}
            data-testid="update-billing-button"
          >
            Update billing Information
          </button>
        )}
      </div>
    </>
  );
};

PlanSelection.propTypes = {
  plans: PropTypes.array.isRequired,
  planTobeSwitch: PropTypes.string,
  setPlanTobeSwitch: PropTypes.func.isRequired,
  isSwitchingPlan: PropTypes.bool,
  onClickSwitch: PropTypes.func.isRequired,
  currentSelectedPlan: PropTypes.object,
  buttonText: PropTypes.string,
  stripeSubscriptionPriceId: PropTypes.string,
  createBillingCheckoutSession: PropTypes.func.isRequired,
  showPremiumPrice: PropTypes.bool,
  showFreeTrialText: PropTypes.bool,
  showUpdateBillingButton: PropTypes.bool,
};

export default PlanSelection;
