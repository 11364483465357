import { memo } from "react";
import PropTypes from "prop-types";

import "./OnboardingLayout.scss";

const Layout = ({ children, noOverlay, className, ...rest }) => (
  <div
    {...rest}
    className={`onboarding-main-container ${className} ${noOverlay ? "no-overlay" : ""}`}
  >
    {children}
  </div>
);

Layout.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  noOverlay: PropTypes.bool,
};

Layout.defaultProps = {
  children: <></>,
  className: "",
};

export default memo(Layout);
