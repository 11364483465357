import { memo } from "react";
import PropTypes from "prop-types";
import noImagePlaceholder from "src/assets/no-image-placeholder.png";

import "./LinkPreview.scss";

const LinkPreview = ({ title = "", linkMetadata, width = "235px", height = "150px" }) => {
  if (!linkMetadata) {
    return <></>;
  }

  return (
    <div className="link-preview-img">
      <div className="desc">
        <a href={linkMetadata.url} target="_blank" rel="noreferrer" title={linkMetadata.url}>
          <b>{linkMetadata.title || ""}</b>
        </a>
        {linkMetadata.description && <span>{linkMetadata.description}</span>}
      </div>
      <img
        src={linkMetadata.image || noImagePlaceholder}
        alt={linkMetadata["image:alt"] || title || ""}
        width={width}
        height={height}
        loading="lazy"
      />
    </div>
  );
};

LinkPreview.propTypes = {
  title: PropTypes.string,
  linkMetadata: PropTypes.object.isRequired,
  width: PropTypes.string,
  height: PropTypes.string,
};

export default memo(LinkPreview);
