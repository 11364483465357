import { memo } from "react";
import Tree from "@atlaskit/tree";
import PropTypes from "prop-types";
import { Col } from "react-bootstrap";
import { DateTime } from "luxon";

import { useFullDayViewHook } from "../../hooks";

const hide_buttons = true;

const DrilledItems = ({ day, root }) => {
  const { treeData, toggleExpand, handleDrag, renderTreeItem } = useFullDayViewHook(
    root,
    true,
    hide_buttons,
  );

  return (
    Object.keys(treeData.items).length > 1 && (
      <Col key={day.jid}>
        <div className="date-separator">
          <span>{DateTime.fromISO(day.date).toFormat("LLLL dd, yyyy")}</span>
        </div>
        <Tree
          tree={treeData}
          renderItem={renderTreeItem}
          onExpand={toggleExpand}
          onCollapse={toggleExpand}
          onDragEnd={handleDrag}
          offsetPerLevel={15}
          isDragEnabled={false}
          isNestingEnabled
        />
      </Col>
    )
  );
};

DrilledItems.propTypes = {
  day: PropTypes.object.isRequired,
  root: PropTypes.string.isRequired,
};

export default memo(DrilledItems);
