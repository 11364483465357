import { memo, useState, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import Tooltip from "react-simple-tooltip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar as fasStar } from "@fortawesome/pro-solid-svg-icons";
import { faStar as falStar } from "@fortawesome/pro-light-svg-icons";
import PropTypes from "prop-types";

import WktButton from "../WktButton";
import {
  workette_actions as wact,
  session_actions as sact,
  onboarding_actions as onb_act,
  check_frozen,
} from "@myca/shared-component";

import "./WktButtonFocus.scss";

const WktButtonFocus = ({ item, controls, withoutText = false, isFirstItem }) => {
  const [onHover, setOnHover] = useState(false);
  const [extraClass, setExtraClass] = useState("");

  const workette = useSelector(state => state.workette);
  const session = useSelector(state => state.session);
  const lifeID = useSelector(state => state.workette.life);
  const life = useSelector(state => state.workette.items[lifeID["life"]]);

  const dispatch = useDispatch();
  const set_push_alert = useCallback(alert => dispatch(sact.set_push_alert(alert)), [dispatch]);
  const set_workette = useCallback(
    (w_id, ctx) => dispatch(wact.set_workette(w_id, ctx)),
    [dispatch],
  );
  const startFocusOnboarding = useCallback(() => dispatch(onb_act.focus.start()), [dispatch]);

  const toggle_MIT = useCallback(async () => {
    if (check_frozen(session)) {
      set_push_alert({ name: "Focus failed", msg: "You can't edit a frozen day" });
      return;
    }

    controls && controls.start("focus");

    const val = item.context?.is_MIT;

    setExtraClass("");

    if (!val && life && !life.context?.settings?.tours?.includes("FocusOnboarding")) {
      setExtraClass("focus-onboarding-item");
      setTimeout(() => {
        startFocusOnboarding();
      }, 500);
    }

    set_workette(item.jid, { is_MIT: !val });
    controls && controls.start("mount");
  }, [
    controls,
    item.context?.is_MIT,
    item.jid,
    life,
    session,
    set_push_alert,
    set_workette,
    startFocusOnboarding,
  ]);

  if (withoutText) {
    return (
      <WktButton
        iconName="star"
        status={!!workette.items[item.jid].context.is_MIT}
        tooltip="Focus"
        onClick={e => {
          toggle_MIT(item);
          e.stopPropagation();
        }}
        className={`focus-btn ${
          workette.items[item.jid].context.is_MIT ? "active" : ""
        } ${extraClass}`}
      />
    );
  } else {
    return (
      <Tooltip
        padding={10}
        fontSize="11px"
        arrow={4}
        content={<span style={{ whiteSpace: "nowrap" }}>Focus</span>}
        background="#000000C0"
        radius={7}
      >
        <div
          className={`with-text focus-btn ${
            workette.items[item.jid].context.is_MIT ? "active" : ""
          } ${extraClass}`}
          onMouseEnter={() => setOnHover(true)}
          onMouseLeave={() => setOnHover(false)}
          onClick={e => {
            toggle_MIT(item);
            e.stopPropagation();
          }}
        >
          <FontAwesomeIcon
            icon={workette.items[item.jid].context.is_MIT || onHover ? fasStar : falStar}
            size="lg"
          />
          <span>Focus</span>
        </div>
      </Tooltip>
    );
  }
};

WktButtonFocus.propTypes = {
  item: PropTypes.object,
  controls: PropTypes.object.isRequired,
  withoutText: PropTypes.bool,
  isFirstItem: PropTypes.bool,
};

export default memo(WktButtonFocus);
