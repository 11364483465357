import { useContext } from "react";
import { useSelector } from "react-redux";

import Layout from "../Layout";
import { useSpotlightPosition, useOnboardingFunctions } from "../hooks";
import { AppContext } from "src/AppContext";

import logo_gold from "src/assets/logo_gold.svg";
import "./AddFocusOnboarding.scss";

const steps = [
  {
    target: ".focus-list .plan-add-button",
    arrow_placement: "bottom",
  },
  {
    target: ".focus-list .add-form-input",
    arrow_placement: "bottom",
  },
];

const AddFocusOnboarding = () => {
  const { isMobile } = useContext(AppContext);
  const step = useSelector(state => state.onboarding?.add_focus?.current_step || 0);

  const { sectionStyle } = useSpotlightPosition(steps, step);
  const { lifeNode } = useOnboardingFunctions("AddFocusOnboarding", "add_focus");
  const add_focus = useSelector(state => state.onboarding?.add_focus);

  return lifeNode && add_focus?.running ? (
    <>
      {step === 0 && (
        <Layout noOverlay>
          <section
            style={{
              ...sectionStyle,
              flexDirection: "row",
              "--posTranslate": isMobile
                ? `translate(-80%, ${sectionStyle["--posTranslateY"]})`
                : sectionStyle["--posTranslate"],
              "--arrowInset": isMobile ? "auto auto 0 84%" : sectionStyle["--arrowInset"],
              width: "16rem",
            }}
          >
            <div className="side-icon">
              <img src={logo_gold} alt="gold logo" />
            </div>
            <section>
              <div className="body">
                <p>Click here to add your focused item</p>
              </div>
            </section>
          </section>
        </Layout>
      )}
      {step === 1 && (
        <Layout noOverlay>
          <section
            style={{
              ...sectionStyle,
              flexDirection: "row",
              "--posTranslate": isMobile
                ? `translate(-80%, ${sectionStyle["--posTranslateY"]})`
                : sectionStyle["--posTranslate"],
              "--arrowInset": isMobile ? "auto auto 0 84%" : sectionStyle["--arrowInset"],
              width: "16rem",
            }}
          >
            <div className="side-icon">
              <img src={logo_gold} alt="gold logo" />
            </div>
            <section>
              <div className="body">
                <p>Any item that you will create here is automatically set to focus. Try it out.</p>
              </div>
            </section>
          </section>
        </Layout>
      )}
    </>
  ) : (
    <></>
  );
};

export default AddFocusOnboarding;
