import { binItems } from "@myca/shared-component";
import { sort_items } from "./utils";

let root_expanded_children = [];
const buildTree = (
  items,
  root,
  cur_date,
  override_order,
  defaultExpandedState = true,
  filter_children,
) => {
  // if no workette items, return default tree
  if (!items[root]) {
    return { rootId: 1, items: { 1: { id: 1, children: [], data: { showHiddenItems: [] } } } };
  }

  let rootChildren = override_order
    ? sort_items(items, items[root].children, override_order)
    : binItems(items, items[root].children, items[root].context.order, cur_date);

  if (filter_children) {
    rootChildren = filter_children(rootChildren);
  }

  let tree = {
    rootId: root,
    items: {
      [root]: {
        id: root,
        children: rootChildren,
        hasChildren: rootChildren.length > 0,
        isExpanded: true,
        data: {
          ...items[root],
        },
      },
    },
  };

  if (rootChildren.length > 0) {
    rootChildren.map(id => {
      root_expanded_children = items[root].context.expanded_children;

      const child = add_child(
        items,
        id,
        root_expanded_children,
        override_order,
        defaultExpandedState,
        filter_children,
      );
      return (tree = {
        ...tree,
        items: { ...tree.items, ...child },
      });
    });
  }
  return tree;
};

const purgeItem = (treeData, id) => {
  const tree = treeData;
  delete tree.items[id];
  Object.keys(tree.items).map(item => {
    if (tree.items[item].children.includes(id)) {
      tree.items[item].children.splice(tree.items[item].children.indexOf(id), 1);
    }
    return "";
  });
  return tree;
};

const add_child = (
  items,
  id,
  root_expanded_children,
  override_order,
  defaultExpandedState,
  filter_children,
) => {
  const inheritedExpand =
    defaultExpandedState && root_expanded_children && root_expanded_children.includes(id);

  let childChildren = items[id].children;
  if (filter_children) {
    childChildren = filter_children(childChildren);
  }

  let childrenItems = {
    [id]: {
      id: id,
      children: override_order
        ? sort_items(items, childChildren, override_order)
        : binItems(items, childChildren, items[id].context.order),
      hasChildren: childChildren ? childChildren.length > 0 : false,
      isExpanded: inheritedExpand,
      data: {
        ...items[id],
      },
    },
  };

  if (childChildren && childChildren.length > 0) {
    childChildren.map(
      i =>
        (childrenItems = {
          ...childrenItems,
          ...add_child(items, i, root_expanded_children, override_order, defaultExpandedState),
        }),
    );
  }
  return childrenItems;
};

const getExpandedChildren = (tree, root) => {
  if (tree.items[root]) {
    const rootChildren = tree.items[root].children;
    const expandedChildren = rootChildren.map(id => {
      if (tree.items[id].isExpanded) {
        return id;
      } else {
        return "";
      }
    });
    return expandedChildren.filter(e => {
      return e !== "";
    });
  } else {
    return [];
  }
};

export { buildTree, purgeItem, getExpandedChildren };
