import { useEffect, useState } from "react";

const useSpotlightPosition = (steps = [], step = 0) => {
  const [spotlightPosition, setSpotlightPosition] = useState(null);
  const [arrowPosition, setArrowPosition] = useState(null);

  useEffect(() => {
    let spotlight = steps[step]?.target && document.querySelectorAll(steps[step]?.target);

    spotlight =
      spotlight?.length > 0
        ? spotlight[0] // just get the first occurrence element
        : steps[step]?.secondary_target &&
          document.querySelectorAll(steps[step]?.secondary_target) &&
          document.querySelectorAll(steps[step]?.secondary_target)[0]; // if the primary target is cannot find, use the secondary target

    if (spotlight) {
      const position = spotlight?.getBoundingClientRect();
      const arrowPlacement = steps[step]?.arrow_placement;
      let arrowRotate = 0;
      let arrowTranslate = "";
      let arrowInset = "";

      let translateX = "";
      let translateY = "";

      const centerX = (position.right - position.left) / 2;
      const centerY = (position.bottom - position.top) / 2;

      switch (arrowPlacement) {
        case "top":
          arrowRotate = 180;
          arrowTranslate = "translate(-50%, -100%)";
          arrowInset = "0 0 0 50%";
          translateX = `calc(-50% + ${centerX}px)`;
          translateY = `calc(${position.bottom - position.top}px + 1rem)`;
          break;
        case "left":
          arrowRotate = 90;
          arrowTranslate = "translate(-79%, -50%)";
          arrowInset = "50% 0 0 2px";
          translateX = `calc(${position.right - position.left}px + 1rem)`;
          translateY = `calc(-50% + ${centerY}px)`;
          break;
        case "left-top":
          arrowRotate = 90;
          arrowTranslate = "translate(-79%, -50%)";
          arrowInset = `${centerY}px 0 0 1px`;
          translateX = `calc(${position.right - position.left}px + 1rem)`;
          translateY = 0;
          break;
        case "top-left":
          arrowRotate = 180;
          arrowTranslate = "translate(-50%, -100%)";
          arrowInset = `0 0 0 ${centerX}px`;
          translateX = 0;
          translateY = `calc(${position.bottom - position.top}px + 1rem)`;
          break;
        case "right":
          arrowRotate = 270;
          arrowTranslate = "translate(calc(79% - 1px), 50%)";
          arrowInset = "auto 0 50% auto";
          translateX = "calc(-100% - 1rem)";
          translateY = `calc(-50% + ${centerY}px)`;
          break;
        case "bottom":
          arrowRotate = 360;
          arrowTranslate = "translate(-50%, 100%)";
          arrowInset = "auto auto 0 50%";
          translateX = `calc(-50% + ${centerX}px)`;
          translateY = "calc(-100% - 1rem)";
          break;
        default:
          break;
      }

      setSpotlightPosition({
        top: position.top + "px",
        left: position.left + "px",
        translateX,
        translateY,
        translate: `translate(${translateX}, ${translateY})`,
      });

      setArrowPosition({
        arrowRotate: `${arrowRotate}deg`,
        arrowTranslate,
        arrowInset,
      });
    } else {
      setArrowPosition({
        arrowInset: 0,
      });

      setSpotlightPosition({
        top: "30%",
        left: "50%",
        translate: "translate(-50%, -30%)",
      });
    }
  }, [step]);

  return {
    spotlightPosition,
    arrowPosition,
    sectionStyle: {
      "--posTop": `${spotlightPosition?.top || 0}`,
      "--posLeft": `${spotlightPosition?.left || 0}`,
      "--posTranslateX": spotlightPosition?.translateX,
      "--posTranslateY": spotlightPosition?.translateY,
      "--posTranslate": spotlightPosition?.translate,
      "--arrowRotate": arrowPosition?.arrowRotate,
      "--arrowTranslate": arrowPosition?.arrowTranslate,
      "--arrowInset": arrowPosition?.arrowInset,
    },
  };
};

export default useSpotlightPosition;
