import { memo, useCallback } from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";

import WktButton from "../WktButton";

import {
  session_actions as sact,
  workette_actions as wact,
  selectCurrentlySelectedLifeNode,
  logTour,
} from "@myca/shared-component";

const WktButtonDrill = ({ jid, tooltipPlacement = "top" }) => {
  const { drill_stack } = useSelector(state => state.session);
  const lifeID = useSelector(state => state.workette.life);
  const lifeNode = useSelector(selectCurrentlySelectedLifeNode);
  const drillOnboarding = useSelector(state => state.onboarding?.drill);

  const dispatch = useDispatch();
  const set_selected_wkt = useCallback(w_id => dispatch(sact.set_selected_wkt(w_id)), [dispatch]);
  const update_drill_stack = useCallback(
    newStacks => dispatch(sact.update_drill_stack(newStacks)),
    [dispatch],
  );
  const set_workette = useCallback(
    (w_id, ctx) => dispatch(wact.set_workette(w_id, ctx)),
    [dispatch],
  );

  const finishOnboarding = useCallback(() => {
    update_drill_stack([...drill_stack, { jid, type: "drill" }]);

    logTour(lifeID, lifeNode, set_workette, "DrillOnboarding");
  }, [lifeID, lifeNode, set_workette, update_drill_stack, drill_stack, jid]);

  const handleOnclick = useCallback(
    e => {
      e.stopPropagation();
      set_selected_wkt(null);

      if (drillOnboarding?.running) {
        finishOnboarding();
      }

      update_drill_stack([...drill_stack, { jid, type: "drill" }]);
    },
    [
      drillOnboarding?.running,
      drill_stack,
      finishOnboarding,
      jid,
      set_selected_wkt,
      update_drill_stack,
    ],
  );

  return (
    <WktButton
      data-testid={"wkt-drill-btn " + jid}
      iconName="binoculars"
      iconType="fas"
      onClick={handleOnclick}
      tooltip="Drill In!"
      className="drill-btn"
      tooltipPlacement={tooltipPlacement}
    />
  );
};

WktButtonDrill.propTypes = {
  jid: PropTypes.string.isRequired,
  tooltipPlacement: PropTypes.oneOf(["top", "bottom", "left", "right"]),
};

export default memo(WktButtonDrill);
