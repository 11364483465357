import { useEffect, useState } from "react";
import moment from "moment";
import { DateTime } from "luxon";
import PropTypes from "prop-types";
import { Modal } from "react-bootstrap";
import Calendar from "react-calendar";

/**
 * Modal for selecting a date
 * @param {function} handleClose - function to close modal
 */
const CustomDateModal = ({ value, setFunc, handleClose }) => {
  const [dateValue, setDateValue] = useState(new Date());

  useEffect(() => {
    const val = typeof value === "string" ? new Date(DateTime.fromSQL(value)) : value;
    setDateValue(val);
  }, [value]);

  const handleChange = date => {
    const d = moment(date).format("YYYY-MM-DD");
    setFunc(d);
    handleClose();
  };

  const handleClear = () => {
    setFunc(null);
    handleClose();
  };

  return (
    <Modal
      show={true}
      onHide={handleClose}
      className="snooze-custom-date-modal"
      data-testid="snooze-custom-date-modal"
    >
      <Modal.Header closeButton>
        <Modal.Title>Select Date</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Calendar
          onClickDay={handleChange}
          value={dateValue}
          minDate={new Date()}
          defaultActiveStartDate={dateValue}
        />
        <span style={{ color: "blue" }} onClick={handleClear}>
          clear snooze
        </span>
      </Modal.Body>
    </Modal>
  );
};

CustomDateModal.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.instanceOf(Date).isRequired]),
  setFunc: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default CustomDateModal;
