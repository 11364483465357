import { useState, useEffect, useCallback, memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import Skeleton from "react-loading-skeleton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/pro-solid-svg-icons";
import InfoTooltip from "../general/InfoTooltip";

import WktRecommendation from "./WktRecommendation";

import { workette_actions as wact } from "@myca/shared-component";

import logo from "./FocusLogo_Gold.svg";
import "./FocusRecommendations.scss";

const FocusRecommendations = ({ setIsOpenRecommendation }) => {
  const { items, days } = useSelector(state => state.workette);
  const { cur_date } = useSelector(state => state.session);
  const { is_loading } = useSelector(state => state.api);

  const [recommendations, setRecommendations] = useState(null);

  const dispatch = useDispatch();
  const set_workette = useCallback(
    (w_id, ctx) => dispatch(wact.set_workette(w_id, ctx)),
    [dispatch],
  );

  useEffect(() => {
    setRecommendations(items[days[cur_date]]?.context?.focusR);
  }, [cur_date, days, items]);

  const dismissRecs = useCallback(() => {
    const todayID = days[cur_date];
    setIsOpenRecommendation && setIsOpenRecommendation(false);
    set_workette(todayID, { focusR: null });
  }, [cur_date, days, setIsOpenRecommendation, set_workette]);

  const removeRecommendation = useCallback(
    w_id => {
      const todayID = days[cur_date];
      const newRecommendations = [...recommendations];
      newRecommendations.splice(newRecommendations.indexOf(w_id), 1);

      set_workette(todayID, { focusR: newRecommendations });
      setRecommendations(newRecommendations);
      if (newRecommendations.length === 0) {
        setIsOpenRecommendation && setIsOpenRecommendation(false);
      }
    },
    [cur_date, days, recommendations, setIsOpenRecommendation, set_workette],
  );

  return is_loading.includes("Getting Suggested Focus") ? (
    <div className="recommendations-view">
      <div className="main-label">
        <div>
          <img src={logo} alt="myca-ai" loading="lazy" /> Recommendations for Today
        </div>
        <div>
          <span className="info-tooltip">
            <InfoTooltip
              description={
                "This AI feature analyzes your days and helps you focus on things that are due soon or have been snoozed for a long time."
              }
            />
          </span>
          <span className="dismiss-btn" onClick={dismissRecs}>
            <FontAwesomeIcon icon={faTimes} />
          </span>
        </div>
      </div>
      <div className="caption-row">Myca is currently thinking...</div>
      <Skeleton />
    </div>
  ) : (
    <div className="recommendations-view" data-testid="recommendations-view">
      <div className="main-label">
        <div>
          <img src={logo} alt="myca-ai" loading="lazy" /> Recommendations for Today
        </div>
        <div>
          <span className="info-tooltip">
            <InfoTooltip
              description={
                "This AI feature analyzes your days and helps you focus on things that are due soon or have been snoozed for a long time."
              }
            />
          </span>
          <span className="dismiss-btn" onClick={dismissRecs}>
            <FontAwesomeIcon icon={faTimes} />
          </span>
        </div>
      </div>

      <div className="caption-row">
        Based on your history, you may want to consider focusing on these things today
      </div>
      {recommendations === "none" ? (
        <div>Your focus list appears to be complete.</div>
      ) : (
        <div className="focus-recommendation-list" data-testid="focus-recommendation-list">
          {recommendations &&
            recommendations.map(item => {
              return <WktRecommendation w_id={item} remove={removeRecommendation} />;
            })}
        </div>
      )}
    </div>
  );
};

FocusRecommendations.propTypes = {
  setIsOpenRecommendation: PropTypes.func,
};

export default memo(FocusRecommendations);
