import { useEffect, useState, useCallback, useRef, useLayoutEffect, lazy, Suspense } from "react";
import { Row, Col } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowFromLeft, faArrowToLeft } from "@fortawesome/pro-solid-svg-icons";

import {
  session_actions as sact,
  onboarding_actions as onb_act,
  selectCurrentlySelectedLifeNode,
  date_now,
} from "@myca/shared-component";

import ReleaseModal from "../../utils/release-modal";
import PerformRightContainer from "./PerformRightContainer";
import PerformMainView from "./PerformMainView";

import "./PerformMain.scss";
import useFetchCalendar from "../MycaCalendar/hooks/useFetchCalendar";

const DrillStack = lazy(() => import("./DrillStack"));

/**
 * Main Container for Perform Page, establishes primary connection to back end and loads in most recent day
 */
const PerformMain = () => {
  const history = useHistory();
  const code = new URLSearchParams(history?.location?.search)?.get("code");
  const { getToken } = useFetchCalendar();

  const {
    toggle_trophy,
    drill_stack,
    freeze_override,
    sidebar_collapsed,
    present_tabkey: tabKey,
  } = useSelector(state => state.session);
  const { days } = useSelector(state => state.workette);

  const lifeNode = useSelector(selectCurrentlySelectedLifeNode);
  const {
    drill: drillOnboarding,
    ritual: ritualOnboarding,
    add_item: addItemOnboarding,
    add_focus: addFocusOnboarding,
    focus_tab: focusTabOnboarding,
    perform_tab: performTabOnboarding,
  } = useSelector(state => state.onboarding);

  const [certifyStep, modifyCertifyStep] = useState(0);
  const [showTrophy, setShowTrophy] = useState(true);
  const [rowClass, setRowClass] = useState("row-lg");

  const rowRef = useRef(null);
  const performMainContainerRef = useRef(null);

  const used_myca_count = lifeNode?.context?.settings?.used_myca_count;

  const dispatch = useDispatch();
  const setTabKey = useCallback(key => dispatch(sact.set_present_tabkey(key)), [dispatch]);
  const toggle_trophy_sidebar = useCallback(
    e => dispatch(sact.toggle_trophy_sidebar(e)),
    [dispatch],
  );
  const startPerfomTabOnboarding = useCallback(
    () => dispatch(onb_act.perform_tab.start()),
    [dispatch],
  );
  const set_sidebar_collapse = useCallback(
    state => dispatch(sact.set_sidebar_collapse(state)),
    [dispatch],
  );

  const getRowClass = useCallback(() => {
    if (rowRef?.current) {
      const rowWidth = rowRef?.current?.getBoundingClientRect?.().width || 0;
      setRowClass(Math.floor(rowWidth) > 600 ? "row-lg" : "row-sm");
    }
  }, [rowRef]);

  const toggleTrophy = useCallback(() => {
    toggle_trophy_sidebar(!showTrophy);
  }, [showTrophy, toggle_trophy_sidebar]);

  useEffect(() => {
    code && getToken(code);
  }, [code]);

  useEffect(() => {
    if (
      !addFocusOnboarding?.running && // dont redirect to plan tab if onboarding is currently running
      !focusTabOnboarding?.running && // dont redirect to plan tab if onboarding is currently running
      (!used_myca_count || used_myca_count === 0)
    ) {
      setTabKey("plan");
    }
  }, [used_myca_count]);

  useEffect(() => {
    if (
      lifeNode &&
      days.hasOwnProperty(date_now()) &&
      tabKey === "plan" &&
      !lifeNode.context?.settings?.tours.includes("PerformTabOnboarding") &&
      !drillOnboarding?.running &&
      !ritualOnboarding?.running &&
      !addItemOnboarding?.running &&
      drill_stack?.length === 0 &&
      !performTabOnboarding?.running
    ) {
      startPerfomTabOnboarding();
    }
  }, [
    addItemOnboarding?.running,
    days,
    drillOnboarding?.running,
    drill_stack?.length,
    lifeNode,
    performTabOnboarding?.running,
    ritualOnboarding?.running,
    startPerfomTabOnboarding,
    tabKey,
  ]);

  useLayoutEffect(() => {
    getRowClass();

    if (rowRef) {
      window.addEventListener("resize", getRowClass);
    }
    return () => {
      window.removeEventListener("resize", getRowClass);
    };
  }, [getRowClass]);

  useEffect(() => {
    setShowTrophy(toggle_trophy);
  }, [toggle_trophy]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      getRowClass();
    }, 300);

    return () => clearTimeout(timeout);
  }, [getRowClass, sidebar_collapsed, toggle_trophy]);

  useEffect(() => {
    if (drill_stack?.length > 0 && performMainContainerRef?.current) {
      performMainContainerRef.current.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  }, [drill_stack]);

  useEffect(() => {
    if (drillOnboarding?.running && document.querySelector(".myca-tour-drill")) {
      performMainContainerRef.current.scrollTo({
        top: document
          .querySelector(".myca-tour-drill")
          .closest("div[data-react-beautiful-dnd-draggable]").offsetTop,
      });
    }
  }, [drillOnboarding?.running]);

  return (
    <>
      <div className="perform-main-wrapper">
        <div
          className={`perform-main-view-container ${freeze_override ? "pointer-none" : ""}`}
          ref={performMainContainerRef}
        >
          <div
            className="toggle-left-nav-button"
            onClick={() => set_sidebar_collapse(!sidebar_collapsed)}
          >
            <FontAwesomeIcon icon={!sidebar_collapsed ? faArrowToLeft : faArrowFromLeft} />
          </div>
          {drill_stack?.length > 0 && !freeze_override ? (
            <Suspense fallback={""}>
              <DrillStack setTabKey={setTabKey} />
            </Suspense>
          ) : (
            <Row className="m-0">
              <Col className="perform-main-container">
                <PerformMainView
                  certifyMode={certifyStep}
                  modifyCertifyStep={modifyCertifyStep}
                  toggleTrophy={toggleTrophy}
                  showTrophy={showTrophy}
                  tabKey={tabKey}
                  setTabKey={setTabKey}
                  rowRef={rowRef}
                  rowClass={rowClass}
                />
              </Col>
            </Row>
          )}
        </div>
        <PerformRightContainer onAnimationComplete={getRowClass} />
      </div>
      <ReleaseModal />
    </>
  );
};

export default PerformMain;
