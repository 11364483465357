import { forwardRef, memo } from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarDay as fadCalendarDay } from "@fortawesome/pro-duotone-svg-icons";

import "./WktButtonDateDisplay.scss";

const WktButtonDateDisplay = forwardRef(({ value, onClick }, ref) => {
  return (
    <div className="due-date-form" onClick={onClick} ref={ref}>
      <div className="date-input" data-testid="due-date-value">
        {value ? (
          <span>{value}</span>
        ) : (
          <>
            Set Date <FontAwesomeIcon icon={fadCalendarDay} />
          </>
        )}
      </div>
    </div>
  );
});

WktButtonDateDisplay.displayName = "WktButtonDateDisplay";

WktButtonDateDisplay.propTypes = {
  value: PropTypes.string,
  onClick: PropTypes.func.isRequired,
};

export default memo(WktButtonDateDisplay);
