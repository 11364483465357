import { useState, createContext, useLayoutEffect, useMemo } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

export const AppContext = createContext();

const AppProvider = ({ children }) => {
  const [isMobile, setIsMobile] = useState(false);
  const [isTablet, setIsTablet] = useState(false);
  const [userAgent, setUserAgent] = useState("");
  const [showOnboardingVideos, setShowOnboardingVideos] = useState(false);

  const { revenue_cat } = useSelector(state => state.subscription);

  const isNonSubscriber = useMemo(() => {
    if (revenue_cat) {
      const subscriptions = revenue_cat?.subscriber?.subscriptions;

      const subscription =
        revenue_cat?.subscriber?.entitlements?.standard?.product_identifier &&
        subscriptions[revenue_cat?.subscriber?.entitlements?.standard?.product_identifier];

      return Boolean(
        Object.keys(subscriptions || {})?.length === 0 ||
          !subscription ||
          subscription?.billing_issues_detected_at,
      );
    }

    return true;
  }, [revenue_cat]);

  useLayoutEffect(() => {
    const ua = window.navigator.userAgent;
    setUserAgent(/iPad|iPhone|iPod/.test(ua) ? "ios" : "android");

    const screenWidth = window.screen.width;

    const checkDevice = () => {
      const isMobileDevice = /Android|webOS|iPhone|BlackBerry|Windows Phone/i.test(ua);
      const isTabletDevice =
        /iPad|Tablet/i.test(ua) || (/Android/i.test(ua) && screenWidth > 600 && screenWidth < 1024);

      setIsMobile(isMobileDevice && !isTabletDevice);
      setIsTablet(isTabletDevice);
    };

    checkDevice();
  }, []);

  return (
    <AppContext.Provider
      value={{
        isMobile,
        userAgent,
        showOnboardingVideos,
        setShowOnboardingVideos,
        isNonSubscriber,
        isTablet,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

AppProvider.propTypes = {
  children: PropTypes.node,
};

export default AppProvider;
