/**
 * Strips HTML tags from a string.
 *
 * From https://stackoverflow.com/a/47140708.
 *
 * @param html
 */
export function stripHtml(html) {
  const doc = new DOMParser().parseFromString(html, "text/html");

  return doc.body.textContent || "";
}
