import { useState, useCallback, memo, useEffect, useMemo, useRef } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { OverlayTrigger, Popover } from "react-bootstrap";

import Tooltip from "react-simple-tooltip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/pro-solid-svg-icons";
import { DateTime } from "luxon";

import WktButton from "../WktButton";
import WktRitualForm from "../../wktforms/WktRitualForm";
import HelpInfoTooltip from "../../../help/HelpInfoTooltip";

import {
  workette_actions as wact,
  session_actions as sact,
  onboarding_actions as onb_act,
  print_day_of_week,
  remove_date_offset,
  ordinal_suffix_of,
  check_frozen,
  is_item_frozen,
  generate_new_ritual_history,
  selectCurrentlySelectedLifeNode,
  logTour,
} from "@myca/shared-component";

import "./WktButtonRitual.scss";

/**
 * Clickable component to launch ritual editing form
 * @param {object} item workette that this button is associated with
 * @param {boolean} expanded just show icon or show repeat frequency
 */
const WktButtonRitual = ({ item, expanded = false }) => {
  const overlayRef = useRef(null);

  const [isOpen, setisOpen] = useState(false);
  const [showOverlay, setShowOverlay] = useState(false);
  const [showCustomForm, setShowCustomForm] = useState(false);
  const [isRitualChecked, setIsRitualChecked] = useState(true);
  const [saveTimeout, setSaveTimeout] = useState(null);
  const [extraClass, setExtraClass] = useState("");
  const [overrideOpen, setOverrideOpen] = useState(false);

  const workette = useSelector(state => state.workette);
  const session = useSelector(state => state.session);
  const lifeNode = useSelector(selectCurrentlySelectedLifeNode);
  const ritualOnboarding = useSelector(state => state.onboarding?.ritual);

  const dispatch = useDispatch();
  const set_workette = useCallback(
    (w_id, ctx) => dispatch(wact.set_workette(w_id, ctx)),
    [dispatch],
  );
  const set_push_alert = useCallback(alert => dispatch(sact.set_push_alert(alert)), [dispatch]);
  const startRitualOnboarding = useCallback(() => dispatch(onb_act.ritual.start()), [dispatch]);

  const isRitual = useMemo(() => {
    if (item?.context?.is_ritual) {
      if (!("ritual_flag" in item.context.is_ritual)) {
        return true;
      }

      return item.context.is_ritual.ritual_flag;
    }

    return true;
  }, [item.context.is_ritual]);

  const handleShowModal = useCallback(
    e => {
      if (is_item_frozen(item.jid, workette.items, session) || check_frozen(session)) {
        return true;
      }

      e.stopPropagation();

      setisOpen(!isOpen);

      setExtraClass("");
      if (
        !isOpen &&
        !lifeNode?.context?.settings?.tours?.includes("RitualOnboarding") &&
        !item.context.is_ritual
      ) {
        setOverrideOpen(true);
        setExtraClass("ritual-onboarding-item");
        startRitualOnboarding();
      }
    },
    [
      isOpen,
      item.context.is_ritual,
      item.jid,
      lifeNode?.context?.settings?.tours,
      session,
      startRitualOnboarding,
      workette.items,
    ],
  );

  const clearRitual = useCallback(() => {
    if (check_frozen(session)) {
      set_push_alert({ name: "Ritual failed", msg: "You can't edit a frozen day" });
      return;
    }

    const ritualHistory = generate_new_ritual_history({
      item,
      new_history: {
        day: session.cur_date,
        snoozed_till: item.context.snoozed_till,
        is_ritual: false,
      },
    });

    set_workette(item.jid, {
      is_ritual: false,
      ritual_history: ritualHistory,
    });
  }, [item, session, set_push_alert, set_workette]);

  const handleToggle = useCallback(
    show => {
      if (is_item_frozen(item.jid, workette.items, session) || check_frozen(session)) {
        return;
      }

      if (overrideOpen) {
        setOverrideOpen(false);
      }

      if (show) {
        setIsRitualChecked(isRitual);
      }

      setShowOverlay(show);
    },
    [isRitual, item.jid, overrideOpen, session, workette.items],
  );

  const formatRitualString = () => {
    let frequency = item.context.is_ritual.frequency.toLowerCase();
    const rate = item.context.is_ritual.interval;
    if (rate === 1) {
      frequency = frequency.substring(0, frequency.length - 1);
      return "Every " + frequency;
    }
    return "Every " + rate + " " + frequency;
  };

  useEffect(() => {
    setIsRitualChecked(isRitual);
  }, [isRitual]);

  const getDay = useMemo(
    () =>
      remove_date_offset(session.cur_date).getDay() === 0
        ? 7
        : remove_date_offset(session.cur_date).getDay() - 1,
    [session.cur_date],
  );

  const handleClickPreset = useCallback(
    type => {
      const startDate = DateTime.now().toFormat("yyyy-LL-dd");
      let rule = false;

      switch (type) {
        case "daily":
          rule = {
            frequency: "DAYS",
            start: startDate,
            interval: 1,
            ritual_flag: isRitualChecked,
          };
          break;
        case "weekly":
          rule = {
            frequency: "WEEKS",
            start: startDate,
            byDayOfWeek: Array(7)
              .fill(false)
              .map((v, i) => i === getDay),
            interval: 1,
            ritual_flag: isRitualChecked,
          };
          break;
        case "monthly":
          rule = {
            frequency: "MONTHS",
            start: startDate,
            byDayOfMonth: remove_date_offset(session.cur_date).getDate(),
            interval: 1,
            ritual_flag: isRitualChecked,
          };
          break;
        case "weekdays":
          rule = {
            frequency: "WEEKS",
            start: startDate,
            byDayOfWeek: [true, true, true, true, true, false, false],
            interval: 1,
            ritual_flag: isRitualChecked,
          };
          break;
        default:
          rule = false;
          break;
      }

      if (rule) {
        const ritualHistory = generate_new_ritual_history({
          item,
          new_history: {
            day: session.cur_date,
            snoozed_till: item.context.snoozed_till,
            is_ritual: rule,
          },
        });

        // if the user set item as ritual for the first time, save it to tours
        !lifeNode?.context?.settings?.tours.includes("CreatedRitual") &&
          logTour(workette.life, lifeNode, set_workette, "CreatedRitual");

        set_workette(item.jid, { is_ritual: rule, ritual_history: ritualHistory });
        setOverrideOpen(false);
        setShowOverlay(false);
      }
    },
    [getDay, isRitualChecked, item, lifeNode, session.cur_date, set_workette, workette.life],
  );

  const handleOnChangeRitualFlag = useCallback(
    isChecked => {
      saveTimeout && clearTimeout(saveTimeout);

      if (Boolean(item?.context?.is_ritual)) {
        const oldRitualFlag = item?.context?.is_ritual.ritual_flag;
        const is_ritual = { ...item.context.is_ritual, ritual_flag: isChecked };

        if (oldRitualFlag !== isChecked) {
          const ritualHistory = generate_new_ritual_history({
            item,
            new_history: {
              day: session.cur_date,
              snoozed_till: item.context.snoozed_till,
              is_ritual,
            },
          });

          setSaveTimeout(
            setTimeout(() => {
              set_workette(item.jid, { is_ritual, ritual_history: ritualHistory });
              setShowOverlay(false);
            }, 1000),
          );
        }
      }

      setIsRitualChecked(isChecked);
    },
    [item, saveTimeout, session.cur_date, set_workette],
  );

  const RitualPopover = (
    <Popover
      id="recurring-popover"
      bsPrefix="recurring-popover popover"
      data-testid="recurring-menu-container-popover"
    >
      {showCustomForm ? (
        <WktRitualForm
          w_id={item.jid}
          setShowCustomForm={setShowCustomForm}
          isRitualChecked={isRitualChecked}
          setIsRitualChecked={setIsRitualChecked}
          setShowOverlay={setShowOverlay}
        />
      ) : (
        <div className="recurring-menu" data-testid="recurring-menu-container">
          <ul>
            <li data-testid="daily-option" onClick={() => handleClickPreset("daily")}>
              Daily
            </li>
            <li data-testid="weekly-option" onClick={() => handleClickPreset("weekly")}>
              Weekly on {print_day_of_week(remove_date_offset(session.cur_date).getDay())}
            </li>
            <li data-testid="monthly-option" onClick={() => handleClickPreset("monthly")}>
              Monthly on {ordinal_suffix_of(remove_date_offset(session.cur_date).getDate())}
            </li>
            <li data-testid="weekdays-option" onClick={() => handleClickPreset("weekdays")}>
              Every weekday (Monday to Friday)
            </li>
            <li
              data-testid="recurring-custom"
              onClick={() => {
                setShowOverlay(true);
                setShowCustomForm(true);
              }}
            >
              Custom...
            </li>
            <li>
              <label>
                Ritual:
                <input
                  name="Recurring"
                  type="checkbox"
                  checked={isRitualChecked}
                  onChange={e => {
                    handleOnChangeRitualFlag(e.target.checked);
                  }}
                  data-testid="ritual-flag"
                />
              </label>
              <HelpInfoTooltip
                placement="right"
                message="Rituals are recurring items that you are looking to build habits around and will show up in their own area"
              />
            </li>
          </ul>
        </div>
      )}
    </Popover>
  );

  useEffect(() => {
    const handleClickOutside = e => {
      const recurringMenu = document.querySelector(".recurring-menu");
      if (
        (!Boolean(recurringMenu) && overlayRef.current && !overlayRef.current.contains(e.target)) ||
        (Boolean(recurringMenu) && !Boolean(recurringMenu.contains(e.target)))
      ) {
        setShowOverlay(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    showCustomForm && document.removeEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [overlayRef, showCustomForm]);

  return (
    <span
      className={`ritual-button ${extraClass}`}
      onClick={e => {
        e.stopPropagation();
      }}
      ref={overlayRef}
    >
      {expanded ? (
        <span
          className="ritual-summary"
          onClick={handleShowModal}
          data-testid={"wkt-ritual-btn-expanded"}
        >
          <OverlayTrigger
            show={showOverlay || overrideOpen}
            rootClose={!ritualOnboarding?.running}
            trigger="click"
            placement="bottom"
            overlay={RitualPopover}
            onToggle={handleToggle}
          >
            {item.context.is_ritual && item.context.is_ritual.frequency ? (
              <span>{formatRitualString()}</span>
            ) : (
              <span>Configure Ritual</span>
            )}
          </OverlayTrigger>
          {item.context.is_ritual && (
            <span className="clear-link" onClick={clearRitual}>
              <Tooltip
                adding={10}
                fontSize="11px"
                arrow={4}
                content={<span style={{ whiteSpace: "nowrap" }}>Clear Ritual</span>}
                background="#000000C0"
                radius={7}
              >
                <FontAwesomeIcon icon={faTimesCircle} color="grey" />
              </Tooltip>
            </span>
          )}
        </span>
      ) : (
        <OverlayTrigger
          show={showOverlay || overrideOpen}
          rootClose={!ritualOnboarding?.running}
          trigger="click"
          placement="bottom"
          onToggle={handleToggle}
          overlay={RitualPopover}
        >
          <WktButton
            iconName="sync"
            status={!!item?.context.is_ritual}
            tooltip="Setup Recurrance"
            onClick={handleShowModal}
            data-testid={`wkt-ritual-btn ${item.jid}`}
          />
        </OverlayTrigger>
      )}
    </span>
  );
};

WktButtonRitual.propTypes = {
  item: PropTypes.object.isRequired,
  expanded: PropTypes.bool,
};

export default memo(WktButtonRitual);
