import { useState, useEffect, useCallback, memo } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import Tree, { mutateTree } from "@atlaskit/tree";
import WktItemSingle from "../../workette/WktItemSingle/";
import { buildTree, purgeItem } from "../../../utils/tree-builder";
import { workette_filters as w_filter, nearestColor } from "@myca/shared-component";

const WorketteTree = ({
  root,
  leftWktActions,
  rightWktActions,
  parentSnapshot = false,
  override_filters,
}) => {
  const { session, workette } = useSelector(state => state);
  const [treeData, setTreeData] = useState(
    buildTree(workette.items, root, session.cur_date, false),
  );

  useEffect(() => {
    const defaultExpandedState = false;
    const tree = buildTree(workette.items, root, session.cur_date, false, defaultExpandedState);
    setTreeData(tree);
  }, [root, session.cur_date, workette.items]);

  let lastWtype = "workette";
  let typeCount = 0;

  const wsetColor = useCallback(
    w_id =>
      nearestColor({
        w_id,
        items: workette.items,
        defaultColor: "#F8F8F8",
        skipSelf: true,
      }),
    [workette.items],
  );

  const renderTreeItem = RenderItemParams => {
    const { item, provided } = RenderItemParams;
    const witem = workette.items[item.id];

    if (!witem) {
      return (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        ></div>
      );
    }
    // Boolean to see if item should be hidden
    const isHidden = w_filter.checkHidden(witem, workette, session.cur_date, override_filters);

    //show a div between types of workettes
    const showDiv =
      lastWtype !==
      (witem?.context?.wtype === "" ? "workette" : witem?.context?.wtype || "workette");
    typeCount = typeCount + 1;

    const hasExtraGap = showDiv;

    if (showDiv) {
      lastWtype = witem?.context?.wtype === "" ? "workette" : witem?.context?.wtype || "workette";
      typeCount = 0;
    }

    return (
      <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
        {!isHidden && witem && (
          <WktItemSingle
            expandState={false}
            item={witem}
            leftWktActions={leftWktActions}
            rightWktActions={rightWktActions}
            showOn
            purgeTree={() => setTreeData(purgeItem(treeData, item.id))}
            parentSnapshot={parentSnapshot}
            count={typeCount}
            recursive={true}
            sideLineColor={wsetColor(item.id)}
            hasExtraGap={hasExtraGap}
            override_filters={override_filters}
          />
        )}
      </div>
    );
  };

  const handleExpandItem = useCallback(
    itemId => {
      setTreeData(mutateTree(treeData, itemId, { isExpanded: true }));
    },
    [treeData],
  );

  const handleCollapseItem = useCallback(
    itemId => {
      setTreeData(mutateTree(treeData, itemId, { isExpanded: false }));
    },
    [treeData],
  );

  return (
    <div
      style={{
        paddingLeft: "15px",
      }}
    >
      <Tree
        tree={treeData}
        renderItem={renderTreeItem}
        onExpand={handleExpandItem}
        onCollapse={handleCollapseItem}
        isDragEnabled={false}
        isNestingEnabled={false}
        offsetPerLevel={35}
      />
    </div>
  );
};

WorketteTree.propTypes = {
  root: PropTypes.string.isRequired,
  leftWktActions: PropTypes.array,
  rightWktActions: PropTypes.array,
  parentSnapshot: PropTypes.bool,
  override_filters: PropTypes.object,
};

export default memo(WorketteTree);
