const config = {
  feature: {
    wall: true,
    productivity_insight: true,
    ritual_tracking: true,
    daily_summary: false,
    weekly_summary: true,
  },
};

export default config;
