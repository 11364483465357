import { memo } from "react";
import PropTypes from "prop-types";
import { Button, Modal } from "react-bootstrap";

const SubscriptionModal = ({ show = false, body = <></>, cancelFunc, confirmFunc }) => {
  return (
    <Modal show={show} backdrop="static" keyboard={false}>
      <Modal.Body>{body}</Modal.Body>
      <Modal.Footer>
        <Button variant="default" onClick={cancelFunc}>
          Cancel
        </Button>
        <Button variant="primary" onClick={confirmFunc}>
          Confirm
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

SubscriptionModal.propTypes = {
  show: PropTypes.bool,
  body: PropTypes.node,
  cancelFunc: PropTypes.func.isRequired,
  confirmFunc: PropTypes.func.isRequired,
};

export default memo(SubscriptionModal);
