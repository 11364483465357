import { memo, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import PropTypes from "prop-types";
import { faTrash } from "@fortawesome/pro-solid-svg-icons";

import { workette_actions as wact, check_frozen } from "@myca/shared-component";
import WktButton from "../WktButton";

const WktButtonDelete = ({ jid }) => {
  const items = useSelector(state => state.workette.items);
  const item = useSelector(state => jid && state.workette.items[jid]);
  const session = useSelector(state => state.session);

  const dispatch = useDispatch();
  const delete_workette = useCallback(
    (w_id, parent_id) => dispatch(wact.delete_workette(w_id, parent_id)),
    [dispatch],
  );

  const handleDelete = useCallback(() => {
    if (items[jid]) {
      delete_workette(jid, items[jid].parent);
    }
  }, [delete_workette, items, jid]);

  if (!item || (item?.context?.wtype !== "goal" && check_frozen(session))) {
    return <></>;
  }

  return (
    <WktButton
      data-testid={"wkt-delete-btn " + jid}
      icon={faTrash}
      onClick={handleDelete}
      tooltip="Delete item"
    />
  );
};

WktButtonDelete.propTypes = {
  jid: PropTypes.string.isRequired,
};

export default memo(WktButtonDelete);
