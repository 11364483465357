import { memo, useCallback, useState, useMemo } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { Dropdown } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown } from "@fortawesome/pro-solid-svg-icons";

import { workette_filters as w_filter } from "@myca/shared-component";

import WktButtons from "../../../wktbuttons";
function RightButtons({
  item,
  toggleShow,
  toggleExpand,
  showOn,
  rightWktActions,
  controls,
  purgeTree,
  wRowClass,
  withoutText,
  isFirstItem,
  setShowNoteFrame,
  setShowNoteForm,
  showAddForm,
  setShowAddForm,
  setExpanded,
  firstWkt,
  showHoverButtons = true,
  handleOnClickRecommendation,
}) {
  const cur_date = useSelector(state => state.session.cur_date);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const hoverButtons = useMemo(() => {
    let hoverButtons = ["add", "drill", "note"];

    if (item.children?.length > 0 || ["workset", "link", "note"].includes(item.context.wtype)) {
      hoverButtons = ["edit", ...hoverButtons];
    }

    return hoverButtons;
  }, [item.children?.length, item.context.wtype]);

  const generateIcons = useCallback(() => {
    let buttons = [...rightWktActions];

    // add complete if workset, link, or note
    if (["workset", "link", "note"].includes(item.context.wtype)) {
      buttons = ["complete", ...buttons];
    }

    // remove ritual and running if its a workset
    if (item.context.wtype === "workset") {
      buttons = buttons.filter(b => !["ritual", "running"].includes(b));
    }

    const wktNote = item?.context?.note?.replace(/(<([^>]+)>)/gi, "").trim();
    if (
      wktNote &&
      wktNote !== "" &&
      item?.context?.wtype !== "goal" &&
      item?.context?.wtype !== "link"
    ) {
      buttons = ["note", ...buttons];
    }

    // if item is not scheduled today, remove running button and complete button
    if (!w_filter.scheduled_now(item, cur_date)) {
      buttons = buttons.filter(b => !["running", "complete"].includes(b));
    }

    return buttons;
  }, [cur_date, item, rightWktActions]);

  const onToggleDropdown = isOpen => setIsDropdownOpen(isOpen);

  if (rightWktActions.length > 1 && wRowClass === "sm") {
    return (
      <>
        {rightWktActions.length > 1 && (
          <WktButtons
            item={item}
            buttons={rightWktActions.includes("focus") ? ["focus"] : []}
            controls={controls}
            purgeTree={purgeTree}
            showOn={showOn}
            toggleExpand={toggleExpand}
            toggleShow={toggleShow}
            wRowClass={wRowClass}
            withoutText={withoutText}
            isFirstItem={isFirstItem}
            setShowNoteFrame={setShowNoteFrame}
            setShowNoteForm={setShowNoteForm}
            showAddForm={showAddForm}
            setShowAddForm={setShowAddForm}
            setExpanded={setExpanded}
            firstWkt={firstWkt}
            handleOnClickRecommendation={handleOnClickRecommendation}
          />
        )}
        <Dropdown alignRight={true} onToggle={onToggleDropdown} show={isDropdownOpen}>
          <Dropdown.Toggle
            className="mini-menu fa-btn"
            data-testid={`right-icons-dropdown ${item.jid}`}
          >
            <FontAwesomeIcon icon={faCaretDown} />
          </Dropdown.Toggle>
          <Dropdown.Menu data-testid={`right-icons-menu ${item.jid}`}>
            <WktButtons
              item={item}
              buttons={generateIcons().filter(type => type !== "focus")}
              controls={controls}
              purgeTree={purgeTree}
              showOn={showOn}
              toggleExpand={toggleExpand}
              toggleShow={toggleShow}
              wRowClass={wRowClass}
              isDropDown={true}
              setShowNoteFrame={setShowNoteFrame}
              setShowNoteForm={setShowNoteForm}
              showAddForm={showAddForm}
              setShowAddForm={setShowAddForm}
              setExpanded={setExpanded}
              firstWkt={firstWkt}
              handleOnClickRecommendation={handleOnClickRecommendation}
            />
            {showHoverButtons && (
              <div className="hover-buttons">
                <Dropdown.Divider />
                <WktButtons
                  item={item}
                  buttons={hoverButtons}
                  controls={controls}
                  purgeTree={purgeTree}
                  showOn={showOn}
                  toggleExpand={toggleExpand}
                  toggleShow={toggleShow}
                  wRowClass={wRowClass}
                  isDropDown={true}
                  setShowNoteFrame={setShowNoteFrame}
                  setShowNoteForm={setShowNoteForm}
                  showAddForm={showAddForm}
                  setShowAddForm={setShowAddForm}
                  setExpanded={setExpanded}
                  firstWkt={firstWkt}
                  handleOnClickRecommendation={handleOnClickRecommendation}
                />
              </div>
            )}
          </Dropdown.Menu>
        </Dropdown>
      </>
    );
  } else {
    return (
      <>
        <WktButtons
          item={item}
          buttons={generateIcons()}
          controls={controls}
          purgeTree={purgeTree}
          showOn={showOn}
          toggleExpand={toggleExpand}
          toggleShow={toggleShow}
          withoutText={withoutText}
          isFirstItem={isFirstItem}
          setShowNoteFrame={setShowNoteFrame}
          setShowNoteForm={setShowNoteForm}
          showAddForm={showAddForm}
          setShowAddForm={setShowAddForm}
          setExpanded={setExpanded}
          firstWkt={firstWkt}
          handleOnClickRecommendation={handleOnClickRecommendation}
        />
        {showHoverButtons && (
          <div className="hover-buttons">
            <WktButtons
              item={item}
              buttons={hoverButtons}
              controls={controls}
              purgeTree={purgeTree}
              showOn={showOn}
              toggleExpand={toggleExpand}
              toggleShow={toggleShow}
              wRowClass={wRowClass}
              isDropDown={true}
              setShowNoteFrame={setShowNoteFrame}
              showAddForm={showAddForm}
              setShowAddForm={setShowAddForm}
              setShowNoteForm={setShowNoteForm}
              setExpanded={setExpanded}
              firstWkt={firstWkt}
              handleOnClickRecommendation={handleOnClickRecommendation}
            />
          </div>
        )}
      </>
    );
  }
}

RightButtons.propTypes = {
  item: PropTypes.object.isRequired,
  toggleShow: PropTypes.func,
  toggleExpand: PropTypes.func,
  showOn: PropTypes.bool,
  rightWktActions: PropTypes.array.isRequired,
  controls: PropTypes.object,
  purgeTree: PropTypes.func,
  wRowClass: PropTypes.string,
  withoutText: PropTypes.bool,
  isFirstItem: PropTypes.bool,
  setShowNoteFrame: PropTypes.func,
  setShowNoteForm: PropTypes.func,
  showAddForm: PropTypes.bool,
  setShowAddForm: PropTypes.func,
  setExpanded: PropTypes.func,
  firstWkt: PropTypes.string,
  showHoverButtons: PropTypes.bool,
  handleOnClickRecommendation: PropTypes.func,
};

export default memo(RightButtons);
