import PlanFeature from "./FeatureItem";
import PropTypes from "prop-types";

import "./Features.scss";

const FEATURES = {
  envision: [
    "Weekly/Monthly/Yearly goal setting",
    "AI-enabled automatic goal-task association",
    "Automatic goal progress tracking",
  ],
  improve: [
    "Productivity insights",
    "Where you dedicated your time  and what you overlooked",
    "Weekly/monthly ritual tracking and streaks",
    "Life highlights and daily summary",
  ],
};

const Features = ({ headerText = "", subHeaderText = "" }) => {
  return (
    <div className="features">
      <header>
        {headerText && <h1>{headerText}</h1>}
        {subHeaderText && <h2>{subHeaderText}</h2>}
      </header>
      <PlanFeature title="Envision" features={FEATURES.envision} />
      <PlanFeature title="Improve" features={FEATURES.improve} />
    </div>
  );
};

Features.propTypes = {
  headerText: PropTypes.string,
  subHeaderText: PropTypes.string,
};

export default Features;
