import { lazy, Suspense, useCallback, useContext } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Tab, Tabs } from "react-bootstrap";

import { reflect_actions as refl } from "@myca/shared-component";
import featuresConfig from "../../config/features_config";
import MycaLoader from "../MycaLoader";

import "./Reflect.scss";
import { AppContext } from "src/AppContext";
import SubscriptionLock from "../SubscriptionLock";
import myca_wall_webp from "src/assets/myca_wall.webp";
import ritual_tracking_webp from "src/assets/ritual_tracking_onboarding.webp";
import insights_webp from "src/assets/insights.webp";

const ProductivityInsight = lazy(() => import("./ProductivityInsight"));
const RitualTracking = lazy(() => import("./RitualTracking"));
const WallTab = lazy(() => import("./Wall"));

const { wall, productivity_insight, ritual_tracking } = featuresConfig.feature;

const Reflect = () => {
  const { isNonSubscriber } = useContext(AppContext);
  const { days } = useSelector(state => state.workette);
  const { tab_key } = useSelector(state => state.reflect);

  const dispatch = useDispatch();
  const set_reflect_tab = useCallback(key => dispatch(refl.set_reflect_tab(key)), [dispatch]);

  const handleOnSelectTab = tab_key => {
    set_reflect_tab(tab_key);
  };

  if (Object.keys(days).length === 0) {
    return <MycaLoader />;
  }

  const renderLockContent = () => {
    if (tab_key === "productivity-insight") {
      return {
        text: (
          <>
            <p>
              To achieve personal best, it is incredibly important to learn from your past. Are you
              being productive recently? How have you been allocating your time?
            </p>
            <p>Use myca for a few days to see answers to those questions</p>
          </>
        ),
        image: insights_webp,
      };
    }

    if (tab_key === "ritual-tracking") {
      return {
        text: (
          <>
            <p>
              The key to forming good habits is to make them part of your rituals. On average, it
              takes 66 days for a new behavior to become automatic.
            </p>
            <p>See how well you're doing in creating the behaviors you want.</p>
            <p>To add rituals, go to Plan page.</p>
          </>
        ),
        image: ritual_tracking_webp,
      };
    }

    if (tab_key === "wall") {
      return {
        text: (
          <>
            <p>
              Myca wall helps you revisit your journey and enjoy your life highlights. Seeing how
              far you have come motivates you for your future journey.
            </p>
            <p>To add to the myca wall, select your highlights during “certify day” process</p>
          </>
        ),
        image: myca_wall_webp,
      };
    }
  };

  return (
    <>
      {isNonSubscriber && (
        <SubscriptionLock bodyText={renderLockContent().text} img={renderLockContent().image} />
      )}
      <section className="improve-section myca-tab" data-testid="reflect-page-container">
        <Tabs activeKey={tab_key} onSelect={handleOnSelectTab}>
          {productivity_insight && (
            <Tab eventKey="productivity-insight" title="Productivity Insights">
              {tab_key === "productivity-insight" && (
                <Suspense fallback={<MycaLoader />}>
                  <ProductivityInsight />
                </Suspense>
              )}
            </Tab>
          )}
          {ritual_tracking && (
            <Tab eventKey="ritual-tracking" title="Ritual Tracking (Beta)">
              {tab_key === "ritual-tracking" && (
                <Suspense fallback={<MycaLoader />}>
                  <RitualTracking />
                </Suspense>
              )}
            </Tab>
          )}
          {wall && (
            <Tab eventKey="wall" title="Myca Wall">
              {tab_key === "wall" && (
                <Suspense fallback={<MycaLoader />}>
                  <WallTab />
                </Suspense>
              )}
            </Tab>
          )}
        </Tabs>
      </section>
    </>
  );
};

export default Reflect;
