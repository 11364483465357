import { useCallback } from "react";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fal } from "@fortawesome/pro-light-svg-icons";
import { fas } from "@fortawesome/pro-solid-svg-icons";
import { fad } from "@fortawesome/pro-duotone-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Tooltip from "react-simple-tooltip";
import PropTypes from "prop-types";

import "./WktButton.scss";

// This exports the whole icon packs for Light and Solid.
library.add(fal, fas, fad);

// Takes icon, status (true/false) of active or not, tooltip, onClick function
const WktButton = ({
  icon,
  onClick,
  label,
  status,
  tooltip,
  className,
  isFAButton = true,
  active,
  iconName,
  iconType = "fal",
  tooltipPlacement = "top",
  ...rest
}) => {
  // if status is true, use "fas" otherwise use the iconType props
  const getIconType = useCallback(() => {
    if (status) {
      return "fas";
    } else {
      const iconTypes = ["fal", "fas", "fad"];
      return iconTypes.includes(iconType) ? iconType : "fal"; // if iconType is not valid type, use "fal"
    }
  }, [iconType, status]);

  const generateButton = useCallback(
    () =>
      icon || iconName ? (
        <span>
          <FontAwesomeIcon
            icon={iconName ? [getIconType(), iconName] : icon}
            className={status ? "active" : ""}
            size="lg"
          />
        </span>
      ) : (
        <button type="button" className="btn btn-success shadow border border-dark">
          {label}
        </button>
      ),
    [getIconType, icon, iconName, label, status],
  );

  return (
    <span
      className={`wkt-buttons ${isFAButton ? "fa-btn " : ""}${className || ""}`}
      onClick={onClick}
      {...rest}
    >
      {tooltip ? (
        <Tooltip
          padding={10}
          fontSize="11px"
          arrow={4}
          background="#000000C0"
          radius={7}
          content={<span style={{ whiteSpace: "nowrap" }}>{tooltip}</span>}
          placement={tooltipPlacement}
        >
          {generateButton()}
          {label && <span className="label">{label}</span>}
        </Tooltip>
      ) : (
        <>
          {generateButton()}
          <span className="label">{label}</span>
        </>
      )}
    </span>
  );
};

WktButton.defaultProps = {
  tooltip: "",
};

WktButton.propTypes = {
  icon: PropTypes.object,
  onClick: PropTypes.func.isRequired,
  label: PropTypes.string,
  status: PropTypes.bool,
  tooltip: PropTypes.string,
  className: PropTypes.string,
  isFAButton: PropTypes.bool,
  active: PropTypes.bool,
  iconName: PropTypes.string,
  iconType: PropTypes.string,
  tooltipPlacement: PropTypes.oneOf(["left", "top", "right", "bottom"]),
};

export default WktButton;
