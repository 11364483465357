import { forwardRef } from "react";
import { FormControl } from "react-bootstrap";
import PropTypes from "prop-types";
import { useItemSearch } from "../../../utils/useItemSearch";

/**
 * Shows a search input that returns the result of a fuzzy searching of the entered query.
 */
const SearchInput = forwardRef(
  ({ items, searchNotes, className, onResultsChange, onQueryClear }, ref) => {
    const { searchItems } = useItemSearch(items, searchNotes);

    const handleQueryChange = event => {
      const query = event.target.value;

      if (query.length === 0) {
        onQueryClear();
      } else {
        const results = searchItems(query);

        onResultsChange(results);
      }
    };

    return (
      <FormControl
        data-testid="search-input"
        type="search"
        placeholder="Search"
        className={`w-50 ${className}`}
        onChange={handleQueryChange}
        ref={ref}
      />
    );
  },
);

SearchInput.displayName = "SearchInput";

SearchInput.defaultProps = {
  searchNotes: false,
  onResultsChange: () => null,
  onQueryClear: () => null,
};

SearchInput.propTypes = {
  /**
   * The array of items to search from.
   */
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  /**
   * When set to `true`, `note` properties of the items are also searched. The default is `false`.
   */
  searchNotes: PropTypes.bool,
  /**
   * Called when the search results change.
   */
  onResultsChange: PropTypes.func,
  /**
   * Called when the query is empty.
   */
  onQueryClear: PropTypes.func,
  className: PropTypes.string,
};

export { SearchInput };
