import { memo } from "react";
import PropTypes from "prop-types";

const SubscriptionFooter = ({
  currencySymbol = "",
  currentSelectedPlan,
  setShowCancelDialog,
  showFreeTrialText = false,
  showCancelButton = false,
}) => {
  return (
    <footer data-testid="subscription-footer">
      <p>
        {showFreeTrialText ? "After your free trial, y" : "Y"}
        ou'll auto-enroll in our {currencySymbol}
        {currentSelectedPlan?.amount} / {currentSelectedPlan?.interval} plan.
      </p>
      <p>
        Change or{" "}
        {showCancelButton ? (
          <span
            onClick={() => setShowCancelDialog && setShowCancelDialog(true)}
            data-testid="cancel-subscription-button"
          >
            cancel anytime
          </span>
        ) : (
          "cancel anytime"
        )}{" "}
        during your trial to not be charged.
      </p>
    </footer>
  );
};

SubscriptionFooter.propTypes = {
  currencySymbol: PropTypes.string.isRequired,
  currentSelectedPlan: PropTypes.object,
  setShowCancelDialog: PropTypes.func,
  showFreeTrialText: PropTypes.bool,
  showCancelButton: PropTypes.bool,
};

export default memo(SubscriptionFooter);
