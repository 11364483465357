import { useRef, useState, memo } from "react";
import PropTypes from "prop-types";
import { Modal } from "react-bootstrap";
import { SearchInput } from "../SearchInput/SearchInput";
import { useDispatch, useSelector } from "react-redux";
import {
  selectCurrentlySelectedDayNode,
  selectDeepChildItemsOfNode,
  session_actions,
} from "@myca/shared-component";

import { ResultItem } from "../ResultItem/ResultItem";
import { SearchModalNoteMatch } from "./SearchModalNoteMatch";

import "./SearchModal.scss";

const SearchModal = memo(({ show, onHide }) => {
  const dispatch = useDispatch();
  const dayNode = useSelector(selectCurrentlySelectedDayNode);
  const items = useSelector(state => selectDeepChildItemsOfNode(state, dayNode.jid));
  const [searchResults, setSearchResults] = useState();
  const searchInputRef = useRef(null);

  const handleHide = () => {
    setSearchResults(undefined);
    onHide();
  };

  const handleShow = () => {
    searchInputRef.current && searchInputRef.current.focus();
  };

  const handleQueryClear = () => {
    setSearchResults(undefined);
  };

  const handleResultsChange = results => {
    setSearchResults(results);
  };

  const handleResultClick = itemId => {
    dispatch(session_actions.set_selected_wkt(itemId));
  };

  const renderMessage = message => {
    return <div className="h5 text-muted text-center mt-5">{message}</div>;
  };

  const renderSearchResults = () => {
    if (searchResults == null) {
      return renderMessage("Enter a query to search.");
    }

    if (searchResults.length === 0) {
      return renderMessage("There are no results.");
    }

    return searchResults.map(result => (
      <ResultItem
        item={result.item}
        onClick={() => handleResultClick(result.item.jid)}
        showBreadcrumbs
        key={result.item.jid}
      >
        {result.noteMatches != null && (
          <div className="mt-3">
            <h1 className="h6">Note Matches:</h1>
            <SearchModalNoteMatch noteMatches={result.noteMatches} itemId={result.item.jid} />
          </div>
        )}
      </ResultItem>
    ));
  };

  return (
    <Modal
      show={show}
      onExit={handleHide}
      onHide={handleHide}
      onShow={handleShow}
      size="lg"
      centered
      scrollable
      data-testid="search-modal"
    >
      <Modal.Header>
        <SearchInput
          items={items}
          onResultsChange={handleResultsChange}
          ref={searchInputRef}
          onQueryClear={handleQueryClear}
          searchNotes
          className="w-100"
        />
      </Modal.Header>
      <Modal.Body className="search-modal__body" data-testid="search-modal-body">
        {renderSearchResults()}
      </Modal.Body>
    </Modal>
  );
});

SearchModal.defaultProps = {
  onHide: () => null,
};

SearchModal.propTypes = {
  /**
   * When set to `true`, the modal is shown.
   */
  show: PropTypes.bool,
  /**
   * Called when the user requests to hide the modal.
   */
  onHide: PropTypes.func,
};

SearchModal.displayName = "SearchModal";

export { SearchModal };
