import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { useHistory } from "react-router-dom";
import jwt from "jsonwebtoken";

import {
  REACT_APP_GOOGLE_CLIENT_ID,
  REACT_APP_GOOGLE_CLIENT_SECRET,
  workette_actions as wact,
  selectCurrentlySelectedLifeNode,
  calendar_actions as cact,
} from "@myca/shared-component";

const client_id = REACT_APP_GOOGLE_CLIENT_ID;
const scope =
  "https://www.googleapis.com/auth/calendar.events https://www.googleapis.com/auth/calendar.readonly";
const redirect_uri = `${window.location.origin}/perform`; // Ensure it matches the one used in the initial request

const useFetchCalendar = () => {
  const history = useHistory();

  const [tokenClient, setTokenClient] = useState(null);
  const [currentDate, setCurrentDate] = useState(new Date());
  const lifeNode = useSelector(selectCurrentlySelectedLifeNode);
  const { calendars, errors: calendarErrors, new_token } = useSelector(state => state.calendar);
  const calendar_accounts = useMemo(() => lifeNode.context?.settings?.calendars || [], [lifeNode]);

  const dispatch = useDispatch();
  const set_workette = useCallback(
    (w_id, ctx) => dispatch(wact.set_workette(w_id, ctx, false, true)),
    [dispatch],
  );
  const remove_calendar = useCallback(email => dispatch(cact.remove_calendar(email)), [dispatch]);
  const clear_calendar_error = useCallback(() => dispatch(cact.clear_error()), [dispatch]);
  const clear_new_token = useCallback(() => dispatch(cact.clear_new_token()), [dispatch]);
  const get_calendars = calendars => cact.get_calendars(calendars);
  const toggleCalendarEvent = (id, is_selected, email) => {
    cact.toggleCalendarEvent(id, is_selected, email);
  };
  const fetch_events = (timeMin, timeMax) => cact.fetch_events(timeMin, timeMax);
  const refreshAccessToken = refresh_token => cact.refreshAccessToken(refresh_token);

  const setCalendarToken = async newToken => {
    const settings = lifeNode?.context?.settings;
    const payload = jwt.decode(newToken.id_token);
    const { email } = payload;

    // move the calendar object value into context.calendars and remove it, for compatibility with the new version
    if (lifeNode && lifeNode?.context?.settings?.calendar) {
      const old_refresh_token = lifeNode?.context?.settings?.calendar?.refresh_token;
      delete settings.calendar;

      set_workette(lifeNode?.jid, {
        settings: {
          ...settings,
          calendars: [
            {
              email,
              access_token: newToken.access_token,
              refresh_token: newToken.refresh_token || old_refresh_token,
              expires_in: new Date(new Date().getTime() + newToken.expires_in * 1000),
              selected_calendars: [email],
            },
          ],
        },
      });

      return;
    }

    let updatedCalendarAccounts = [];

    const existingAccounts = calendar_accounts.find(account => account.email === email);
    if (existingAccounts) {
      updatedCalendarAccounts = calendar_accounts.map(account => {
        if (account.email === email) {
          return {
            ...account,
            access_token: newToken.access_token,
            refresh_token: newToken?.refresh_token || account.refresh_token,
            expires_in: new Date(new Date().getTime() + newToken.expires_in * 1000),
          };
        }

        return account;
      });
    } else {
      updatedCalendarAccounts = [
        ...calendar_accounts,
        {
          email,
          access_token: newToken.access_token,
          refresh_token: newToken.refresh_token,
          expires_in: new Date(new Date().getTime() + newToken.expires_in * 1000),
          selected_calendars: [email],
        },
      ];
    }

    set_workette(lifeNode?.jid, {
      settings: {
        ...settings,
        calendars: updatedCalendarAccounts,
      },
    });

    if (new_token) {
      // clear the new token after updating the calendars access tokens
      clear_new_token();

      // if there is a 401 error, call the last action made and clear the calendar error
      if (calendarErrors?.response?.status === 401 && calendarErrors?.last_action) {
        cact[calendarErrors?.last_action]();
        clear_calendar_error();
      }
    }

    const code = new URLSearchParams(history?.location?.search)?.get("code");
    if (code) {
      get_calendars(updatedCalendarAccounts);
      history.replace(window.location.pathname);
    }
  };

  const removeCalendarAccount = email => {
    const updatedCalendarAccounts = calendar_accounts.filter(account => account.email !== email);

    const settings = lifeNode?.context?.settings;
    remove_calendar(email);
    set_workette(lifeNode?.jid, {
      settings: {
        ...settings,
        calendars: updatedCalendarAccounts,
      },
    });
  };

  const getToken = async authorizationCode => {
    const tokenEndpoint = "https://oauth2.googleapis.com/token";

    const requestBody = {
      code: authorizationCode,
      client_id,
      client_secret: REACT_APP_GOOGLE_CLIENT_SECRET,
      redirect_uri,
      grant_type: "authorization_code",
    };

    try {
      const response = await axios.post(tokenEndpoint, new URLSearchParams(requestBody), {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      });

      if (response.data.access_token) {
        setCalendarToken(response.data);
      }
    } catch (error) {}
  };

  const updateEventDateTime = async newData => {
    const calendarId = newData.event.extendedProps?.calendarId;
    const eventId = newData.event.extendedProps?.id;
    const newStartDateTime = newData.event.startStr;
    const newEndDateTime = newData.event.endStr;
    const calendarAccountEmail = newData.event.extendedProps?.calendarAccountEmail;

    try {
      const accessToken =
        calendarAccountEmail &&
        lifeNode?.context?.settings?.calendars?.find(
          account => account.email === calendarAccountEmail,
        )?.access_token;

      if (!accessToken) {
        return;
      }

      await axios.patch(
        `https://www.googleapis.com/calendar/v3/calendars/${calendarId}/events/${eventId}`,
        {
          start: {
            dateTime: newStartDateTime,
            timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          },
          end: {
            dateTime: newEndDateTime,
            timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          },
        },
        {
          headers: { Authorization: `Bearer ${accessToken}` },
        },
      );
    } catch (error) {}
  };

  useEffect(() => {
    const googleInit = async () => {
      window.google?.accounts?.oauth2?.initCodeClient &&
        setTokenClient(
          window.google.accounts.oauth2.initCodeClient({
            client_id,
            scope,
            redirect_uri,
            ux_mode: "redirect",
          }),
        );
    };

    if (window.google) {
      googleInit();
    }
  }, []);

  const handleRefreshToken = () => {
    calendar_accounts.forEach(account => {
      const isAccessExpired = new Date(account.expires_in) < new Date();

      if (isAccessExpired) {
        // if expired, get new access_token
        refreshAccessToken(account.refresh_token);
        return;
      }
    });
  };

  useEffect(() => {
    try {
      // lifenode settings cleanup, removed unused properties in settings
      if (
        lifeNode &&
        (lifeNode?.context?.settings?.calendar_accounts ||
          lifeNode?.context?.settings?.current_calendar)
      ) {
        const updatedSettings = JSON.parse(JSON.stringify(lifeNode?.context?.settings));

        delete updatedSettings.calendar_accounts;
        if (updatedSettings.current_calendar) {
          delete updatedSettings.current_calendar;
        }

        set_workette(lifeNode?.jid, {
          settings: updatedSettings,
        });
      }
    } catch (error) {}
  }, [lifeNode]);

  return {
    tokenClient,
    handleRefreshToken,
    getToken,
    removeCalendarAccount,
    calendars,
    currentDate,
    setCurrentDate,
    toggleCalendarEvent,
    calendar_accounts,
    get_calendars,
    updateEventDateTime,
    fetch_events,
    setCalendarToken,
  };
};

export default useFetchCalendar;
