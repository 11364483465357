import { useEffect, useState, useCallback, memo, useRef, useLayoutEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { Collapse } from "react-bootstrap";
import { faEllipsisH } from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Tooltip from "react-simple-tooltip";
import Skeleton from "react-loading-skeleton";

import {
  workette_actions as wact,
  onboarding_actions as onb_act,
  randColor,
  logTour,
  get_meta,
} from "@myca/shared-component";

import { Input, InputEmoji } from "../../../utils/utils";

import "./WktAddForm.scss";
import LinkPreview from "src/components/link-preview";
import WktAddModal from "./WktAddModal";

/**
 * Add Workettes Button & Modal Form
 * @param {string} w_id active workette for this form
 * @param {boolean} bypass_expand pass in true to skip in line form
 * @param {object} hide_type hide option to change the type of item
 * @param {object} context_override replaces context contents with object
 * @param {string} extra_class add extra className to the form
 * @param {boolean} isFirstItem
 * @param {} hide_more_options hide option for WktAddModal
 * @param {} setAddFormHeight
 * @param {boolean} isOpen Controller for the Collapse
 */
const WktAddForm = ({
  w_id,
  bypass_expand,
  hide_type,
  context_override,
  extra_class = "",
  isFirstItem,
  hide_more_options,
  setAddFormHeight,
  isOpen = false,
}) => {
  const formRef = useRef();
  //state vars for create workette context
  const { session, workette } = useSelector(state => state);

  const lifeID = useSelector(state => state.workette.life);
  const life = useSelector(state => state.workette.items[lifeID["life"]]);
  const { add_focus, add_item } = useSelector(state => state.onboarding);

  const itemParent = useSelector(state => state.workette.items[w_id]);

  const dispatch = useDispatch();
  const set_workette = useCallback(
    (w_id, ctx) => dispatch(wact.set_workette(w_id, ctx)),
    [dispatch],
  );
  const create = useCallback(
    (w_id, title) => dispatch(wact.create_workette(w_id, title)),
    [dispatch],
  );

  const [title, setTitle] = useState("");
  const [wtype, setWtype] = useState(context_override?.wtype || "workette");
  const [note, setNote] = useState(""); // used for link url property

  const [fetchingLinkTitle, setFetchingLinkTitle] = useState(false);
  const [linkMetadata, setLinkMetadata] = useState(null);
  //state vars for visual control
  const [showAddModal, setShowAddModal] = useState(false);

  const [inputTimeout, setInputTimeout] = useState(null);

  const titleInput = useRef();

  useEffect(() => {
    setWtype(context_override?.wtype || "workette");
  }, [context_override?.wtype]);

  useEffect(() => {
    if (add_item?.running && add_item?.current_step === 1) {
      setWtype("workset");
    }

    if (add_item?.running && add_item?.current_step === 3) {
      setWtype("workette");
    }
  }, [add_item?.current_step, add_item?.running]);
  // Resets title and notes
  const handleClose = () => {
    setTitle("");
    setNote("");
    setWtype("workette");
  };

  const updateStepAddItemOnboarding = useCallback(
    step => dispatch(onb_act.add_item.next_or_prev(step)),
    [dispatch],
  );
  const restartAddItemOnboarding = useCallback(
    () => dispatch(onb_act.add_item.restart()),
    [dispatch],
  );
  const restartAddFocusOnboarding = useCallback(
    () => dispatch(onb_act.add_focus.restart()),
    [dispatch],
  );

  const getUrlMetadata = useCallback(
    async url => {
      try {
        setFetchingLinkTitle(true);

        const { error, og } = await get_meta(url, session.token);

        if (error) {
          setFetchingLinkTitle(false);
          setLinkMetadata(null);
          return;
        }

        setTitle(og.title);
        setLinkMetadata(og);
        setFetchingLinkTitle(false);
      } catch (error) {}

      setFetchingLinkTitle(false);
    },
    [session.token],
  );

  // Handle Enter to submit
  const onKeyUp = e => {
    if (e.key === "Enter") {
      handleSubmit(e);
    }
  };

  // Create item logic
  const handleSubmit = useCallback(
    e => {
      e.preventDefault();

      let context = {
        title,
        wtype,
        note,
        is_MIT: context_override?.is_MIT,
      };

      // If workset, add a random color
      if (wtype === "workset") {
        context = { ...context, color: randColor() };
      }

      if (itemParent?.associated_to_goals?.length > 0) {
        context = { ...context, goals: itemParent?.associated_to_goals };
      }

      create(w_id, context);

      // ADD ITEM ONBOARDING
      if (add_item?.running && add_item?.current_step === 2 && wtype === "workset") {
        updateStepAddItemOnboarding(3);
      }

      // ADD ITEM ONBOARDING
      if (add_item?.running && add_item?.current_step === 4 && wtype === "workette") {
        restartAddItemOnboarding();
      }

      // ADD FOCUS ONBOARDING
      if (add_focus?.running && add_focus?.current_step === 1) {
        restartAddFocusOnboarding();
      }

      // log the first time user created a GROUP
      if (wtype === "workset" && !life?.context?.settings?.tours.includes("CreatedGroup")) {
        logTour(lifeID, life, set_workette, "CreatedGroup");
      }

      // log the first time user created a TASK
      if (wtype === "workette" && !life?.context?.settings?.tours.includes("CreatedTask")) {
        logTour(lifeID, life, set_workette, "CreatedTask");
      }

      // log the first time user created a FOCUS ITEM
      if (
        context_override?.is_MIT &&
        !life?.context?.settings?.tours.includes("CreatedFocusItem")
      ) {
        logTour(lifeID, life, set_workette, "CreatedFocusItem");
      }

      // Reset some fields to default values, but not wtype for fast entry
      setTitle("");
      setNote("");
      setLinkMetadata(null);
    },
    [
      add_focus?.current_step,
      add_focus?.running,
      add_item?.current_step,
      add_item?.running,
      context_override?.is_MIT,
      life,
      lifeID,
      note,
      title,
      w_id,
      wtype,
    ],
  );

  const handleChange = e => {
    if (e.target) {
      if (e.target.name === "add") {
        setTitle(e.target.value);
      } else if (e.target.name === "wtype") {
        setWtype(e.target.value);

        // If its not a link, refocus the name field and clear any link data in state
        if (e.target.value !== "link") {
          titleInput?.current?.textareaRef?.focus();
          setLinkMetadata(null);
        }
      } else if (e.target.name === "link") {
        setNote(e.target.value);
        clearTimeout(inputTimeout);

        if (e.target.value !== "") {
          setInputTimeout(
            setTimeout(() => {
              getUrlMetadata(e.target.value);
            }, 500),
          );
        } else {
          setLinkMetadata(null);
        }
      }
    }
  };

  useLayoutEffect(() => {
    setAddFormHeight && setAddFormHeight(formRef?.current?.clientHeight + 8 || 0);
  }, [formRef, setAddFormHeight, wtype, linkMetadata, fetchingLinkTitle]);

  return (
    <>
      <Collapse
        in={isOpen}
        onEntered={() => titleInput?.current?.textareaRef?.focus()}
        onExited={handleClose}
        unmountOnExit
        mountOnEnter
      >
        <div style={{ width: "100%", display: "flex" }}>
          <form
            onSubmit={handleSubmit}
            className={`${bypass_expand ? "" : "inline-add-form "} ${extra_class}`}
            ref={formRef}
          >
            <>
              <div className={"title-input "}>
                {wtype === "link" && (
                  <Input
                    autoFocus={wtype === "link"}
                    name="link"
                    value={note}
                    onChange={handleChange}
                    onKeyPress={onKeyUp}
                    placeholder="Link URL"
                    autoComplete="off"
                    extra_class={
                      workette.items[workette.life["life"]]?.context?.settings?.mode === "compact"
                        ? "form-control-compact"
                        : "form-control-comfortable"
                    }
                    disabled={fetchingLinkTitle}
                  />
                )}
                <InputEmoji
                  ref={titleInput}
                  value={title}
                  onChange={handleChange}
                  onKeyPress={onKeyUp}
                  placeholder={`${wtype === "link" ? "Name (Optional)" : "Name"}`}
                  name="add"
                  description="Press enter to add."
                  autoComplete="off"
                  extra_class={`add-form-input ${
                    workette.items[workette.life["life"]]?.context?.settings?.mode === "compact"
                      ? "form-control-compact"
                      : "form-control-comfortable"
                  }`}
                  data-testid={`wkt-add-form ${w_id}`}
                  disabled={fetchingLinkTitle}
                />

                {wtype === "link" && fetchingLinkTitle ? (
                  <>
                    <Skeleton width="435px" height="305px" />
                  </>
                ) : (
                  linkMetadata && (
                    <LinkPreview
                      title={title}
                      linkMetadata={linkMetadata}
                      width="300px"
                      height="180px"
                    />
                  )
                )}
              </div>
              {!hide_type && (
                <div className={"wtype-select form-group m-0 pb-1 pt-1 "}>
                  <select
                    className={
                      workette.items[workette.life["life"]]?.context?.settings?.mode === "compact"
                        ? "form-control-compact"
                        : "form-control-comfortable"
                    }
                    value={wtype}
                    onChange={handleChange}
                    name="wtype"
                    aria-describedby="inputdesc"
                    disabled={add_item?.running}
                  >
                    <option value="workette">Task</option>
                    <option value="workset">Group</option>
                    <option value="link">Link</option>
                    <option value="note">Note</option>
                  </select>
                  <small id="inputdesc" className="form-text text-muted">
                    Type
                  </small>
                </div>
              )}
              {!hide_more_options && (
                <div className="open-modal" onClick={() => setShowAddModal(true)}>
                  <Tooltip
                    padding={10}
                    fontSize="11px"
                    arrow={4}
                    content={<span style={{ whiteSpace: "nowrap" }}>Show More Options</span>}
                    background="#000000C0"
                    radius={7}
                  >
                    <FontAwesomeIcon icon={faEllipsisH} size="sm" />
                  </Tooltip>
                </div>
              )}
            </>
          </form>
        </div>
      </Collapse>
      {showAddModal && (
        <WktAddModal
          showAddModal={showAddModal}
          setShowAddModal={setShowAddModal}
          context_override={{ ...context_override, note, title, wtype }}
          w_id={w_id}
        />
      )}
    </>
  );
};

WktAddForm.propTypes = {
  w_id: PropTypes.string.isRequired,
  bypass_expand: PropTypes.bool,
  hide_type: PropTypes.bool,
  context_override: PropTypes.object,
  extra_class: PropTypes.string,
  isFirstItem: PropTypes.bool,
  hide_more_options: PropTypes.bool,
  setAddFormHeight: PropTypes.func,
  isOpen: PropTypes.bool,
};

export default memo(WktAddForm);
