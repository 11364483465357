import { useEffect, useState, useCallback, useContext } from "react";
import { Link, useParams } from "react-router-dom";

import { faArrowRight, faCheck, faTimes } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import { Spinner } from "react-bootstrap";

import { AppContext } from "src/AppContext";

import "./activation.scss";

const Activation = () => {
  const { isMobile } = useContext(AppContext);

  const { code } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [isVerified, setIsVerified] = useState(false);

  const verfyCode = useCallback(async () => {
    try {
      await axios.get(`${process.env.REACT_APP_JASECI_URL}user/activate/${code}`);
      setIsVerified(true);
    } catch (error) {
      setIsVerified(false);
    }
    setIsLoading(false);
  }, [code]);

  useEffect(() => {
    verfyCode();
  }, [verfyCode]);

  return (
    <div className="bg">
      <div className="activation-container">
        {isLoading ? (
          <Spinner animation="grow" />
        ) : (
          <>
            <span className={`circle ${isVerified ? "valid" : "invalid"}`}>
              <FontAwesomeIcon icon={isVerified ? faCheck : faTimes} />
            </span>
            <strong>{isVerified ? "Email Verified" : "Email not verified"}</strong>
            <p>
              {isVerified
                ? "Your email address was successfully verified"
                : "Your activation code is invalid"}
            </p>
            {isMobile ? (
              <a href="aimyca://">
                back to myca <FontAwesomeIcon icon={faArrowRight} />
              </a>
            ) : (
              <Link to="/login">
                back to myca <FontAwesomeIcon icon={faArrowRight} />
              </Link>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default Activation;
