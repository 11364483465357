import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/pro-solid-svg-icons";

const StripeLoader = () => {
  return (
    <div className="stripe-loading">
      <div className="d-flex flex-column gap-2 align-center">
        <FontAwesomeIcon icon={faSpinner} spin />
        Please wait...
      </div>
    </div>
  );
};

export default StripeLoader;
