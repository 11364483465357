import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/pro-solid-svg-icons";
import axios from "axios";
import { useSelector } from "react-redux";

import { REACT_APP_JASECI_URL } from "@myca/shared-component";

import "./BillingIssue.scss";
import { Spinner } from "react-bootstrap";

const BillingIssue = ({ plan, MOBILE_SUBSCRIPTION = false }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [invoiceLink, setInvoiceLink] = useState("");
  const { token } = useSelector(state => state.session);

  const getCurrentSubscriptionInvoices = async () => {
    try {
      const result = await axios.post(
        `${REACT_APP_JASECI_URL}js/walker_run`,
        {
          name: "get_current_subscription_invoices",
          ctx: {},
        },
        { headers: { Authorization: "token " + token } },
      );

      const invoices = result?.data?.report[0]?.data;
      const unpaidInvoice = invoices?.find(invoice => invoice.status === "open");

      if (unpaidInvoice) {
        setInvoiceLink(unpaidInvoice.hosted_invoice_url);
      }

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getCurrentSubscriptionInvoices();
  }, []);

  return (
    <div
      className="d-flex flex-column gap-4 billing-issue-wrapper"
      data-testid="billing-issue-display"
    >
      {isLoading ? (
        <div className="loading-wrapper">
          <Spinner animation="grow" size="lg" />
        </div>
      ) : (
        <>
          <div className="plan active">
            <div className="d-flex gap-4 align-items-center">
              <div className="description">
                <strong>{plan?.description || ""}</strong>
                <span>{plan?.amount_description || 0}</span>
              </div>
              <aside>
                <div className="extra">CURRENT PLAN</div>
              </aside>
            </div>
          </div>
          <div className="issue-description-wrapper">
            <FontAwesomeIcon icon={faExclamationTriangle} />
            <strong>
              <p>
                We're sorry, but there seems to be a billing issue with your subscription.To ensure
                uninterrupted access to the app and all its premium features,{" "}
                {!MOBILE_SUBSCRIPTION ? (
                  <>
                    <span>please </span>
                    <a href={invoiceLink}>click here to update your payment information</a>{" "}
                    <span>and resolve the issue.</span>
                  </>
                ) : (
                  "we kindly ask you to update your payment details within our mobile app"
                )}
              </p>
              <p>
                If you need assistance, please contact our support team at{" "}
                <a href="mailto:support@lifelogify.com">support@lifelogify.com</a>. Thank you for
                your cooperation!
              </p>
            </strong>
          </div>
        </>
      )}
    </div>
  );
};

BillingIssue.propTypes = {
  plan: PropTypes.object.isRequired,
  MOBILE_SUBSCRIPTION: PropTypes.bool,
};

export default BillingIssue;
