import { memo } from "react";
import PropTypes from "prop-types";
import WktNoteForm from "../../../components/wkt-note";
import { motion } from "framer-motion";

import "./DailyJournal.scss";

const DailyJournal = ({ w_id }) => {
  return (
    <motion.div data-testid="daily_note" className="daily_note">
      <span>Add any thoughts or reflections for the day here</span>
      <WktNoteForm w_id={w_id} height="75vh" />
    </motion.div>
  );
};

DailyJournal.propTypes = {
  w_id: PropTypes.string.isRequired,
};

export default memo(DailyJournal);
