import { useEffect, useCallback, useState, useMemo } from "react";
import { Modal, Button } from "react-bootstrap";
import ReactMarkdown from "react-markdown";
import "./release-modal.scss";
import releaseNotes from "./release-notes.md";
import {
  workette_actions as wact,
  selectCurrentlySelectedLifeNode,
  is_today,
} from "@myca/shared-component";
import { useDispatch, useSelector } from "react-redux";
import { LL_VER } from "../utils/utils";

const RNotes = () => {
  const [release_text, setRelease_text] = useState("");

  useEffect(() => {
    fetch(releaseNotes)
      .then(response => response.text())
      .then(text => {
        setRelease_text(text.split("-----")[0]);
      });
  }, []);

  return (
    <div className="content">
      <ReactMarkdown source={release_text} />
    </div>
  );
};

const ReleaseModal = () => {
  const { cur_date, freeze_override } = useSelector(state => state.session);
  const days = useSelector(state => state.workette.days);
  const items = useSelector(state => state.workette.items);
  const lifeNode = useSelector(selectCurrentlySelectedLifeNode);

  const dispatch = useDispatch();
  const set_workette = useCallback(
    (w_id, ctx) => dispatch(wact.set_workette(w_id, ctx)),
    [dispatch],
  );

  const used_myca_count = useMemo(
    () => (lifeNode && lifeNode.context?.settings?.used_myca_count) || 0,
    [lifeNode],
  );

  const [show, setShow] = useState(false);

  useEffect(() => {
    const current = days[cur_date];
    if (current && items[current]) {
      const ll_ver = items[current].context.ll_version;

      if (LL_VER !== "development" && LL_VER) {
        if (!ll_ver) {
          set_workette(current, { ll_version: LL_VER });
        } else {
          if (
            ll_ver &&
            ll_ver !== LL_VER &&
            (is_today(cur_date) || freeze_override) &&
            used_myca_count > 0
          ) {
            setShow(true);
            set_workette(current, { ll_version: LL_VER });
          }
        }
      }
    }
  }, [cur_date, days, freeze_override, items, set_workette, used_myca_count]);

  return (
    <Modal
      centered
      show={show}
      onHide={() => setShow(false)}
      dialogClassName="release-modal"
      data-testid="release-modal"
    >
      <Modal.Header closeButton>
        <Modal.Title>New Features!</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <RNotes />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => setShow(false)}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ReleaseModal;
