import { forwardRef } from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/pro-duotone-svg-icons";
import { print_day_of_week, print_month_of_year } from "@myca/shared-component";

const TwoRowDate = forwardRef(({ onClick, date }, ref) => {
  return (
    <div onClick={onClick} ref={ref} className="twoRowDate" data-testid="twoRowDate">
      <div className="date-top-row">
        <div>{print_day_of_week(date.getDay())}</div>
        <div>
          <FontAwesomeIcon icon={faChevronDown} size="xs" />
        </div>
      </div>
      <div className="date-bottom-row">
        <div>
          {print_month_of_year(date.getMonth())} {date.getDate()}
        </div>
      </div>
    </div>
  );
});

TwoRowDate.propTypes = {
  onClick: PropTypes.func,
  date: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.instanceOf(Date).isRequired]),
};

TwoRowDate.displayName = "TwoRowDate";

export default TwoRowDate;
