import { useEffect, useState, useCallback, memo, useRef } from "react";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAlarmClock as fasAlarmClock } from "@fortawesome/pro-solid-svg-icons";
import { faAlarmClock as falAlarmClock } from "@fortawesome/pro-light-svg-icons";
import Tooltip from "react-simple-tooltip";
import PropTypes from "prop-types";

import CustomDateModal from "./CustomDateModal";

import {
  workette_actions as wact,
  session_actions as sact,
  workette_filters as w_filter,
  remove_date_offset,
  onboarding_actions as onb_act,
  print_day_of_week,
  print_month_of_year,
  check_frozen,
  is_item_frozen,
  generate_new_ritual_history,
} from "@myca/shared-component";
import "../WktButton/WktButton.scss";
import "./WktButtonSnooze.scss";

const WktButtonSnooze = ({ item, expanded, controls, isFirstItem = false }) => {
  const workette = useSelector(state => state.workette);
  const { items } = workette;
  const session = useSelector(state => state.session);
  const lifeID = useSelector(state => state.workette.life);
  const life = useSelector(state => state.workette.items[lifeID["life"]]);
  const snoozeOnboarding = useSelector(state => state.onboarding?.snooze);

  const dropdownRef = useRef(null);

  const [showCustomDateModal, setShowCustomDateModal] = useState(false);
  const [wktSnoozeDate, setWktSnoozeDate] = useState(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const dispatch = useDispatch();
  const set_workette = useCallback(
    (w_id, ctx) => dispatch(wact.set_workette(w_id, ctx)),
    [dispatch],
  );
  const set_push_alert = useCallback(alert => dispatch(sact.set_push_alert(alert)), [dispatch]);
  const startSnoozeOnboarding = useCallback(() => dispatch(onb_act.snooze.start()), [dispatch]);
  const stopSnoozeOnboarding = useCallback(() => dispatch(onb_act.snooze.stop()), [dispatch]);
  const updateStepOnboarding = useCallback(
    step => dispatch(onb_act.snooze.next_or_prev(step)),
    [dispatch],
  );

  useEffect(() => {
    if (item) {
      setWktSnoozeDate(item.context.snooze_till);
    }
  }, [item]);

  const addDays = (date, days) => {
    date.setDate(date.getDate() + days);
    const ret = date.toISOString().split("T")[0];
    return ret;
  };

  const handle_dropdown_select = useCallback(
    (key, e) => {
      // Grab Current Date, offset timezone for toISOString
      const date = remove_date_offset(new Date());

      if (check_frozen(session)) {
        set_push_alert({ name: "Snooze failed", msg: "You can't edit a frozen day" });
        return;
      }

      let snoozeDate;

      // if its not yet focus, just set it to focus if its NOT ritual and recurring
      let is_MIT = item.context.is_MIT
        ? true
        : !w_filter.is_ritual(item) && !w_filter.is_recurring(item);

      switch (key) {
        case "1":
          snoozeDate = addDays(date, 1);
          break;
        case "2":
          snoozeDate = addDays(date, 2);
          break;
        case "3":
          snoozeDate = addDays(date, 7);
          break;
        case "4":
          setShowCustomDateModal(true);
          break;
        case "5":
          snoozeDate = null;
          is_MIT = item.context.is_MIT;
          break;
        default:
          return;
      }

      if (["1", "2", "3", "5"].includes(key)) {
        controls.start(key === "5" ? "" : "snoozed");
        setWktSnoozeDate(snoozeDate);

        const ritualHistory = generate_new_ritual_history({
          item,
          new_history: {
            day: session.cur_date,
            snoozed_till: snoozeDate,
            is_ritual: item.context.is_ritual,
          },
        });

        setTimeout(() => {
          set_workette(item.jid, {
            is_MIT,
            snooze_till: snoozeDate,
            ritual_history: ritualHistory,
          });

          if (snoozeOnboarding?.running && snoozeOnboarding?.current_step === 0) {
            updateStepOnboarding(1);
          }
        }, 300);
      }
    },
    [
      controls,
      item,
      session,
      set_push_alert,
      set_workette,
      snoozeOnboarding?.current_step,
      snoozeOnboarding?.running,
      updateStepOnboarding,
    ],
  );

  const calculateDate = increment => {
    const date = remove_date_offset(new Date());

    date.setUTCDate(date.getUTCDate() + increment);
    const ret = `${print_day_of_week(date.getUTCDay()).slice(0, 3)} ${print_month_of_year(
      date.getUTCMonth(),
    )} ${date.getUTCDate()} ${date.getUTCFullYear()}`;

    return ret;
  };

  const formattedDate = () => {
    const date = remove_date_offset(wktSnoozeDate);

    let month = date.getMonth() + 1;
    if (month < 10) {
      month = "0" + month;
    }
    let day = date.getDate();
    if (day < 10) {
      day = "0" + day;
    }
    return month + "/" + day + "/" + date.getFullYear();
  };

  const onToggleDropdown = useCallback(
    isOpen => {
      if (is_item_frozen(item.jid, items, session) || check_frozen(session)) {
        return true;
      }

      setIsDropdownOpen(isOpen);
    },
    [item.jid, items, session],
  );

  const handleSelectOnCustomDate = useCallback(
    value => {
      controls.start("snoozed");
      setWktSnoozeDate(value);

      const ritualHistory = generate_new_ritual_history({
        item,
        new_history: {
          day: session.cur_date,
          snoozed_till: value,
          is_ritual: item.context.is_ritual,
        },
      });

      setTimeout(() => {
        set_workette(item.jid, {
          is_MIT: true,
          snooze_till: value,
          ritual_history: ritualHistory,
        });

        if (snoozeOnboarding?.running && snoozeOnboarding?.current_step === 0) {
          updateStepOnboarding(1);
        }
      }, 300);
    },
    [controls, item, session.cur_date, set_workette, snoozeOnboarding],
  );

  useEffect(() => {
    const handleClickOutside = e => {
      const customMenu = document.querySelector(".snooze-custom-date-modal");
      const joyrideOverlay = document.querySelector(".react-joyride__overlay");
      const onboardingContainer = document.querySelector(".onboarding-snooze-container");
      const snoozeDropdowns = document.querySelector(".snoozed-dropdown");

      if (!snoozeDropdowns?.contains(e.target)) {
        if (
          (e.target !== joyrideOverlay && e.target !== onboardingContainer) ||
          !onboardingContainer?.contains(e.target)
        ) {
          if (Boolean(customMenu)) {
            if (!Boolean(customMenu.contains(e.target))) {
              setIsDropdownOpen(false);
              setShowCustomDateModal(false);
            }
          } else {
            if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
              setIsDropdownOpen(false);
              setShowCustomDateModal(false);
            }
          }
        }
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef, showCustomDateModal]);

  useEffect(() => {
    if (!isDropdownOpen) {
      if (snoozeOnboarding?.running && snoozeOnboarding?.current_step === 0) {
        stopSnoozeOnboarding();
      }
    } else {
      if (!life.context?.settings?.tours?.includes("SnoozeOnboarding")) {
        setTimeout(() => {
          startSnoozeOnboarding();
        }, 100);
      }
    }
  }, [isDropdownOpen]);

  return (
    <>
      <span data-testid={`snooze-wrapper ${item.jid}`} className="snooze-wrapper" ref={dropdownRef}>
        <Tooltip
          padding={10}
          fontSize="11px"
          arrow={4}
          content={<span style={{ whiteSpace: "nowrap" }}>Snooze</span>}
          background="#000000C0"
          radius={7}
        >
          <OverlayTrigger
            show={isDropdownOpen}
            trigger="click"
            placement="bottom"
            onToggle={onToggleDropdown}
            overlay={
              <Popover id="snooze-popover" bsPrefix="snooze-popover popover">
                <div className="snoozing-menu">
                  <ul data-testid="snoozed-dropdown" className="snoozed-dropdown">
                    <li data-testid="snoozed-option-1" onClick={() => handle_dropdown_select("1")}>
                      Tomorrow: {calculateDate(1)}
                    </li>
                    <li data-testid="snoozed-option-2" onClick={() => handle_dropdown_select("2")}>
                      Day After Tomorrow: {calculateDate(2)}
                    </li>
                    <li data-testid="snoozed-option-3" onClick={() => handle_dropdown_select("3")}>
                      Next Week: {calculateDate(7)}
                    </li>
                    <li
                      data-testid="snoozed-option-4"
                      data-type="has-divider"
                      onClick={() => handle_dropdown_select("4")}
                      id="custom-date-option"
                    >
                      Custom Date
                    </li>
                    {item.context.snooze_till && (
                      <li
                        data-testid="snoozed-option-5"
                        onClick={() => handle_dropdown_select("5")}
                      >
                        Un-Snooze
                      </li>
                    )}
                  </ul>
                </div>
              </Popover>
            }
          >
            <div data-testid="snooze-button" className="snooze-button">
              {expanded ? (
                <span className="snooze-text">
                  {wktSnoozeDate ? (
                    formattedDate()
                  ) : (
                    <>
                      Set Date <FontAwesomeIcon icon={falAlarmClock} />
                    </>
                  )}
                </span>
              ) : (
                <span className="fa-btn">
                  <FontAwesomeIcon
                    icon={
                      w_filter.not_snoozed(item, session.cur_date) ? falAlarmClock : fasAlarmClock
                    }
                  />
                </span>
              )}
            </div>
          </OverlayTrigger>
        </Tooltip>
      </span>
      {showCustomDateModal && (
        <CustomDateModal
          value={wktSnoozeDate ? wktSnoozeDate : new Date()}
          show={showCustomDateModal}
          setFunc={handleSelectOnCustomDate}
          handleClose={setShowCustomDateModal}
        />
      )}
    </>
  );
};

WktButtonSnooze.propTypes = {
  item: PropTypes.object.isRequired,
  expanded: PropTypes.bool,
  controls: PropTypes.object.isRequired,
  isFirstItem: PropTypes.bool,
};

export default memo(WktButtonSnooze);
