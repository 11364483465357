import { useMemo } from "react";
import { stripHtml } from "./stripHtml";
import lunr from "lunr";

/**
 * This hooks returns a function that allows searching the items given to it.
 *
 * @param items An array of items to search.
 * @param searchNotes Whether or not we should search within the item note. By default, this is set
 * to `false` and we only search the item name.
 */
export function useItemSearch(items, searchNotes = false) {
  const itemEntities = useMemo(
    () =>
      items.reduce((entities, item) => {
        entities[item.jid] = item;

        return entities;
      }, {}),
    [items],
  );

  const indices = useMemo(() => {
    return lunr(function (config) {
      // there are words that is by default filtered out by lunr, thats why there are items that cannot be search like "not", "my"
      // ref: https://github.com/olivernn/lunr.js/issues/487#issuecomment-777156389

      // I have an item named "not enought item to be drilled", and when I typed "not", its not being search.

      config.pipeline.remove(lunr.stopWordFilter);
      config.searchPipeline.remove(lunr.stopWordFilter);

      this.field("allContent");
      this.field("name");

      if (searchNotes) {
        this.field("note");
      }

      this.metadataWhitelist = ["position"];

      items.forEach(item => {
        const name = item.context.name;
        const note = searchNotes ? stripHtml(item.context.note) : "";
        const allContent = name + " " + note;
        this.add({
          id: item.jid,
          allContent,
          name,
          note,
        });
      }, this);
    });
  }, [items, searchNotes]);

  const formatResults = searchResults => {
    // Sets how many letters to include before and after a highlight.
    const highlightPadding = 20;

    const formattedResults = [];
    searchResults.forEach(result => {
      const item = itemEntities[result.ref];

      const noteMatches = [];
      Object.keys(result.matchData.metadata).forEach(matchTerm => {
        const noteMatchMetadata = result.matchData.metadata[matchTerm].note;
        const noteMatchPositions = noteMatchMetadata && noteMatchMetadata.position;

        if (noteMatchPositions == null) {
          return;
        }

        noteMatchPositions.forEach(([matchStart, matchLength]) => {
          // TODO: Don't do this multiple times.
          const note = stripHtml(item.context.note);
          const matchEnd = matchStart + matchLength;
          const matchLeadingStart = Math.max(0, matchStart - highlightPadding);
          const matchLeadingEnd = matchStart;
          const matchTrailingStart = matchEnd + 1;
          const matchTrailingEnd = Math.min(note.length, matchEnd + highlightPadding) + 1;

          let matchLeading = matchLeadingStart === 0 ? "" : "...";
          matchLeading += note.slice(matchLeadingStart, matchLeadingEnd);
          let matchTrailing = note.slice(matchTrailingStart, matchTrailingEnd + 1);
          matchTrailing = matchTrailing === "" ? matchTrailing : matchTrailing + "...";
          const match = note.slice(matchStart, matchEnd + 1);

          noteMatches.push({
            matchLeading,
            matchTrailing,
            match,
          });
        });
      });

      formattedResults.push({
        item,
        noteMatches: noteMatches.length > 0 ? noteMatches : undefined,
      });
    });

    return formattedResults;
  };

  const searchItems = qTerm => {
    const queryTerm = qTerm.toLowerCase();

    const results = indices.query(function () {
      // See https://github.com/olivernn/lunr.js/issues/289 and https://github.com/olivernn/lunr.js/issues/261.
      const allTokensInQueryTerm = queryTerm.split(lunr.tokenizer.separator);

      // For when a part of the search query is in the item name and a part of it is in the note.
      allTokensInQueryTerm.forEach(function (token) {
        this.term(token, { fields: ["name"], boost: 10 });

        if (searchNotes) {
          this.term(token, { fields: ["note"], boost: 5 });
        }
      }, this);

      this.term(queryTerm, {
        fields: ["name"],
        editDistance: 1,
        boost: 20,
      });

      this.term(queryTerm, {
        fields: ["name"],
        boost: 18,
        wildcard: lunr.Query.wildcard.TRAILING,
      });

      if (searchNotes) {
        this.term(queryTerm, {
          fields: ["note"],
          boost: 16,
          editDistance: 1,
        });

        this.term(queryTerm, {
          fields: ["note"],
          boost: 14,
          wildcard: lunr.Query.wildcard.TRAILING,
        });
      }
    });

    return formatResults(results);
  };

  return { searchItems };
}
