import { useCallback, memo } from "react";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";

import WktButton from "../WktButton";

import {
  workette_actions as wact,
  session_actions as sact,
  check_frozen,
} from "@myca/shared-component";

const WktButtonAbandon = ({ controls, item }) => {
  const session = useSelector(state => state.session);

  const dispatch = useDispatch();
  const set_workette = useCallback(
    (w_id, ctx) => dispatch(wact.set_workette(w_id, ctx)),
    [dispatch],
  );
  const set_push_alert = useCallback(alert => dispatch(sact.set_push_alert(alert)), [dispatch]);

  const toggle_canceled = useCallback(async () => {
    if (check_frozen(session)) {
      set_push_alert({ name: "Abandon failed", msg: "You can't edit a frozen day" });
      return;
    }

    let val = item.context.status;
    if (val === "canceled") {
      val = "open";
      controls.start("open");
    } else {
      val = "canceled";
      controls.start("canceled");
    }

    setTimeout(() => {
      set_workette(item.jid, { status: val });
    }, 300);
  }, [controls, item.context.status, item.jid, session, set_push_alert, set_workette]);

  return (
    <WktButton
      iconName="times-circle"
      status={item.context.status === "canceled"}
      tooltip={item.context.status === "canceled" ? "Un-abandon" : "Abandon"}
      onClick={e => {
        toggle_canceled(item);
        e.stopPropagation();
      }}
    />
  );
};

WktButtonAbandon.propTypes = {
  controls: PropTypes.object.isRequired,
  item: PropTypes.object.isRequired,
};

export default memo(WktButtonAbandon);
