import { memo } from "react";
import PropTypes from "prop-types";
import { faMinusSquare, faPlusSquare } from "@fortawesome/pro-solid-svg-icons";

import WktButton from "../WktButton";

const WktButtonAdd = ({ jid, showAddForm, setShowAddForm, toggleExpand, setExpanded }) => {
  return (
    <WktButton
      data-testid={"wkt-add-btn " + jid}
      className={`wkt-add-btn ${showAddForm ? "expanded" : "collapsed"}`}
      icon={showAddForm ? faMinusSquare : faPlusSquare}
      onClick={e => {
        e.stopPropagation();
        setShowAddForm(showAddForm => !showAddForm);
        if (toggleExpand) {
          toggleExpand(jid, true, true);
        } else {
          setExpanded(true);
        }
      }}
      tooltip="Add a new item"
    />
  );
};

WktButtonAdd.propTypes = {
  jid: PropTypes.string.isRequired,
  showAddForm: PropTypes.bool,
  setShowAddForm: PropTypes.func.isRequired,
  toggleExpand: PropTypes.func,
  setExpanded: PropTypes.func.isRequired,
};

export default memo(WktButtonAdd);
