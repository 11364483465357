import { memo } from "react";
import PropTypes from "prop-types";

import WktButton from "../WktButton";

const WktButtonEye = ({ jid, showOn, toggleShow, isFirstItem }) => {
  return (
    <WktButton
      data-testid={"wkt-eye-btn " + jid}
      iconName="filter"
      iconType="fas"
      className={`wkt-eye-btn ${showOn ? "active" : "inactive"}`}
      status={showOn}
      tooltip={showOn ? "Hide Inactive Tasks" : "Show Inactive Tasks"}
      onClick={e => {
        e.stopPropagation();

        toggleShow(jid);
      }}
    />
  );
};

WktButtonEye.propTypes = {
  jid: PropTypes.string.isRequired,
  showOn: PropTypes.bool,
  toggleShow: PropTypes.func.isRequired,
  isFirstItem: PropTypes.bool,
};

export default memo(WktButtonEye);
