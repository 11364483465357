import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";

import "./SubscriptionLock.scss";

const SubscriptionLock = ({ bodyText = <></>, img }) => {
  const history = useHistory();

  return (
    <div className="subscription-lock-wrapper" data-testid="subscription-lock">
      <div className="subscription-lock-container">
        <header>This feature is only available to Myca Premium subscribers</header>
        <main>
          {bodyText}
          {img && <img src={img} alt="Goals" />}
          <button
            type="button"
            data-testid="get-premium-btn"
            onClick={() => history.push("/settings/subscription")}
          >
            Try premium for free
          </button>
        </main>
      </div>
    </div>
  );
};

SubscriptionLock.propTypes = {
  bodyText: PropTypes.node,
  img: PropTypes.string,
};

export default SubscriptionLock;
