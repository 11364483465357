import { useEffect, useState, useCallback, Suspense, lazy, useMemo, useContext } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Tab, Tabs, Spinner } from "react-bootstrap";
import { DateTime } from "luxon";

import {
  session_actions as sact,
  future_actions as fact,
  check_goal_frozen,
} from "@myca/shared-component";
import { AppContext } from "src/AppContext";

import FrozenBar from "../perform/FrozenBar";
import SubscriptionLock from "../SubscriptionLock";
import goals_webp from "src/assets/goals.webp";

import "react-calendar/dist/Calendar.css";
import "./Envision.scss";

const EnvisionTab = lazy(() => import("./EnvisionTab"));

const EnvisionMain = () => {
  const { isNonSubscriber } = useContext(AppContext);
  const DATE_NOW = DateTime.now().startOf("day");
  const DEFAULT_DATE = {
    currentWeekSelected: DATE_NOW.set({ weekday: 1 }).toFormat("yyyy-LL-dd"),
    currentMonthSelected: DATE_NOW.set({ day: 1 }).toFormat("yyyy-LL-dd"),
    currentYearSelected: DATE_NOW.set({ month: 1, day: 1 }).toFormat("yyyy-LL-dd"),
    pastWeekSelected: DATE_NOW.minus({ weeks: 1 }).set({ weekday: 1 }).toFormat("yyyy-LL-dd"),
    pastMonthSelected: DATE_NOW.minus({ months: 1 }).set({ day: 1 }).toFormat("yyyy-LL-dd"),
    pastYearSelected: DATE_NOW.minus({ years: 1 }).set({ month: 1, day: 1 }).toFormat("yyyy-LL-dd"),
  };

  const [isBottomVisible, setIsBottomVisible] = useState(false);
  const [dateSelected, setDateSelected] = useState(DEFAULT_DATE);

  const { last_day_loaded } = useSelector(state => state.workette);
  const session = useSelector(state => state.session);
  const { cur_date } = session;
  const future = useSelector(state => state.future);

  const latestCurrentDate = useMemo(() => {
    switch (future.tab_key) {
      case "week":
        return DEFAULT_DATE.currentWeekSelected;
      case "month":
        return DEFAULT_DATE.currentMonthSelected;
      case "year":
        return DEFAULT_DATE.currentYearSelected;
      default:
        return true;
    }
  }, [future.tab_key]);

  const latestPastDate = useMemo(() => {
    switch (future.tab_key) {
      case "week":
        return DEFAULT_DATE.pastWeekSelected;
      case "month":
        return DEFAULT_DATE.pastMonthSelected;
      case "year":
        return DEFAULT_DATE.pastYearSelected;
      default:
        return true;
    }
  }, [future.tab_key]);

  const currentDiffBetweenNow = useMemo(() => {
    const latest = DateTime.fromSQL(latestCurrentDate).startOf("day");
    let diff = 0;
    let prev = "";

    switch (future.tab_key) {
      case "week":
        prev = DateTime.fromSQL(dateSelected.currentWeekSelected).startOf("day");
        diff = latest.diff(prev, "weeks").as("weeks");
        break;
      case "month":
        prev = DateTime.fromSQL(dateSelected.currentMonthSelected).startOf("day");
        diff = latest.diff(prev, "months").as("months");
        break;
      case "year":
        prev = DateTime.fromSQL(dateSelected.currentYearSelected).startOf("day");
        diff = latest.diff(prev, "years").as("years");
        break;
      default:
        break;
    }

    return Math.trunc(diff);
  }, [
    dateSelected.currentMonthSelected,
    dateSelected.currentWeekSelected,
    dateSelected.currentYearSelected,
    latestCurrentDate,
    future.tab_key,
  ]);

  const pastDiffBetweenNow = useMemo(() => {
    const latest = DateTime.fromSQL(latestPastDate).startOf("day");
    let diff = 0;
    let prev = "";

    switch (future.tab_key) {
      case "week":
        prev = DateTime.fromSQL(dateSelected.pastWeekSelected).startOf("day");
        diff = latest.diff(prev, "weeks").as("weeks");
        break;
      case "month":
        prev = DateTime.fromSQL(dateSelected.pastMonthSelected).startOf("day");
        diff = latest.diff(prev, "months").as("months");
        break;
      case "year":
        prev = DateTime.fromSQL(dateSelected.pastYearSelected).startOf("day");
        diff = latest.diff(prev, "years").as("years");
        break;
      default:
        break;
    }

    return Math.trunc(diff);
  }, [
    dateSelected.pastMonthSelected,
    dateSelected.pastWeekSelected,
    dateSelected.pastYearSelected,
    latestPastDate,
    future.tab_key,
  ]);

  const prevBtnDisabled = useMemo(
    () => Boolean(currentDiffBetweenNow > -1),
    [currentDiffBetweenNow],
  );
  const nextBtnDisabled = useMemo(() => Boolean(pastDiffBetweenNow < 1), [pastDiffBetweenNow]);

  const dispatch = useDispatch();
  const change_date = useCallback(date => dispatch(sact.change_date(date)), [dispatch]);
  const set_future_tabkey = useCallback(key => dispatch(fact.set_future_tabkey(key)), [dispatch]);
  const set_future_cur_date = useCallback(
    date => dispatch(fact.set_future_cur_date(date)),
    [dispatch],
  );
  const set_past_selected_date = useCallback(
    date => dispatch(fact.set_past_selected_date(date)),
    [dispatch],
  );

  const handleOnClickNext = useCallback(
    (dt, key) => {
      let next = "";

      if (dt) {
        switch (future.tab_key) {
          case "week":
            next = DateTime.fromSQL(dt).plus({ weeks: 1 }).toFormat("yyyy-LL-dd");
            break;
          case "month":
            next = DateTime.fromSQL(dt).plus({ months: 1 }).toFormat("yyyy-LL-dd");
            break;
          case "year":
            next = DateTime.fromSQL(dt).plus({ years: 1 }).toFormat("yyyy-LL-dd");
            break;
          default:
            break;
        }
      }

      setDateSelected(dateSelected => ({
        ...dateSelected,
        [key]: next,
      }));
    },
    [future.tab_key],
  );

  const handleOnClickPrevious = useCallback(
    (dt, key) => {
      let prev = "";

      if (dt) {
        switch (future.tab_key) {
          case "week":
            prev = DateTime.fromSQL(dt).minus({ weeks: 1 }).toFormat("yyyy-LL-dd");
            break;
          case "month":
            prev = DateTime.fromSQL(dt).minus({ months: 1 }).toFormat("yyyy-LL-dd");
            break;
          case "year":
            prev = DateTime.fromSQL(dt).minus({ years: 1 }).toFormat("yyyy-LL-dd");
            break;
          default:
            break;
        }
      }

      setDateSelected(dateSelected => ({
        ...dateSelected,
        [key]: prev,
      }));
    },
    [future.tab_key],
  );

  const handleOnClickLatestCurrent = useCallback(
    isCurrent => {
      if (isCurrent) {
        switch (future.tab_key) {
          case "week":
            setDateSelected(dateSelected => ({
              ...dateSelected,
              currentWeekSelected: DEFAULT_DATE.currentWeekSelected,
            }));
            break;
          case "month":
            setDateSelected(dateSelected => ({
              ...dateSelected,
              currentMonthSelected: DEFAULT_DATE.currentMonthSelected,
            }));
            break;
          case "year":
            setDateSelected(dateSelected => ({
              ...dateSelected,
              currentYearSelected: DEFAULT_DATE.currentYearSelected,
            }));
            break;
          default:
            break;
        }
      } else {
        switch (future.tab_key) {
          case "week":
            setDateSelected(dateSelected => ({
              ...dateSelected,
              pastWeekSelected: DEFAULT_DATE.pastWeekSelected,
            }));
            break;
          case "month":
            setDateSelected(dateSelected => ({
              ...dateSelected,
              pastMonthSelected: DEFAULT_DATE.pastMonthSelected,
            }));
            break;
          case "year":
            setDateSelected(dateSelected => ({
              ...dateSelected,
              pastYearSelected: DEFAULT_DATE.pastYearSelected,
            }));
            break;
          default:
            break;
        }
      }
    },
    [future.tab_key],
  );

  useEffect(() => {
    set_future_cur_date(dateSelected);
  }, [dateSelected]);

  useEffect(() => {
    if (!cur_date && last_day_loaded !== "" && last_day_loaded !== "new") {
      change_date(last_day_loaded);
    }
  }, [change_date, cur_date, last_day_loaded]);

  useEffect(() => {
    // set date selected to default every time user change tab
    setDateSelected(DEFAULT_DATE);
  }, [future.tab_key]);

  useEffect(() => {
    switch (future.tab_key) {
      case "week":
        set_past_selected_date(dateSelected.pastWeekSelected);
        break;
      case "month":
        set_past_selected_date(dateSelected.pastMonthSelected);
        break;
      case "year":
        set_past_selected_date(dateSelected.pastYearSelected);
        break;
      default:
        break;
    }
  }, [
    dateSelected.pastMonthSelected,
    dateSelected.pastWeekSelected,
    dateSelected.pastYearSelected,
    set_past_selected_date,
    future.tab_key,
  ]);

  return (
    <>
      {isNonSubscriber && (
        <SubscriptionLock
          bodyText={
            <p>
              Myca insight - It is very important to occasionally pop up from your daily life to
              envision your future and set goals. To ensure you're dedicating time to what truly
              matters to you, myca AI seamlessly associates tasks with your goals and monitors your
              progress.
            </p>
          }
          img={goals_webp}
        />
      )}
      <div className="envision-main myca-tab" data-testid="envision-main">
        <div className="tab-header">
          <div className="d-flex flex-column">
            <h1>Goals</h1>
            <span>Time to pop up. What are the 3-5 things you want to accomplish?</span>
          </div>
        </div>
        <section className="goal-section">
          <Tabs activeKey={future.tab_key} onSelect={k => set_future_tabkey(k)}>
            <Tab eventKey="week" title="This Week">
              {future.tab_key === "week" && (
                <Suspense
                  fallback={
                    <div className="spinner">
                      <Spinner animation="grow" />
                    </div>
                  }
                >
                  <EnvisionTab
                    curDate={dateSelected.currentWeekSelected}
                    tabKey={future.tab_key}
                    editable={true}
                    handleOnClickPrevious={handleOnClickPrevious}
                    handleOnClickNext={handleOnClickNext}
                    handleOnClickLatestCurrent={handleOnClickLatestCurrent}
                    prevBtnDisabled={prevBtnDisabled}
                    isCurrent
                  />
                </Suspense>
              )}
            </Tab>
            <Tab eventKey="month" title="This Month">
              {future.tab_key === "month" && (
                <Suspense
                  fallback={
                    <div className="spinner">
                      <Spinner animation="grow" />
                    </div>
                  }
                >
                  <EnvisionTab
                    curDate={dateSelected.currentMonthSelected}
                    tabKey={future.tab_key}
                    editable={true}
                    handleOnClickPrevious={handleOnClickPrevious}
                    handleOnClickNext={handleOnClickNext}
                    handleOnClickLatestCurrent={handleOnClickLatestCurrent}
                    prevBtnDisabled={prevBtnDisabled}
                    isCurrent
                  />
                </Suspense>
              )}
            </Tab>
            <Tab eventKey="year" title="This Year">
              {future.tab_key === "year" && (
                <Suspense
                  fallback={
                    <div className="spinner">
                      <Spinner animation="grow" />
                    </div>
                  }
                >
                  <EnvisionTab
                    curDate={dateSelected.currentYearSelected}
                    tabKey={future.tab_key}
                    editable={true}
                    handleOnClickPrevious={handleOnClickPrevious}
                    handleOnClickNext={handleOnClickNext}
                    handleOnClickLatestCurrent={handleOnClickLatestCurrent}
                    prevBtnDisabled={prevBtnDisabled}
                    isCurrent
                  />
                </Suspense>
              )}
            </Tab>
            <Tab eventKey="life" title="Life">
              {future.tab_key === "life" && (
                <Suspense
                  fallback={
                    <div className="spinner">
                      <Spinner animation="grow" />
                    </div>
                  }
                >
                  <EnvisionTab
                    curDate="life"
                    tabKey={future.tab_key}
                    editable={true}
                    handleOnClickPrevious={handleOnClickPrevious}
                    handleOnClickNext={handleOnClickNext}
                    handleOnClickLatestCurrent={handleOnClickLatestCurrent}
                    prevBtnDisabled={prevBtnDisabled}
                    isCurrent
                  />
                </Suspense>
              )}
            </Tab>
          </Tabs>
        </section>
        {future.tab_key !== "life" && (
          <div
            data-testid="bottom-toggle"
            className={`bottom-toggle ${isBottomVisible ? "visible" : ""}`}
            onClick={() => setIsBottomVisible(isBottomVisible => !isBottomVisible)}
          >
            <span>Click here to {isBottomVisible ? "hide" : "see"} past goals</span>
          </div>
        )}
        {isBottomVisible && (
          <section className="goal-section bottom" data-testid="past-goal-container">
            {(check_goal_frozen(future) || future.freeze_override) && (
              <FrozenBar
                enableUnfreezeBtn={true}
                enableLatestDayBtn={false}
                text={`Your previous ${future.tab_key} is frozen`}
              />
            )}

            <Tabs activeKey={future.tab_key} onSelect={k => set_future_tabkey(k)}>
              <Tab eventKey="week" title="This Week">
                {future.tab_key === "week" && (
                  <Suspense
                    fallback={
                      <div className="spinner">
                        <Spinner animation="grow" />
                      </div>
                    }
                  >
                    <EnvisionTab
                      curDate={dateSelected.pastWeekSelected}
                      tabKey={future.tab_key}
                      editable={future.freeze_override}
                      handleOnClickPrevious={handleOnClickPrevious}
                      handleOnClickNext={handleOnClickNext}
                      handleOnClickLatestCurrent={handleOnClickLatestCurrent}
                      nextBtnDisabled={nextBtnDisabled}
                    />
                  </Suspense>
                )}
              </Tab>
              <Tab eventKey="month" title="This Month">
                {future.tab_key === "month" && (
                  <Suspense
                    fallback={
                      <div className="spinner">
                        <Spinner animation="grow" />
                      </div>
                    }
                  >
                    <EnvisionTab
                      curDate={dateSelected.pastMonthSelected}
                      tabKey={future.tab_key}
                      editable={future.freeze_override}
                      handleOnClickPrevious={handleOnClickPrevious}
                      handleOnClickNext={handleOnClickNext}
                      handleOnClickLatestCurrent={handleOnClickLatestCurrent}
                      nextBtnDisabled={nextBtnDisabled}
                    />
                  </Suspense>
                )}
              </Tab>
              <Tab eventKey="year" title="This Year">
                {future.tab_key === "year" && (
                  <Suspense
                    fallback={
                      <div className="spinner">
                        <Spinner animation="grow" />
                      </div>
                    }
                  >
                    <EnvisionTab
                      curDate={dateSelected.pastYearSelected}
                      tabKey={future.tab_key}
                      editable={future.freeze_override}
                      handleOnClickPrevious={handleOnClickPrevious}
                      handleOnClickNext={handleOnClickNext}
                      handleOnClickLatestCurrent={handleOnClickLatestCurrent}
                      nextBtnDisabled={nextBtnDisabled}
                    />
                  </Suspense>
                )}
              </Tab>
            </Tabs>
          </section>
        )}
      </div>
    </>
  );
};

export default EnvisionMain;
