import { useState, useCallback, useMemo, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { moveItemOnTree, mutateTree } from "@atlaskit/tree";
import { isEqual } from "lodash";

import WktItemSingle from "../../workette/WktItemSingle";

import {
  session_actions as sact,
  workette_actions as wact,
  workette_filters as w_filter,
  selectCurrentlySelectedLifeNode,
  selectCurrentlySelectedDayNode,
  check_frozen,
  nearestColor,
  getDeepChildrenId,
  binItems,
  findFirstOccurrence,
} from "@myca/shared-component";
import { SORT_MENUS } from "../../../utils/utils";
import { buildTree, purgeItem } from "../../../utils/tree-builder";

const useFullDayViewHook = (root, show_hidden, hide_buttons) => {
  const { session, workette, api, onboarding } = useSelector(state => state);
  const lifeNode = useSelector(selectCurrentlySelectedLifeNode);
  const ritualOnboarding = useSelector(state => state.onboarding?.ritual);

  const dispatch = useDispatch();
  const [showAddForm, setShowAddForm] = useState(false);
  const [moveTimeout, setMoveTimeout] = useState(null);
  const [showSearchModal, setShowSearchModal] = useState(false);
  const [expandTimeout, setExpandTimeout] = useState();
  const [treeData, setTreeData] = useState(
    buildTree(workette.items, root, session.cur_date, false),
  );
  const [isSortDesc, setIsSortDesc] = useState(true);
  const [sortBy, setSortBy] = useState(SORT_MENUS[0]);

  const override_order = useMemo(() => {
    if (session.drill_stack?.length > 0 && sortBy.value !== "custom") {
      return sortBy.value;
    }
  }, [session.drill_stack?.length, sortBy.value]);

  const moreThan8Children = useSelector(({ workette }) => {
    const items = Object.values(workette.items);
    return items.find(
      item =>
        item.children?.length > 8 && item.name === "workette" && item.context?.is_scheduled_now,
    );
  });

  const firstWorkset = useSelector(state => {
    const dayNode = selectCurrentlySelectedDayNode(state);
    let item = "";
    if (dayNode) {
      const children = dayNode.children;

      children.forEach(itemId => {
        if (
          state.workette.items[itemId]?.context?.wtype === "workset" &&
          state.workette.items[itemId]?.context?.is_scheduled_now
        ) {
          if (item === "") {
            item = state.workette.items[itemId];
            return true;
          }
        }
      });
    }
    return item;
  });

  const firstWorkette = useSelector(state => {
    const dayNode = selectCurrentlySelectedDayNode(state);
    let item = "";
    if (dayNode) {
      let children = getDeepChildrenId(dayNode?.jid, state.workette.items);
      children = binItems(
        state.workette.items,
        children,
        dayNode?.context.order,
        state.session.cur_date,
      );

      const isScheduledWorkette = item => w_filter.open(item);

      item = findFirstOccurrence(children, state.workette.items, isScheduledWorkette);
    }
    return item;
  });

  const itemForOnboarding = moreThan8Children?.jid;
  const itemForDrillMycaTour = itemForOnboarding || firstWorkset?.jid;
  const itemForRitualMycaTour = firstWorkette?.jid;

  useEffect(() => {
    const newTree = buildTree(workette.items, root, session.cur_date, override_order);

    if (!isEqual(newTree.items, treeData.items)) {
      setTreeData(newTree);
    }
  }, [root, workette.items, override_order]);

  const set_push_alert = useCallback(alert => dispatch(sact.set_push_alert(alert)), [dispatch]);

  const move_workette = useCallback(
    (w_id, ctx) => dispatch(wact.move_workette(w_id, ctx)),
    [dispatch],
  );

  const graph_node_set = useCallback(
    (w_id, ctx) => dispatch(wact.graph_node_set(w_id, ctx)),
    [dispatch],
  );

  const set_workette = useCallback(
    (w_id, ctx) => {
      dispatch(wact.set_workette(w_id, ctx));
    },
    [dispatch],
  );

  const expandedChildren = useMemo(() => {
    const expandedChildrenIds = [];
    for (const [key, value] of Object.entries(treeData.items)) {
      if (value.isExpanded && value.data?.name === "workette") {
        expandedChildrenIds.push(key);
      }
    }

    return expandedChildrenIds;
  }, [treeData]);

  const isTreeExpanded = expandedChildren.length > 0;

  useEffect(() => {
    const getDeepParentId = w_filter.getDeepParentId(itemForOnboarding, workette.items, true);

    // should expand the parents of the item that should be drilled
    if (
      lifeNode &&
      lifeNode.context?.settings?.tours.includes("PerformTabOnboarding") &&
      !lifeNode.context?.settings?.tours.includes("DrillOnboarding") &&
      itemForOnboarding &&
      getDeepParentId?.length > 0 &&
      !getDeepParentId.every(id => expandedChildren.includes(id))
    ) {
      graph_node_set(root, {
        expanded_children: [...expandedChildren, ...getDeepParentId],
      });
    }
  }, [lifeNode?.context?.settings?.tours, onboarding?.drill?.running, itemForOnboarding]);

  useEffect(() => {
    const getDeepParentId = w_filter.getDeepParentId(itemForRitualMycaTour, workette.items, true);

    // should expand the parents of the item that should be drilled
    if (
      lifeNode &&
      ritualOnboarding?.running &&
      itemForRitualMycaTour &&
      getDeepParentId?.length > 0 &&
      !getDeepParentId.every(id => expandedChildren.includes(id))
    ) {
      set_workette(root, {
        expanded_children: [...expandedChildren, ...getDeepParentId],
      });
    }
  }, [
    expandedChildren,
    itemForRitualMycaTour,
    lifeNode,
    ritualOnboarding?.running,
    root,
    set_workette,
    workette.items,
  ]);

  // controls logic for expanding and collapsing items, including saving state
  const toggleExpand = useCallback(
    (itemId, override = false) => {
      let newExpandedChildren = [...expandedChildren];
      const rootState = treeData.items[itemId].isExpanded;
      const newTree = mutateTree(treeData, itemId, {
        isExpanded: override ? override : !rootState,
      });

      if (newExpandedChildren.includes(itemId)) {
        if (!override) {
          newExpandedChildren.splice(newExpandedChildren.indexOf(itemId), 1);
        }
      } else {
        newExpandedChildren = [...newExpandedChildren, itemId];
      }

      // don't wait for set_workette; we'll now use the state
      setTreeData(newTree);
      // update the expanded_children of day node

      const isIdentical =
        newExpandedChildren.length === expandedChildren.length &&
        newExpandedChildren.every(value => expandedChildren.includes(value));

      if (!isIdentical) {
        graph_node_set(root, {
          expanded_children: newExpandedChildren,
        });
      }
    },
    [expandedChildren, root, graph_node_set, treeData],
  );

  const toggleTree = useCallback(async () => {
    clearTimeout(expandTimeout);
    let newTree = { ...treeData };
    const allExpandedChild = [];

    if (isTreeExpanded) {
      // tree is open, set expanded to false and rebuild tree.
      for (const [key, value] of Object.entries(treeData.items)) {
        // build our tree for local state
        newTree = {
          ...newTree,
          items: { ...newTree.items, [key]: { ...value, isExpanded: false } },
        };
      }
    } else {
      // tree is closed, so find all tree with exanded status to false
      for (const [key, value] of Object.entries(treeData.items)) {
        if (!value.isExpanded) {
          allExpandedChild.push(key);
        }
        // build our tree for local state
        newTree = {
          ...newTree,
          items: { ...newTree.items, [key]: { ...value, isExpanded: true } },
        };
      }
    }
    // don't wait for set_workette; we'll now use the state
    setTreeData(newTree);
    setExpandTimeout(
      setTimeout(() => {
        graph_node_set(root, {
          expanded_children: allExpandedChild,
        });
      }, 3000),
    );
  }, [expandTimeout, isTreeExpanded, root, graph_node_set, treeData]);

  const handleDrag = (TreeSourcePosition, TreeDestinationPosition) => {
    const srcParent = TreeSourcePosition.parentId;
    const w_id = treeData.items[srcParent].children[TreeSourcePosition.index];

    if (TreeDestinationPosition) {
      const destParentId = TreeDestinationPosition.parentId;
      const destParent = workette.items[destParentId];

      const notHidden = !w_filter.checkHidden(destParent, workette, session.cur_date);

      // if item is hidden, prevent on dropping the item
      if (!Boolean(notHidden || show_hidden)) {
        return;
      }

      if (!check_frozen(session)) {
        if (JSON.stringify(TreeSourcePosition) === JSON.stringify(TreeDestinationPosition)) {
          return;
        }

        const newTree = moveItemOnTree(treeData, TreeSourcePosition, TreeDestinationPosition);
        setTreeData(newTree);

        setSortBy(SORT_MENUS[0]); //set sort to custom

        if (destParentId === srcParent) {
          graph_node_set(srcParent, { order: [...newTree.items[srcParent].children] });
        } else {
          move_workette(w_id, {
            dest_node: destParentId,
            dest_node_order: [...newTree.items[destParentId].children],
            src_node: srcParent,
            src_node_order: [...newTree.items[srcParent].children],
            goals: destParent?.associated_to_goals || [],
          });

          setMoveTimeout(true);
        }
      } else {
        set_push_alert({ name: "Drag and drop failed", msg: "You can't edit a frozen day" });
      }
    }
  };

  const handleSearchClick = () => {
    setShowSearchModal(true);
  };

  const handleSearchModalHide = () => {
    setShowSearchModal(false);
  };

  const wsetColor = useCallback(
    w_id =>
      nearestColor({
        w_id,
        items: workette.items,
        defaultColor: "#F8F8F8",
        skipSelf: true,
      }),
    [workette.items],
  );

  // Tracks when you switch between differet wtypes to add an extra space
  let lastWtype = "workette";
  let typeCount = 0;
  let firstWkt = "";
  let firstWktset = "";
  let firstInProgress = "";

  const renderTreeItem = RenderItemParams => {
    const { item, provided, snapshot } = RenderItemParams;
    const witem = workette.items[item.id];
    if (witem) {
      // Boolean to see if item should be hidden
      const isHidden = w_filter.checkHidden(witem, workette, session.cur_date);

      //show a div between types of workettes
      const showDiv = lastWtype !== (witem.context.wtype === "" ? "workette" : witem.context.wtype);

      typeCount = typeCount + 1;

      const hasExtraGap = showDiv;

      // for workette and workset tours purposes
      if (
        firstWkt === "" &&
        (witem.context.wtype === "" || witem.context.wtype === "workette") &&
        workette.items[workette.items[item.id].parent].name !== "day"
      ) {
        firstWkt = witem.jid;
      }

      if (firstWktset === "" && witem.context.wtype === "workset" && !isHidden) {
        firstWktset = witem.jid;
      }

      if (firstInProgress === "" && witem.context.status === "running" && !isHidden) {
        firstInProgress = witem.jid;
      }

      if (showDiv) {
        lastWtype = witem.context.wtype === "" ? "workette" : witem.context.wtype;
        typeCount = 0;
      }

      const drillClass =
        onboarding?.drill?.running && itemForOnboarding === item.id ? "onboarding-drill-item" : "";
      const drillClassMycaTour = itemForDrillMycaTour === item.id ? "myca-tour-drill" : "";
      const ritualClassMycaTour = itemForRitualMycaTour === item.id ? "myca-tour-ritual" : "";

      const extraClassName = [drillClass, drillClassMycaTour, ritualClassMycaTour];

      return (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          key={item.id}
        >
          {(!isHidden || show_hidden) && (
            <WktItemSingle
              expandState={!!treeData.items[item.id].isExpanded}
              toggleExpand={toggleExpand}
              item={witem}
              leftWktActions={["complete"]}
              rightWktActions={["focus", "calendar", "snooze", "ritual", "running", "abandon"]}
              purgeTree={() => setTreeData(purgeItem(treeData, item.id))}
              count={typeCount}
              firstWkt={firstWkt}
              firstWktset={firstWktset}
              hide_buttons={hide_buttons}
              isTreeExpanded={isTreeExpanded}
              sideLineColor={wsetColor(item.id)}
              extraClassName={extraClassName.join(" ")}
              hasExtraGap={hasExtraGap}
              isDraggedOver={Boolean(snapshot.combineTargetFor)}
            />
          )}
        </div>
      );
    } else {
      return <span ref={provided.innerRef} />;
    }
  };

  return {
    root,
    treeData,
    showAddForm,
    moveTimeout,
    showSearchModal,
    isTreeExpanded,
    session,
    workette,
    api,
    toggleExpand,
    handleDrag,
    handleSearchClick,
    handleSearchModalHide,
    renderTreeItem,
    toggleTree,
    setShowAddForm,
    purgeItem,
    setTreeData,
    set_workette,
    isSortDesc,
    setIsSortDesc,
    sortBy,
    setSortBy,
    moreThan8Children,
    itemForOnboarding,
  };
};

export default useFullDayViewHook;
