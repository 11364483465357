import { memo } from "react";
import { Button } from "react-bootstrap";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFlag as fadFlag } from "@fortawesome/pro-duotone-svg-icons";
import comfortableEx from "./assets/comfortable_mode.png";
import compactEx from "./assets/compact_mode.png";
import "./AppSettings.scss";
import "./SettingsMain.scss";

const AppSettings = ({ settings, setSettings, handleSave }) => {
  return (
    <form onSubmit={handleSave}>
      <div className="d-flex flex-column gap-4">
        <h2>General Settings</h2>
        <div className="d-flex flex-column gap-4">
          <h6 className="mb-0">Display Mode:</h6>
          <div className="d-flex flex-column gap-4">
            <div className="app-setting-img-select">
              <label htmlFor="comfortable" className="select">
                <input
                  type="radio"
                  value="comfortable"
                  name="comfortable"
                  id="comfortable"
                  checked={settings.mode === "comfortable"}
                  onChange={e => setSettings({ ...settings, mode: e.target.value })}
                />{" "}
                Comfortable
              </label>
              <div>
                <img
                  className="app-settings-img"
                  alt="app settings img"
                  src={comfortableEx}
                  width={350}
                  height={150}
                  loading="lazy"
                />
              </div>
            </div>
            <div className="app-setting-img-select">
              <label htmlFor="compact" className="select">
                <input
                  type="radio"
                  value="compact"
                  name="compact"
                  id="compact"
                  checked={settings.mode === "compact"}
                  onChange={e => setSettings({ ...settings, mode: e.target.value })}
                />{" "}
                Compact
                <div style={{ divor: "#3662E7" }}>
                  <FontAwesomeIcon icon={fadFlag} /> Beta Feature
                </div>
              </label>
              <div>
                <img
                  className="app-settings-img"
                  alt="app settings img"
                  src={compactEx}
                  width={350}
                  height={150}
                  loading="lazy"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex flex-column gap-4">
          <h6 className="mb-0">
            <FontAwesomeIcon icon={fadFlag} />
            {" AI Feature Flag:"}
          </h6>
          <div>
            <div className="app-setting-img-select">
              <label htmlFor="aiFlag_true" className="select">
                <input
                  type="radio"
                  value={1}
                  name="aiFlag"
                  id="aiFlag_true"
                  data-testid="aiFlag_true"
                  checked={!!settings.aiFlag === true}
                  onChange={e => setSettings({ ...settings, aiFlag: true })}
                />{" "}
                On
              </label>
            </div>
            <div className="app-setting-img-select">
              <label htmlFor="aiFlag_false" className="select">
                <input
                  type="radio"
                  value={0}
                  name="aiFlag"
                  id="aiFlag_false"
                  data-testid="aiFlag_false"
                  checked={!!settings.aiFlag === false}
                  onChange={e => setSettings({ ...settings, aiFlag: false })}
                />{" "}
                Off
              </label>
            </div>
          </div>
        </div>
        <div className="mt-5">
          <div>
            <Button type="submit" className="primary-button">
              Save
            </Button>
          </div>
        </div>
      </div>
    </form>
  );
};

AppSettings.propTypes = {
  setSettings: PropTypes.func.isRequired,
  handleSave: PropTypes.func.isRequired,
  settings: PropTypes.shape({
    mode: PropTypes.string.isRequired,
    aiFlag: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]).isRequired,
  }),
};

export default memo(AppSettings);
