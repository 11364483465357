const MobileSubscriptionDisplay = () => {
  return (
    <div className="mobile-subscription" data-testid="mobile-subscription">
      <strong>You're currently subscribed using our mobile app. </strong>
      <p>
        If you want to update your subscription or manage it, please use our mobile app. Thank you.
      </p>
    </div>
  );
};

export default MobileSubscriptionDisplay;
