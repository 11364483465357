import { useCallback } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import DatePicker from "react-datepicker";
import {
  workette_actions as wact,
  session_actions as sact,
  print_day_of_week,
  print_month_of_year,
  remove_date_offset,
} from "@myca/shared-component";
import { DateTime } from "luxon";
import TwoRowDate from "../general/TwoRowDate";

import "./PerformDateSelector.scss";
import "react-datepicker/dist/react-datepicker.css";
import HelpInfoTooltip from "../help/HelpInfoTooltip";

const PerformDateSelector = () => {
  const days = useSelector(state => state.workette.days);
  const cur_date = useSelector(state => state.session.cur_date);
  const freeze_override = useSelector(state => state.session.freeze_override);

  const dispatch = useDispatch();
  const load_day = useCallback(date => dispatch(wact.load_day(date, undefined, undefined, "web")), [dispatch]);
  const change_date = useCallback(date => dispatch(sact.change_date(date)), [dispatch]);

  const handle_date_select = useCallback(
    v => {
      const isoDate = remove_date_offset(v).toISOString().split("T")[0];
      if (!Object.keys(days).includes(isoDate)) {
        load_day(isoDate);
      }

      change_date(isoDate);
    },
    [change_date, days, load_day],
  );

  return (
    <div className="date-selector">
      {freeze_override && cur_date ? (
        <Container>
          <Row className="date-top-row">
            <Col>{print_day_of_week(remove_date_offset(cur_date).getDay())}s</Col>
          </Row>
          <Row className="date-bottom-row">
            <Col>
              {print_month_of_year(remove_date_offset(cur_date).getMonth())}{" "}
              {remove_date_offset(cur_date).getDate()}
            </Col>
          </Row>
        </Container>
      ) : (
        <div className="datepicker-container">
          <DatePicker
            selected={
              cur_date ? new Date(DateTime.fromSQL(cur_date)) : new Date(DateTime.now().toUTC())
            }
            onChange={handle_date_select}
            maxDate={new Date(DateTime.now().toUTC())}
            customInput={
              <TwoRowDate
                date={
                  cur_date ? new Date(DateTime.fromSQL(cur_date)) : new Date(DateTime.now().toUTC())
                }
              />
            }
          />
          <HelpInfoTooltip
            className="main-tooltip"
            placement="right"
            message="You can click here to navigate to any prior day to see what happened on that day"
          />
        </div>
      )}
    </div>
  );
};

export default PerformDateSelector;
