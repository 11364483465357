import { memo, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";

import WktButton from "../WktButton";

import {
  workette_actions as wact,
  session_actions as sact,
  check_frozen,
} from "@myca/shared-component";

import "./WktButtonComplete.scss";

const WktButtonComplete = ({ noTooltip, expanded, controls, item, purgeTree }) => {
  const session = useSelector(state => state.session);

  const dispatch = useDispatch();
  const set_push_alert = useCallback(alert => dispatch(sact.set_push_alert(alert)), [dispatch]);
  const set_selected_wkt = useCallback(w_id => dispatch(sact.set_selected_wkt(w_id)), [dispatch]);
  const set_workette = (w_id, ctx) => dispatch(wact.set_workette(w_id, ctx));

  const toggle_done = useCallback(async () => {
    if (check_frozen(session)) {
      set_push_alert({ name: "Complete failed", msg: "You can't edit a frozen day" });
      return;
    }

    let val = item.context.status;
    if (val === "done") {
      val = "open";
      controls.start("open");
    } else {
      if (session.selected_wkt === item.jid) {
        set_selected_wkt(null);
      }
      val = "done";
      controls.start("done");
    }

    if (purgeTree) {
      purgeTree();
    }

    setTimeout(() => {
      set_workette(item.jid, { status: val });
    }, 300);
  }, [
    controls,
    item.context.status,
    item.jid,
    purgeTree,
    session,
    set_push_alert,
    set_selected_wkt,
    set_workette,
  ]);

  return (
    <WktButton
      data-testid={"wkt-complete-btn " + item.jid}
      isFAButton={!expanded}
      iconName="check-circle"
      label={expanded ? (item.context.status === "done" ? "Completed" : "Complete Task") : null}
      status={item.context.status === "done"}
      onClick={e => {
        toggle_done(item);
        e.stopPropagation();
      }}
      tooltip={noTooltip ? null : "Complete"}
    />
  );
};

WktButtonComplete.propTypes = {
  item: PropTypes.object.isRequired,
  noTooltip: PropTypes.bool,
  expanded: PropTypes.bool,
  controls: PropTypes.object,
  purgeTree: PropTypes.func,
};

export default memo(WktButtonComplete);
