import { useMemo, memo, useState, Suspense } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";

import FilteredDayView from "../../dayviews/FilteredDayView/FilteredDayView";
import FocusRecommendations from "../../aiviews/FocusRecommendations";

import {
  selectCurrentlySelectedLifeNode,
  deepWktRetrieve,
  workette_filters as w_filter,
} from "@myca/shared-component";
import empty_focus from "../assets/TodaysFocus_empty.webp";
import empty_focus_png from "../assets/TodaysFocus_empty.png";
import empty_ritual from "../assets/TodaysRitual_empty.webp";
import empty_ritual_png from "../assets/TodaysRitual_empty.png";
import "react-calendar/dist/Calendar.css";
import AddFocusOnboarding from "src/components/Onboarding/AddFocusOnboarding";

const defaultFilter = {
  inactive_ritual: false,
  snoozed: false,
  completed: false,
  canceled: false,
  in_progress: true,
};

const PerformTabView = ({
  root,
  setTabKey,
  drillOptions,
  tabKey,
  rowRef,
  rowClass,
  extraClass = "",
}) => {
  const lifeNode = useSelector(selectCurrentlySelectedLifeNode);
  const workette = useSelector(state => state.workette);
  const session = useSelector(state => state.session);
  const drilledItem = useSelector(
    ({ session }) =>
      session.drill_stack &&
      session.drill_stack.length > 0 &&
      [...session.drill_stack].reverse()[0].type === "drill" &&
      [...session.drill_stack].reverse()[0],
  );
  const { add_focus } = useSelector(state => state.onboarding);

  const [isOpenRecommendation, setIsOpenRecommendation] = useState(false);

  const isAiFlag = useMemo(
    () => !!lifeNode?.context?.settings?.aiFlag,
    [lifeNode?.context?.settings?.aiFlag],
  );

  const focusItems = useMemo(
    () => [
      ...new Set([
        ...deepWktRetrieve(
          root,
          {
            MIT: true,
            now: true,
            notCanceled: true,
            notCompleted: true,
            notRunning: true,
          },
          workette,
          session,
          true,
        ),
        ...deepWktRetrieve(
          root,
          {
            now: true,
            due_today: true,
            notCanceled: true,
            notCompleted: true,
            notRunning: true,
          },
          workette,
          session,
          true,
        ),
      ]),
    ],
    [root, workette, session],
  );

  const ritualItems = useMemo(
    () =>
      deepWktRetrieve(
        root,
        {
          ritual: true,
        },
        workette,
        session,
        true,
      ),
    [root, session, workette],
  );

  const recurringItems = useMemo(
    () =>
      deepWktRetrieve(
        root,
        {
          recurring: true,
        },
        workette,
        session,
        true,
      ),
    [root, session, workette],
  );

  const sections = useMemo(() => {
    return {
      focus: {
        completed: [
          ...new Set([
            ...deepWktRetrieve(
              root,
              {
                MIT: true,
                now: true,
                completed: true,
              },
              workette,
              session,
              true,
            ),
            ...deepWktRetrieve(
              root,
              {
                now: true,
                due_today: true,
                completed: true,
              },
              workette,
              session,
              true,
            ),
          ]),
        ],
      },
      ritual: {
        completed: deepWktRetrieve(
          root,
          {
            ritual: true,
            completed: true,
            now: true,
          },
          workette,
          session,
          true,
        ),
      },
      recurring: {
        completed: deepWktRetrieve(
          root,
          {
            recurring: true,
            completed: true,
            now: true,
          },
          workette,
          session,
          true,
        ),
      },
    };
  }, [root, session, workette]);

  const inProgressItems = useMemo(
    () => deepWktRetrieve(drilledItem?.jid || null, { running: true }, workette, session) || [],
    [drilledItem, session, workette],
  );

  const emptyImg = (src, src2) => (
    <>
      {drillOptions && drillOptions.hiddenElements && drillOptions.hiddenElements.emptyImg ? (
        <></>
      ) : (
        <div className="image-container">
          <picture>
            <source srcSet={src} type="image/webp" />
            <img width="360" height="300" src={src2} alt="Empty" loading="lazy" />
          </picture>
        </div>
      )}
      {drillOptions && drillOptions.hiddenElements && drillOptions.hiddenElements.goToPlan ? (
        <></>
      ) : (
        <div className="primary-button" onClick={() => setTabKey && setTabKey("plan")}>
          Go to Plan
        </div>
      )}
    </>
  );

  return (
    <>
      <div ref={rowRef} className={`perform-tab-view m-0 ${rowClass || ""} ${extraClass}`}>
        <div data-testid="today_focus" className="focus-cards">
          <div className="d-flex flex-column gap-5 mx-0">
            {isAiFlag && isOpenRecommendation && (
              <FocusRecommendations setIsOpenRecommendation={setIsOpenRecommendation} />
            )}
            <FilteredDayView
              testid="pb-focus"
              label={drillOptions?.focus?.label || "Today's Focus"}
              caption={drillOptions?.focus?.caption || "These are your priorities for today"}
              leftWktActions={["complete"]}
              rightWktActions={["focus", "calendar", "snooze", "running", "abandon"]}
              showParentTag={true}
              items={focusItems}
              completedCount={sections.focus.completed.length}
              showSummaryBarTotal
              doneImg={"All done for today"}
              emptyImg={emptyImg(empty_focus, empty_focus_png)}
              addOption={
                drillOptions && drillOptions.hiddenElements.addOption
                  ? []
                  : ["add-focus-button", "focus-recommendation"]
              }
              skeletons={[0, 0, 0, 0, 0]}
              hiddenElements={drillOptions && drillOptions.hiddenElements}
              withoutText={true}
              setIsOpenRecommendation={setIsOpenRecommendation}
              source="focus"
              extraClassName="focus-list"
              root={root}
              override_filters={defaultFilter}
            />
            {inProgressItems.length > 0 && (
              <FilteredDayView
                sectionTestId="pb-in-progress"
                label="In progress"
                caption="These are your currently in progress items"
                leftWktActions={["complete"]}
                rightWktActions={["running", "abandon"]}
                items={inProgressItems}
                skeletons={[0, 0]}
                showParentTag={true}
                showHoverActions={true}
                source="trophy-running"
                extraClassName="check-back-in-list"
                override_filters={defaultFilter}
              />
            )}
          </div>
        </div>
        <div data-testid="today_ritual" className="ritual-cards px-0">
          <div className="d-flex flex-column gap-5 mx-0">
            <FilteredDayView
              testid="pb-ritual"
              label={drillOptions?.ritual?.label || "Today's Rituals"}
              caption={
                drillOptions?.ritual?.caption ||
                (ritualItems.filter(
                  itemId =>
                    workette.items[itemId] && w_filter.scheduled_now(workette.items[itemId]),
                )?.length > 0
                  ? "These are the ritual tasks that you want to do today"
                  : "There are no ritual tasks for the day")
              }
              leftWktActions={["complete"]}
              wai
              rightWktActions={["running", "abandon"]}
              showParentTag={true}
              items={ritualItems}
              completedCount={sections.ritual.completed.length}
              showSummaryBarTotal
              doneImg={"All done for today"}
              emptyImg={emptyImg(empty_ritual, empty_ritual_png)}
              skeletons={[0, 0, 0, 0, 0]}
              hiddenElements={drillOptions && drillOptions.hiddenElements}
              tabKey={tabKey}
              dropdownButtons={false}
              source="rituals"
              extraClassName="ritual-list"
              root={root}
              override_filters={defaultFilter}
              addOption={
                drillOptions && drillOptions.hiddenElements.addOption
                  ? []
                  : ["ritual-history-button"]
              }
            />
            <FilteredDayView
              testid="pb-recurring"
              label={drillOptions?.recurring?.label || "Today's Recurrings"}
              caption={
                drillOptions?.recurring?.caption ||
                (recurringItems.filter(
                  itemId =>
                    workette.items[itemId] && w_filter.scheduled_now(workette.items[itemId]),
                )?.length > 0
                  ? "These are the recurring tasks that you want to do today"
                  : "There are no recurring tasks for the day")
              }
              showSummaryBar={true}
              leftWktActions={["complete"]}
              rightWktActions={["running", "abandon"]}
              showParentTag={true}
              items={recurringItems}
              completedCount={sections.recurring.completed.length}
              showSummaryBarTotal
              doneImg={"All done for today"}
              emptyImg={emptyImg(empty_ritual, empty_ritual_png)}
              skeletons={[0, 0, 0, 0, 0]}
              hiddenElements={drillOptions && drillOptions.hiddenElements}
              tabKey={tabKey}
              dropdownButtons={false}
              source="recurrings"
              extraClassName="recurring-list"
              root={root}
              override_filters={defaultFilter}
            />
          </div>
        </div>
      </div>
      {lifeNode && add_focus?.running && session.video_playing === "" && (
        <Suspense fallback="">
          <AddFocusOnboarding />
        </Suspense>
      )}
    </>
  );
};

PerformTabView.propTypes = {
  root: PropTypes.string,
  setTabKey: PropTypes.func,
  drillOptions: PropTypes.object,
  tabKey: PropTypes.string,
  rowRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.any })]),
  rowClass: PropTypes.string,
  extraClass: PropTypes.string,
};

export default memo(PerformTabView);
