import { memo, useCallback } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";

import WktButton from "../WktButton";

import { session_actions as sact } from "@myca/shared-component";

const WktButtonEdit = ({ jid }) => {
  const dispatch = useDispatch();
  const set_selected_wkt = useCallback(() => dispatch(sact.set_selected_wkt(jid)), [dispatch, jid]);

  return (
    <WktButton
      data-testid={`wkt-edit-btn ${jid}`}
      iconName="pen"
      iconType="fas"
      onClick={e => {
        e.stopPropagation();
        set_selected_wkt(jid);
      }}
      tooltip="Edit Task"
    />
  );
};

WktButtonEdit.propTypes = {
  jid: PropTypes.string.isRequired,
};

export default memo(WktButtonEdit);
