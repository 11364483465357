import { useCallback, memo, useState, useRef, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { DateTime } from "luxon";
import moment from "moment";
import { Editor } from "@tinymce/tinymce-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExpandArrowsAlt } from "@fortawesome/pro-solid-svg-icons";

import { Input } from "../utils/utils";
import {
  workette_actions as wact,
  session_actions as sact,
  check_frozen,
  is_item_frozen,
} from "@myca/shared-component";

import "./WktNoteForm.scss";

let noteMounted = 0;

const WktNoteForm = ({ w_id, height = "400px", editable = false, hasFullScreen = false }) => {
  const [savingTimeout, setSavingTimeout] = useState(null);
  const [note, setNote] = useState("");
  const [onFocusNote, setOnFocusNote] = useState(false);
  const [label, setLabel] = useState("");
  const [instanceCount, setInstanceCount] = useState(0);

  const { items } = useSelector(state => state.workette);
  const session = useSelector(state => state.session);
  const { drill_stack } = session;
  const { status } = useSelector(state => state.api);
  const item = useSelector(state => w_id && state.workette.items[w_id]);

  const isGoalEditable = useSelector(state => state.future?.is_selected_item_editable || false);

  const is_link = useMemo(
    () => items[w_id]?.context?.wtype && items[w_id].context.wtype === "link",
    [items, w_id],
  );

  const noteLastUpdated = useMemo(
    () =>
      items[w_id]?.context?.note_last_updated &&
      moment.utc(items[w_id].context.note_last_updated).fromNow(),
    [items, w_id],
  );

  const editorRef = useRef(null);

  const dispatch = useDispatch();
  const set_workette = useCallback(
    (w_id, ctx, bypassToast) => dispatch(wact.set_workette(w_id, ctx, false, bypassToast)),
    [dispatch],
  );
  const set_push_alert = useCallback(alert => dispatch(sact.set_push_alert(alert)), [dispatch]);
  const update_drill_stack = useCallback(
    newStacks => dispatch(sact.update_drill_stack(newStacks)),
    [dispatch],
  );

  useEffect(() => {
    setNote(items[w_id]?.context?.note || "");
  }, [w_id]);

  useEffect(() => {
    const nm = noteMounted++;
    setInstanceCount(nm + 1);
  }, []);

  useEffect(() => {
    // just change the label to the newest component mounted
    if (instanceCount === noteMounted && onFocusNote) {
      switch (status) {
        case "Working":
          setLabel("Saving...");
          break;
        case "Success":
          noteLastUpdated && setLabel(`Last saved ${noteLastUpdated}`);
          break;
        default:
          setLabel("Saving failed");
          break;
      }
    } else {
      setLabel("");
    }
  }, [instanceCount, noteLastUpdated, onFocusNote, status]);

  useEffect(() => {
    if (!onFocusNote) {
      setLabel("");
    }
  }, [onFocusNote]);

  const handleChange = useCallback(
    (value, delta, source = "user") => {
      if (source === "user") {
        if (item?.context?.wtype === "goal") {
          if (!isGoalEditable) {
            set_push_alert({ name: "Note update failed", msg: "You can't edit a frozen day" });
            return true;
          }
        } else if (["week", "month", "year", "life"].includes(item?.name)) {
          if (!editable) {
            set_push_alert({ name: "Note update failed", msg: "You can't edit a frozen day" });
            return true;
          }
        } else {
          if (is_item_frozen(w_id, items, session) || check_frozen(session)) {
            set_push_alert({ name: "Note update failed", msg: "You can't edit a frozen day" });
            return true;
          }
        }

        clearTimeout(savingTimeout);

        setNote(value);

        const note_last_updated = DateTime.utc().toString();

        const bypassToast = item?.name === "life";

        setSavingTimeout(
          setTimeout(() => {
            set_workette(w_id, { note: value, note_last_updated }, bypassToast);
          }, 1000),
        );
      }
    },
    [
      editable,
      isGoalEditable,
      item?.context?.wtype,
      item?.name,
      items,
      savingTimeout,
      session,
      set_push_alert,
      set_workette,
      w_id,
    ],
  );

  const handleOnFocus = useCallback(() => {
    if (item?.context?.wtype === "goal") {
      if (!isGoalEditable) {
        set_push_alert({ name: "Note update failed", msg: "You can't edit a frozen day" });
        return true;
      }
    } else if (["week", "month", "year", "life"].includes(item?.name)) {
      if (!editable) {
        set_push_alert({ name: "Note update failed", msg: "You can't edit a frozen day" });
      }
    } else {
      if (is_item_frozen(w_id, items, session) || check_frozen(session)) {
        set_push_alert({ name: "Note update failed", msg: "You can't edit a frozen day" });
        return true;
      }
    }

    setOnFocusNote(true);
  }, [
    editable,
    isGoalEditable,
    item?.context?.wtype,
    item?.name,
    items,
    session,
    set_push_alert,
    w_id,
  ]);

  const isReadOnly = useMemo(() => {
    if (item?.context?.wtype === "goal") {
      if (!isGoalEditable) {
        return true;
      }
    } else if (["week", "month", "year", "life"].includes(item?.name)) {
      return !editable;
    } else {
      if (is_item_frozen(w_id, items, session) || check_frozen(session)) {
        return true;
      }
    }

    return false;
  }, [editable, isGoalEditable, item?.context?.wtype, item?.name, items, session, w_id]);

  const closeMenuToolbar = useCallback(() => {
    const isOpen = editorRef?.current?.editorCommands.commands.state.toggletoolbardrawer();

    if (onFocusNote && isOpen) {
      editorRef?.current?.execCommand("ToggleToolbarDrawer");
    }
  }, [onFocusNote]);

  const toggleNoteForm = useCallback(
    state => {
      if (state) {
        update_drill_stack([...drill_stack, { jid: w_id, type: "note" }]);
      }
    },
    [drill_stack, update_drill_stack, w_id],
  );

  return (
    <div className="note-container">
      <header>
        {status && (
          <span
            className={`label ${
              status === "Working" ? "saving" : status === "Success" ? "saved" : ""
            }`}
          >
            {noteLastUpdated && label}
          </span>
        )}
        {hasFullScreen && (
          <FontAwesomeIcon
            icon={faExpandArrowsAlt}
            onClick={toggleNoteForm}
            data-testid={`full-note-button ${w_id}`}
            className="full-note-button"
            title="Expand Note"
          />
        )}
      </header>
      <form
        className={`workette-note-form ${isReadOnly ? "disabled" : ""}`}
        data-testid={`workette-note-form ${w_id}`}
        onSubmit={e => e.preventDefault()}
      >
        {is_link ? (
          <div className="link-note">
            <Input
              value={note.replace(/<\/?[^>]+(>|$)/g, "")}
              onChange={e => handleChange(e.target.value)}
              extra_class="form-control-sm"
              name="note"
              description="Link URL"
              data-testid={`input-link-url ${w_id}`}
              disabled={isReadOnly}
            />
          </div>
        ) : (
          <>
            <div
              data-testid={`workette-note-editor ${w_id}`}
              className="workette-note-editor"
              aria-disabled={isReadOnly}
            >
              {note}
            </div>
            <Editor
              onInit={(evt, editor) => {
                editorRef.current = editor;
              }}
              value={note}
              tinymceScriptSrc="/tinymce/tinymce.min.js"
              onEditorChange={handleChange}
              init={{
                license_key: "gpl",
                content_style: "body {font-size: 10pt;}",
                height,
                // menubar: "file edit insert view format table tools help", // default
                menubar: false,
                statusbar: false,
                plugins: [
                  "advlist",
                  "anchor",
                  "autolink",
                  "help",
                  "image",
                  "link",
                  "lists",
                  "searchreplace",
                  "table",
                  "wordcount",
                  "nonbreaking",
                ],
                toolbar:
                  "bold italic forecolor fontsize | alignleft aligncenter " +
                  "alignright alignjustify | bullist numlist outdent indent",
                toolbar_mode: "sliding",
                nonbreaking_force_tab: true,
                contextmenu: "link copy paste",
              }}
              disabled={isReadOnly}
              onFocus={e => {
                handleOnFocus();
                closeMenuToolbar();
              }}
              onChange={e => {
                if (e.originalEvent?.type === "execcommand") {
                  closeMenuToolbar();
                }
              }}
              onBlur={closeMenuToolbar}
            />
          </>
        )}
      </form>
    </div>
  );
};

WktNoteForm.propTypes = {
  w_id: PropTypes.string.isRequired,
  height: PropTypes.string,
  editable: PropTypes.bool,
  hasFullScreen: PropTypes.bool,
};

export default memo(WktNoteForm);
