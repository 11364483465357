import { memo } from "react";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle } from "@fortawesome/pro-light-svg-icons";
import PropTypes from "prop-types";

import "./InfoTooltip.scss";

const InfoTooltip = ({ description = "" }) => {
  return (
    <OverlayTrigger
      placement="top"
      trigger={["hover", "focus"]}
      key="top"
      overlay={
        <Popover id="tooltip-info" bsPrefix="info-popover popover">
          <Popover.Title>How Does This Work?</Popover.Title>
          <Popover.Content>{description}</Popover.Content>
        </Popover>
      }
    >
      <FontAwesomeIcon icon={faQuestionCircle} />
    </OverlayTrigger>
  );
};

InfoTooltip.propTypes = {
  description: PropTypes.string,
};

export default memo(InfoTooltip);
