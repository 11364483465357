import { memo, useCallback, useEffect, useState } from "react";
import { ProgressBar } from "react-bootstrap";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";

import {
  myca_buddy_actions as mbact,
  selectMycaBuddyTriggers,
  workette_actions as wact,
  date_now,
  handleMycaBuddyTrigger,
} from "@myca/shared-component";

const WktListProgressBar = ({
  items = [],
  completedCount = 0,
  testid = "",
  source = "",
  isDay = false,
}) => {
  const [percentage, setPercentage] = useState(0);
  const { freeze_override, cur_date } = useSelector(state => state.session);

  const todayDayNode = useSelector(state => state.workette.items[state.workette.days[date_now()]]);

  const myca_buddy_triggers = useSelector(selectMycaBuddyTriggers);

  const dispatch = useDispatch();
  const query_ai_focus_completion_all = useCallback(
    cur_date => {
      dispatch(mbact.query_ai_focus_completion_all(cur_date, "web"));
    },
    [dispatch],
  );
  const query_ai_focus_completion_partial = useCallback(
    (cur_date, percentage) => {
      dispatch(mbact.query_ai_focus_completion_partial(cur_date, percentage, "web"));
    },
    [dispatch],
  );
  const query_ai_ritual_completion_all = useCallback(
    cur_date => {
      dispatch(mbact.query_ai_ritual_completion_all(cur_date, "web"));
    },
    [dispatch],
  );
  const set_workette = useCallback(
    (w_id, ctx) => dispatch(wact.set_workette(w_id, ctx)),
    [dispatch],
  );

  useEffect(() => {
    if (!freeze_override && isDay && cur_date === date_now()) {
      handleMycaBuddyTrigger(
        source,
        myca_buddy_triggers,
        percentage,
        cur_date,
        todayDayNode,
        set_workette,
        query_ai_focus_completion_all,
        query_ai_focus_completion_partial,
        query_ai_ritual_completion_all,
      );
    }
  }, [percentage]);

  useEffect(() => {
    const newPercentage = Math.round((completedCount / (items.length + completedCount)) * 100) || 0;
    setPercentage(newPercentage);
  }, [items]);

  return (
    <div className="progress-bar-wrapper">
      <ProgressBar
        data-testid={testid}
        className="success"
        variant="success"
        now={percentage}
        label={`${percentage}%`}
        data-percentage={percentage}
      />
    </div>
  );
};

WktListProgressBar.propTypes = {
  items: PropTypes.array,
  testid: PropTypes.string,
  source: PropTypes.string,
  completedCount: PropTypes.number,
  isDay: PropTypes.bool,
};

export default memo(WktListProgressBar);
