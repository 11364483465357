import { useEffect, useState, useCallback, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Container, Row, Col, OverlayTrigger, Modal, Popover } from "react-bootstrap";
import { faAlarmClock as falAlarmClock } from "@fortawesome/pro-light-svg-icons";
import DatePicker from "react-datepicker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Editor } from "@tinymce/tinymce-react";
import PropTypes from "prop-types";

import {
  workette_actions as wact,
  remove_date_offset,
  randColor,
  print_wkt_name,
  logTour,
} from "@myca/shared-component";

import WktButton from "../wktbuttons/WktButton";
import WktRitualForm from "./WktRitualForm";
import WktButtonDateDisplay from "../wktbuttons/WktButtonDateDisplay";
import { ParentSelectionModal } from "../../perform/ParentSelectionModal/ParentSelectionModal";

import { InputEmoji, addSquare } from "../../../utils/utils";

import "./WktAddForm.scss";

/**
 * Modal used to add items with more fields populated

 */
const WktAddModal = ({ showAddModal, setShowAddModal, context_override, w_id }) => {
  const [ritualHover, setRitualHover] = useState(false);

  // Controler for additional menus
  const [showRitualModal, setShowRitualModal] = useState(false);
  const [showParentSelector, setShowParentSelector] = useState(false);

  // Local states for context fields
  const [dueDate, setDueDate] = useState(null);
  const [snoozeTill, setSnoozeTill] = useState(null);
  const [ritual, setRitual] = useState(null);
  const [isMIT, setIsMIT] = useState(context_override?.is_MIT);
  const [newParent, setNewParent] = useState(w_id);
  const [note, setNote] = useState(context_override?.note);
  const [title, setTitle] = useState(context_override?.title);
  const [wtype, setWtype] = useState(context_override?.wtype);

  useEffect(() => {
    setTitle(context_override?.title);
  }, [context_override]);

  useEffect(() => {
    setNewParent(w_id);
  }, [w_id]);

  // Redux imports
  const { session, workette } = useSelector(state => state);
  const lifeID = useSelector(state => state.workette.life);
  const life = useSelector(state => state.workette.items[lifeID["life"]]);
  const { add_focus, add_item } = useSelector(state => state.onboarding);

  // Dispatch functions
  const dispatch = useDispatch();
  const set_workette = useCallback(
    (w_id, ctx) => dispatch(wact.set_workette(w_id, ctx)),
    [dispatch],
  );
  const create = useCallback(
    (w_id, title) => dispatch(wact.create_workette(w_id, title)),
    [dispatch],
  );

  useEffect(() => {
    setNewParent(w_id);
  }, [w_id]);

  const handleDDChange = date => {
    const dt = date ? remove_date_offset(date).toISOString().split("T")[0] : null;
    setDueDate(dt);
  };
  const handleSnoozeChange = date => {
    setSnoozeTill(date);
  };
  const handleNoteChange = (value, delta, source) => {
    if (source === "user") {
      setNote(value);
    }
  };
  const handleParentSelectorHide = () => {
    setShowParentSelector(false);
  };
  const handleParentSelectorNodeSelect = nodeId => {
    setNewParent(nodeId);
    setShowParentSelector(false);
  };

  const handleChange = e => {
    if (e.target) {
      if (e.target.name === "add") {
        setTitle(e.target.value);
      } else if (e.target.name === "wtype") {
        setWtype(e.target.value);
      } else if (e.target.name === "link") {
        setNote(e.target.value);
      }
    }
  };

  const handleClose = () => {
    setShowAddModal(false);
    setTitle("");
    setSnoozeTill(null);
    setDueDate(null);
    setNewParent(w_id);
    setNote("");
    setIsMIT(context_override ? context_override.is_MIT : false);
  };
  const handleSubmit = useCallback(
    e => {
      e.preventDefault();

      let context = {
        title,
        wtype,
        due_date: dueDate,
        snooze_till: snoozeTill,
        is_ritual: ritual,
        note,
        is_MIT: isMIT,
      };

      create(newParent, context);

      //
      if (wtype === "workset") {
        context = { ...context, color: randColor() };
      }

      // log the first time user created a GROUP
      if (wtype === "workset" && !life?.context?.settings?.tours.includes("CreatedGroup")) {
        logTour(lifeID, life, set_workette, "CreatedGroup");
      }
      // log the first time user created a TASK
      if (wtype === "workette" && !life?.context?.settings?.tours.includes("CreatedTask")) {
        logTour(lifeID, life, set_workette, "CreatedTask");
      }
      // log the first time user created a FOCUS ITEM
      if (isMIT && !life?.context?.settings?.tours.includes("CreatedFocusItem")) {
        logTour(lifeID, life, set_workette, "CreatedFocusItem");
      }
      handleClose();
    },
    [
      add_item?.current_step,
      add_item?.running,
      add_focus?.current_step,
      add_focus?.running,
      dueDate,
      isMIT,
      life,
      lifeID,
      newParent,
      note,
      ritual,
      session,
      snoozeTill,
      title,
      w_id,
      wtype,
    ],
  );
  const formatRitualString = useMemo(() => {
    if (!ritual) {
      return "Configure Ritual";
    }
    let frequency = ritual?.frequency.toLowerCase();
    const rate = ritual?.interval;
    if (rate === 1) {
      frequency = frequency.substring(0, frequency.length - 1);
      return "Every " + frequency;
    }
    return "Every " + rate + " " + frequency;
  }, [ritual]);

  return (
    <>
      <Modal
        enforceFocus={false}
        dialogClassName={"small-modal"}
        show={showAddModal}
        onHide={handleClose}
        className="wkt-add-modal"
        centered
      >
        <Modal.Header closeButton>Add Task</Modal.Header>
        <Modal.Body>
          <Container className="pr-0 pl-0 wkt-details-view">
            <div className="w-100 d-flex align-items-center gap-2">
              <div className="title-input-group">
                <InputEmoji
                  value={title}
                  onChange={handleChange}
                  name="add"
                  autoComplete="off"
                  placeholder="Enter Task Name"
                />
              </div>
              <Col sm="auto" className="px-0">
                <WktButton
                  iconName="star"
                  status={isMIT}
                  tooltip="Focus"
                  onClick={() => setIsMIT(!isMIT)}
                />
              </Col>
            </div>
            <Row>
              <Col sm={3} className="label-col">
                Due Date
              </Col>
              <Col>
                <DatePicker
                  selected={dueDate ? remove_date_offset(dueDate) : null}
                  onChange={handleDDChange}
                  customInput={<WktButtonDateDisplay />}
                  isClearable
                />
              </Col>
            </Row>
            <Row>
              <Col sm={3} className="label-col">
                <span>
                  Snooze <FontAwesomeIcon icon={falAlarmClock} />
                </span>
              </Col>
              <Col>
                <DatePicker
                  selected={snoozeTill ? remove_date_offset(snoozeTill) : null}
                  onChange={handleSnoozeChange}
                  customInput={<WktButtonDateDisplay />}
                  isClearable
                />
              </Col>
            </Row>
            <Row>
              <Col sm={3} className="label-col">
                Group
              </Col>
              <Col>
                <div onClick={() => setShowParentSelector(true)} className="add-item-move-button">
                  {workette.items[newParent] ? (
                    <div>
                      {addSquare(workette.items[newParent].context.color)}
                      {print_wkt_name(workette.items[newParent])}
                    </div>
                  ) : (
                    "No Parent Workset"
                  )}
                </div>
              </Col>
            </Row>
            <Row>
              <Col sm={3} className="label-col">
                Type
              </Col>
              <div className="add-type-form">
                <select name="wtype" className="type-input" value={wtype} onChange={handleChange}>
                  <option value="workette">Task</option>
                  <option value="workset">Group</option>
                  <option value="link">Link</option>
                  <option value="note">Note</option>
                </select>
              </div>
            </Row>
            <Row>
              <Col sm={3} className="label-col">
                Repeat
              </Col>
              <Col>
                <OverlayTrigger
                  show={showRitualModal}
                  rootClose
                  trigger="click"
                  placement="right"
                  overlay={
                    <Popover id="add-recurring-popover" bsPrefix="add-recurring-popover popover">
                      <WktRitualForm
                        new_wkt={setRitual}
                        hide={() => setShowRitualModal(false)}
                        new_settings={ritual}
                      />
                    </Popover>
                  }
                  onMouseEnter={() => setRitualHover(true)}
                  onMouseLeave={() => setRitualHover(false)}
                >
                  <span className="add-ritual-summary">
                    {ritual && ritual.frequency ? (
                      <span onClick={() => setShowRitualModal(true)}>{formatRitualString}</span>
                    ) : (
                      <span onClick={() => setShowRitualModal(true)}>Configure Ritual</span>
                    )}
                    {ritualHover && (
                      <span className="clear-link" onClick={() => setRitual(null)}>
                        x
                      </span>
                    )}
                  </span>
                </OverlayTrigger>
              </Col>
            </Row>
            <Row className="p-1">
              <Col>
                <Editor
                  tinymceScriptSrc="/tinymce/tinymce.min.js"
                  value={note}
                  onEditorChange={handleNoteChange}
                  init={{
                    license_key: "gpl",
                    height: 300,
                    menubar: false,
                    plugins: [
                      "advlist",
                      "anchor",
                      "autolink",
                      "image",
                      "link",
                      "lists",
                      "searchreplace",
                      "table",
                      "wordcount",
                    ],
                    toolbar: "undo redo | bold italic forecolor | bullist numlist",
                  }}
                />
              </Col>
            </Row>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <input
            type="button"
            aria-label="cancel button"
            onClick={handleClose}
            className="secondary-button"
            style={{ backgroundColor: "grey", marginRight: 8 }}
            value="Cancel"
          />
          <input
            type="button"
            aria-label="create button"
            onClick={handleSubmit}
            className="primary-button"
            value="Create"
          />
        </Modal.Footer>
      </Modal>
      <ParentSelectionModal
        show={showParentSelector}
        onHide={handleParentSelectorHide}
        onNodeSelect={handleParentSelectorNodeSelect}
      />
    </>
  );
};

WktAddModal.propTypes = {
  showAddModal: PropTypes.bool.isRequired,
  setShowAddModal: PropTypes.func.isRequired,
  context_override: PropTypes.object,
  w_id: PropTypes.string.isRequired,
};

export default WktAddModal;
