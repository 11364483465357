import { useCallback, useState, memo, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import Tooltip from "react-simple-tooltip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckSquare, faTimesSquare } from "@fortawesome/pro-duotone-svg-icons";

import { ParentSelectionModal } from "../perform/ParentSelectionModal/ParentSelectionModal";

import {
  workette_actions as wact,
  onboarding_actions as onb_act,
  print_wkt_name,
  nearestColor,
  selectCurrentlySelectedLifeNode,
} from "@myca/shared-component";
import { addSquare } from "../../utils/utils";

import logo from "./SuggestedLogo_Green.svg";

import "./SuggestedWorkset.scss";

const SuggestedWorkset = ({ w_id, recommendedParentID, setRecommended }) => {
  const [showModal, setShowModal] = useState(false);

  const workette = useSelector(state => state.workette);
  const parentNode = useSelector(
    state =>
      state.workette.items[w_id]?.parent && state.workette.items[state.workette.items[w_id].parent],
  );
  const lifeNode = useSelector(selectCurrentlySelectedLifeNode);

  const recommendedParent = useSelector(({ workette }) => workette.items[recommendedParentID]);

  const dispatch = useDispatch();
  const move_workette = useCallback(
    (w_id, ctx) => dispatch(wact.move_workette(w_id, ctx)),
    [dispatch],
  );
  const decline_parent_recommendation = useCallback(
    w_id => dispatch(wact.decline_parent_recommendation(w_id)),
    [dispatch],
  );
  const startAIOnboarding = useCallback(() => dispatch(onb_act.ai.start()), [dispatch]);

  const handleAcceptSuggestion = useCallback(() => {
    let ctx = {
      dest_node: recommendedParentID,
      dest_node_order: recommendedParent ? [...(recommendedParent.context?.order || []), w_id] : [],
    };

    if (parentNode) {
      ctx = {
        ...ctx,
        src_node: parentNode.jid,
        src_node_order: parentNode.context?.order
          ? parentNode.context.order.filter(id => id !== w_id)
          : [],
      };
    }

    move_workette(w_id, ctx);
  }, [move_workette, parentNode, recommendedParent, recommendedParentID, w_id]);

  const handleSelectParent = useCallback(
    parentId => {
      let ctx = {
        dest_node: parentId,
        dest_node_order: parentId?.context?.order ? [...parentId.context.order, w_id] : [w_id],
      };

      if (parentNode) {
        ctx = {
          ...ctx,
          src_node: parentNode.jid,
          src_node_order: parentNode.context?.order
            ? parentNode.context.order.filter(id => id !== w_id)
            : [],
        };
      }

      move_workette(w_id, ctx);
      setShowModal(false);
    },
    [move_workette, parentNode, w_id],
  );

  useEffect(() => {
    if (
      !lifeNode?.context?.settings?.tours?.includes("AIOnboarding") &&
      lifeNode?.context?.settings?.tours?.includes("PerformTabOnboarding")
    ) {
      setTimeout(() => {
        startAIOnboarding();
      }, 500);
    }
  }, [lifeNode?.context?.settings?.tours]);

  return (
    <>
      <Tooltip
        padding={10}
        fontSize="11px"
        arrow={4}
        content={<span style={{ whiteSpace: "nowrap" }}>Myca Recommended Workset</span>}
        background="#000000C0"
        radius={7}
        className="suggested-workset-tooltip d-flex"
        data-recid={recommendedParentID}
        data-testid={`parent-recommendation-wrapper ${w_id}`}
      >
        <span className="recommended-parent">
          <img src={logo} alt="myca-ai" loading="lazy" />
          <span
            onClick={() => {
              setShowModal(true);
            }}
            className="title-wrapper"
            data-testid="recommended-parent-title"
          >
            {recommendedParent &&
              addSquare(
                nearestColor({
                  w_id: recommendedParentID,
                  items: workette.items,
                  defaultColor: "#333333",
                }),
              )}

            <span className="title">{recommendedParent && print_wkt_name(recommendedParent)}</span>
          </span>
          <div className="parent-recommendation-buttons" data-testid="parent-recommendation-button">
            <span className="accept-recommendation-btn">
              <FontAwesomeIcon
                icon={faCheckSquare}
                color="green"
                data-testid={`accept-recommendation-btn ${w_id}`}
                onClick={e => {
                  e.stopPropagation();
                  handleAcceptSuggestion();
                }}
              />
            </span>
            <span className="decline-recommendation-btn">
              <FontAwesomeIcon
                icon={faTimesSquare}
                color="red"
                data-testid={`decline-recommendation-btn ${w_id}`}
                onClick={e => {
                  e.stopPropagation();
                  setRecommended(null);
                  decline_parent_recommendation(w_id);
                }}
              />
            </span>
          </div>
        </span>
      </Tooltip>
      <ParentSelectionModal
        show={showModal}
        onHide={() => setShowModal(false)}
        nodeIdToSkip={w_id}
        onNodeSelect={handleSelectParent}
        selectedParentId={recommendedParentID}
      />
    </>
  );
};

SuggestedWorkset.propTypes = {
  w_id: PropTypes.string.isRequired,
  recommendedParentID: PropTypes.string.isRequired,
  setRecommended: PropTypes.func.isRequired,
};

export default memo(SuggestedWorkset);
