import { memo } from "react";
import PropTypes from "prop-types";

import WktButton from "../WktButton";

const WktButtonNote = ({ jid, setShowNoteFrame, setShowNoteForm }) => {
  return (
    <WktButton
      data-testid={"wkt-note-btn " + jid}
      iconName="sticky-note"
      iconType="fas"
      onClick={e => {
        e.stopPropagation();
        setShowNoteForm && setShowNoteForm(showNoteForm => !showNoteForm);
      }}
      tooltip="Open Note"
    />
  );
};

WktButtonNote.propTypes = {
  jid: PropTypes.string.isRequired,
  setShowNoteFrame: PropTypes.func,
  setShowNoteForm: PropTypes.func,
};

export default memo(WktButtonNote);
