import { useState, memo, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Dropdown, Modal, Button } from "react-bootstrap";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisH, faRunning, faTimesCircle } from "@fortawesome/pro-solid-svg-icons";

import {
  workette_actions as wact,
  session_actions as sact,
  check_frozen,
} from "@myca/shared-component";

import "./WktButtonMiniMenu.scss";

/*
  openDropdown: is the state for the viewDetailsTour when the step should is to show the dropdown menu
*/
const WktButtonMiniMenu = ({ item, purgeTree, controls, openDropdown = false }) => {
  const session = useSelector(state => state.session);

  const dispatch = useDispatch();
  const delete_workette = useCallback(
    (w_id, parent_id) => dispatch(wact.delete_workette(w_id, parent_id)),
    [dispatch],
  );
  const set_selected_wkt = useCallback(w_id => dispatch(sact.set_selected_wkt(w_id)), [dispatch]);
  const set_workette = useCallback(
    (w_id, ctx) => dispatch(wact.set_workette(w_id, ctx)),
    [dispatch],
  );
  const set_selected_wkt_calendar = useCallback(
    w_id => dispatch(sact.set_selected_wkt_calendar(w_id)),
    [dispatch],
  );

  const [showDelete, setShowDelete] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(openDropdown || false);

  const handleDelete = () => {
    if (check_frozen(session)) {
      return;
    }
    set_selected_wkt(null);
    if (purgeTree) {
      purgeTree();
    }
    delete_workette(item.jid, item.parent);
  };

  const handleOpen = () => {
    setShowDelete(true);
  };

  const handleClose = () => {
    setShowDelete(false);
  };

  const handleAbandon = async () => {
    if (check_frozen(session)) {
      return;
    }
    let val = item.context.status;
    if (val === "canceled") {
      val = "open";
    } else {
      await controls.start("cancel");
      val = "canceled";
    }
    set_selected_wkt(null);
    set_workette(item.jid, { status: val });
  };

  const handleRunning = () => {
    if (check_frozen(session)) {
      return;
    }
    let val = item.context.status;
    if (val === "running") {
      val = "open";
    } else {
      val = "running";
    }
    set_selected_wkt(null);
    set_workette(item.jid, { status: val });
  };

  const handle_dropdown_select = (key, e) => {
    switch (key) {
      case "1":
        handleOpen();
        return;
      case "abandon":
        handleAbandon();
        return;
      case "running":
        handleRunning();
        return;
      case "calendar":
        set_selected_wkt_calendar(item.jid);
        return;
      default:
        return;
    }
  };

  const onToggleDropdown = isOpen => setIsDropdownOpen(isOpen);

  useEffect(() => {
    openDropdown && setIsDropdownOpen(true);
  }, [openDropdown]);

  return (
    <>
      <Dropdown onSelect={handle_dropdown_select} onToggle={onToggleDropdown} show={isDropdownOpen}>
        <Dropdown.Toggle className="mini-menu">
          <FontAwesomeIcon icon={faEllipsisH} />
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Item eventKey="running">
            <FontAwesomeIcon
              icon={faRunning}
              color={item.context.status === "running" ? "black" : "grey"}
            />{" "}
            {item.context.status === "running" ? "Unmark" : "Mark"} In Progress
          </Dropdown.Item>
          <Dropdown.Item eventKey="abandon">
            <FontAwesomeIcon
              icon={faTimesCircle}
              color={item.context.status === "canceled" ? "black" : "grey"}
            />{" "}
            {item.context.status === "canceled" ? "Unmark" : "Mark"} Abandon
          </Dropdown.Item>
          <Dropdown.Divider />
          <Dropdown.Item eventKey="1">Delete Permanently</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>

      <Modal show={showDelete} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Are you sure you wish to permanently delete?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Only permanently delete if this workette was created in error, otherwise use{" "}
          <b>Abandon</b> to log a cancellation for analytical purposes
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleAbandon}>
            Abandon
          </Button>
          <Button variant="danger" onClick={handleDelete}>
            Delete Forever
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

WktButtonMiniMenu.propTypes = {
  item: PropTypes.object,
  purgeTree: PropTypes.func,
  controls: PropTypes.object.isRequired,
  openDropdown: PropTypes.bool,
};

export default memo(WktButtonMiniMenu);
