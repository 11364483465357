import "./Referral.scss";

const Referral = () => {
  return (
    <div className="referral-wrapper">
      <section>
        <h1>Refer a Friend!</h1>
        <strong>Refer 5 friends to have next month's myca for free!</strong>
        <p>You can use the below as an email template for sharing some information about Myca</p>

        <div className="template">
          <span>
            Hey <em>[Friend's Name]</em>,
          </span>
          <span>
            I hope you're doing well! I recently came across an AI productivity platform called
            myca, and it's been a game-changer for me. Not only does it help me juggle my tasks with
            ease, but it's also supercharged my productivity and has been an absolute ally in
            crushing my goals. Knowing your love for top-tier productivity tools, I think you're
            going to find it useful. Check it out at www.myca.ai. Let me know what you think!
          </span>

          <span>Best,</span>
          <em>[Your Name]</em>
        </div>
      </section>
      <section>
        <h1>Referred by Someone?</h1>
        <p>
          Click here - <a href="https://forms.gle/mE32QRxgxd94mLj97">Link</a>
        </p>
      </section>
    </div>
  );
};

export default Referral;
