import PropTypes from "prop-types";

import "./MycaLoader.scss";

const MycaLoader = ({ fullScreen = false }) => {
  return (
    <div className="myca-loader" data-fullscreen={fullScreen}>
      <div className="loader">
        <div className="myca-progress">myca</div>
        <div className="text">myca</div>
      </div>
    </div>
  );
};

MycaLoader.propTypes = {
  fullScreen: PropTypes.bool,
};

export default MycaLoader;
