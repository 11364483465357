import { memo } from "react";
import PropTypes from "prop-types";

import "./RitualDays.scss";

const RitualDays = ({ name, label, value = false, onChange }) => {
  const handleClick = () => {
    const e = { currentTarget: { name } };
    onChange(e);
  };

  return (
    <span
      name={name}
      className={value ? "date selected" : "date not-selected"}
      onClick={handleClick}
    >
      {label}
    </span>
  );
};

RitualDays.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
};

export default memo(RitualDays);
