import { memo, useCallback } from "react";
import { useDispatch } from "react-redux";
import { Modal } from "react-bootstrap";
import PropTypes from "prop-types";

import { session_actions as sact, inLinkWhiteList } from "@myca/shared-component";

import "./WktiFrameModal.scss";

const WktiFrameModal = ({ item, show, onHide, link }) => {
  const dispatch = useDispatch();
  const set_selected_wkt = useCallback(w_id => dispatch(sact.set_selected_wkt(w_id)), [dispatch]);

  const openTab = () => {
    window.open(link.url, "_blank");
    onHide();
  };

  const openEdit = () => {
    set_selected_wkt(item.jid);
    onHide();
  };

  return (
    <>
      {inLinkWhiteList(link.url) ? (
        <Modal
          data-testid="iframe-modal"
          dialogClassName="iframe-modal"
          show={show}
          onHide={onHide}
        >
          <Modal.Header closeButton>
            <div className="title-container">
              <div className="title">{link.name}</div>
              <div className="title-control" onClick={openEdit}>
                Edit
              </div>
              <div className="title-control" onClick={openTab}>
                Pop out in new tab
              </div>
            </div>
          </Modal.Header>
          <Modal.Body>
            <iframe
              is=""
              title={link.name}
              src={link.url}
              style={{
                height: "100%",
                width: "100%",
              }}
            />
          </Modal.Body>
        </Modal>
      ) : null}
    </>
  );
};

WktiFrameModal.propTypes = {
  item: PropTypes.object.isRequired,
  show: PropTypes.bool,
  onHide: PropTypes.func,
  link: PropTypes.object,
};

export default memo(WktiFrameModal);
